/**
 * @description translate any object piece of analytics config
 * - avods -> video-channels
 */
export const translateAnalyticsConfig = (object: object) => {
	try {
		let result = JSON.stringify(object);
		result = result.replaceAll('avods:', 'video-channels:');
		return JSON.parse(result);
	} catch {
		return object;
	}
};
