/**
 *
 * @description
 * Summarizes object number keys;
 * @example
 * sumObjectsByKey({ a: 1, b: 'qwerty' }, { a: 4, b: 'lorem' })
 * // Result: { a: 5, b: 'lorem' }
 */
export const sumObjectsByKey = (...objs: Array<Record<string, unknown>>) => {
	return objs.reduce((a, b) => {
		for (const k in b) {
			if (Object.hasOwnProperty.call(b, k)) {
				a[k] = typeof b[k] === 'number' ? ((a[k] as number) || 0) + (b[k] as number) : b[k];
			}
		}
		return a;
	}, {});
};
