// modules
import { SxProps } from '@mui/material';
// types
import { AnalyticsMetrics, IAnalyticsFilter } from 'types/analytics';

export interface IColumn {
	valueFormatter?: ({ value }: { value: string | number }) => string;
	editable?: boolean;
	field: string;
	filter?: boolean | string;
	floatingFilter?: boolean;
	headerName?: string;
	resizable?: boolean;
	sortable?: boolean;
	suppressMenu?: boolean;
	hiddenDefault?: boolean;
	type?: 'numericColumn' | 'dateColumn';
}

export interface IAnalyticsTableProps<DataRow = unknown> {
	columns: Array<IColumn>;
	data: DataRow[];
	tableKey: string;
	isDataRequested?: boolean;
	loading?: boolean;
	sx?: SxProps;
	onHideChange?: (hidden: string[]) => void;
}

export interface IPlatformTotals {
	activetime: number;
	avgSessionTime: number;
	country: string;
	inactivetime: number;
	newusers: number;
	platform: string;
	sessions: number;
	totalTimePerUser: number;
	users: number;
	eventscount?: number;
	returnedUsers: number;
}

export interface IAppTotals {
	activetime: number;
	appname: string;
	apptype: string;
	avgSessionTime: number;
	country: string;
	inactivetime: number;
	newusers: number;
	platform: string;
	sessionPerUser: number;
	sessions: number;
	totalTimePerUser: number;
	users: number;
	eventscount?: number;
	returnedUsers: number;
}

export interface IAppTypeTotals {
	activetime: number;
	apptype: string;
	inactivetime: number;
	newusers: number;
	sessions: number;
	totalTimePerUser: number;
	users: number;
	eventscount?: number;
	returnedUsers: number;
}

export interface ICountryTotals {
	activetime: number;
	country: string;
	inactivetime: number;
	newusers: number;
	sessions: number;
	totalTimePerUser: number;
	users: number;
	eventscount?: number;
	returnedUsers: number;
}

export interface IAnalyticsTotals {
	adtype?: string;
	activetime: number;
	apps: IAppTotals[];
	appsCount: number;
	apptypes: IAppTypeTotals[];
	apptypesCount: number;
	avgSessionTime: number;
	countries: ICountryTotals[];
	countriesCount: number;
	eventscount?: number;
	eventvalue?: number;
	eventcategory?: string;
	eventaction?: string;
	inactivetime: number;
	newusers: number;
	platforms: IPlatformTotals[];
	platformsCount: number;
	returnedUsers: number;
	sessions: number;
	sessionsPerUser: number;
	totalTimePerUser: number;
	users: number;
	watchTime: number;
}

export interface IAnalyticsDateItem extends IAnalyticsTotals {
	date: string | number;
}

export interface IAnalyticsDateTotals extends IAnalyticsTotals {
	dates: IAnalyticsDateItem[];
}

export type ComparisonMetric = AnalyticsMetrics.platform | AnalyticsMetrics.appname | AnalyticsMetrics.apptype | AnalyticsMetrics.country;

export enum CustomVariableGroup {
	metric = 'metric',
	valueMetric = 'value-metric',
	eventMetric = 'event-metric',
	submetric = 'submetric',
	ownVariable = 'own-variable',
	predefinedVariable = 'predefined-variable',
	separate = 'separate',
	/** @description this means that it is custom variable dependent */
	dependent = 'dependent',
}

export enum CustomVariableSubgroup {
	ads = 'ads',
	app = 'app',
	crossPromotion = 'cross-promotion',
	crossPromotionDetails = 'cross-promotion-details',
	custom = 'custom',
	deeplink = 'deeplink',
	errors = 'errors',
	events = 'events',
	general = 'general',
	ownVariables = 'own-variables',
	pageView = 'Page View',
	payment = 'payment',
	predefinedVariables = 'predefined-variables',
	screen = 'Screen',
	system = 'system',
	video = 'video',
}

export enum VariableFormat {
	/**
	 * @description depend on global user format
	 * @example "Jun 2, 2024"
	 * */
	date = 'date',
	/** @example "31240" */
	number = 'number',
	/** @example "24.48%" */
	percent = 'percent',
	/** @example "Roku" */
	string = 'string',
	/** @example "01:38:17" */
	time = 'time',
	/** @example $24,140.56 */
	price = 'price',
}

export interface ICustomVariable {
	description?: string;
	format: VariableFormat;
	/** @description technical field. need to create request */
	group: CustomVariableGroup;
	/** @description field for grouping variables in UI */
	subgroup: CustomVariableSubgroup;
	/** @description metric to get value for metric (ex. { metric: 'eventaction', valueMetric: 'eventvalue' })  */
	valueMetric?: AnalyticsMetrics;
	id: string;
	name: string;
	/**
	 * @description array of metrics, numbers, arithmetical symbols (+, -, /, *, %)
	 * @example ['impression', '/', 'request-to-play', '/', 1000]
	 * */
	value: Array<string | number>;
	pureValue: Array<string | number>;
	/** @description request filters */
	filters?: IAnalyticsFilter[];
}
