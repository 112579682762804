// modules
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
// types
import { IBreadcrumb } from './types';
// utils
import { useSafeNavigate } from 'hooks/useSafeNavigate';

export const Breadcrumb: FC<{ item: IBreadcrumb }> = (props) => {
	const { item } = props;
	const { navigate, safeNavigate } = useSafeNavigate();
	const [pending, setPending] = useState(false);

	const isClickable = (() => {
		if (!item.path || location.pathname === item.path) return false;
		if (item.path[item.path.length - 1] === '?' && item.path.slice(0, -1) === location.pathname) return false;
		return true;
	})();

	const receiver = useCallback(
		(event: Event) => {
			const isAllowed = (event as CustomEvent).detail !== false;
			safeNavigate(item.path || '', { force: !isAllowed });
		},
		[safeNavigate, item.path]
	);

	useEffect(() => {
		if (!isClickable || !item.eventReceiverId || !pending) return;
		const eventId = item.eventReceiverId;
		document.addEventListener(eventId, receiver);
		return () => {
			document.removeEventListener(eventId, receiver);
		};
	}, [pending, receiver, isClickable, item.eventReceiverId]);

	const handleClick = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			if (!item.eventRequestId) navigate(item.path || '');
			else {
				setPending(true);
				const event = new CustomEvent(item.eventRequestId);
				/** @description let pending state to be updated before event is dispatched */
				setTimeout(() => document.dispatchEvent(event));
			}
		},
		[navigate, safeNavigate, item.eventRequestId]
	);

	if (isClickable)
		return (
			<Button
				sx={{
					color: 'neutral.200',
					fontWeight: '500',
					'&:hover': { backgroundColor: 'primary.dark' },
				}}
				onClick={handleClick}
				size="small"
				variant="text"
				component={Link}
				to={item.path || ''}
			>
				{item.label}
			</Button>
		);
	return (
		<Typography variant="subtitle2" sx={{ fontSize: 13, px: '12px' }} component="div" fontWeight="600" color="neutral.100">
			{item.label}
		</Typography>
	);
};
