// types
import { OfficeAttendanceVariant } from 'types/general';
import { Access } from 'types/security';
import { COLORS } from './colors';
import { IDashboardItem, DashboardItemType } from 'store/types';
import { IDropdownOption } from 'types/form';

/**
 * @description
 * When changing it please check other places:
 * - `manifest.json`
 * - `index.html`
 * - `/src/consts/index.ts`
 */
export const PROJECT_NAME = 'EMS';

export const modeKeys = {
	modeUID: 'modeUID',
	modeUIDRadio: 'modeUIDRadio',
	modes: 'modes',
} as const;

export const radioKeys = {
	custom: 'custom',
	default: 'default',
};

export const PDF_LOADER = 'pdf-loader';

export const PDF_DOWNLOAD_DATA = 'pdf-get-data';

export const PDF_DOWNLOAD_PAGE = 'pdf-download-page';

export const CSV_DOWNLOAD_ELEMENT = 'csv-download-element';

export const XLSX_DOWNLOAD_ELEMENT = 'xlsx-download-element';

export const PDF_CLASS_NAME = 'pw-pdf-element';

export const PDF_WIDTH = 2400;

export const NO_PDF_CLASS_NAME = 'pw-pdf-hide-element';

export const TABLE_RESIZE_EVENT = 'table-resize';

export const CHANGE_ANALYTICS_CSV_EVENT = 'analytics-change-csv';

export const IFRAME_CLOSE_EVENT = 'iframe-close';

export const SECURITY_SEPARATOR = '.';

export const WEEK_SUN = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const WEEK_MON = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

/** @description is used as analytics item id expander to prevent saving item configs (hidden columns/datasets, column order etc) */
export const EXPLORER_PREFIX = '//explorer';

export const ANALYTICS_SECTIONS = Object.freeze({
	overview: 'overview',
	advertising: 'advertising',
	avods: 'video-channels',
	retention: 'retention',
	comparison: 'comparison',
	revenue: 'revenue',
	crossPromotion: 'cross-promotion',
	crossPromotionV2: 'cross-promotion-v2',
	events: 'events',
	custom: 'custom',
});

export const AVERAGE_ALL = 'Average (ALL)';
export const AVERAGE_TOP = 'Average (TOP)';

export const ACCESS_PRESETS: Readonly<Record<string, { label: string; color: string; access: Access[] }>> = Object.freeze({
	read: { label: 'Read Only', color: COLORS.read, access: [Access.read] },
	'read/write': { label: 'Read/Write', color: COLORS.create, access: [Access.read, Access.create, Access.update] },
	full: { label: 'Full Access', color: COLORS.fullAccess, access: [Access.read, Access.create, Access.update, Access.delete] },
	forbid: { label: 'Forbid', color: 'text.secondary', access: [] },
});

export const GLOBAL_ANALYTICS_VARIABLE_PREFIX = '$';

export const CHECK_CUSTOM_REPORT_EVENT_REQUEST = 'CHECK_CUSTOM_REPORT_EVENT_REQUEST';
export const CHECK_CUSTOM_REPORT_EVENT_RECEIVER = 'CHECK_CUSTOM_REPORT_EVENT_RECEIVER';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const CUSTOM_ANALYTICS_ITEM_PREFIX = 'custom:';

export const COHORT_SIZE = ['day', 'week'];

export const DEFAULT_CUSTOM_TAB_NAME = 'Preset';

export const USER_TEAMS = 'user_teams';

export const USER_OFFICE = 'user_office';

/**
 * @description WARNING!!!
 * - Rest of the world must be a last element of the array;
 * - if changed, update requests in `/src/components/Dashboard/useData.ts`
 * */
export const DASHBOARD_LOCATIONS: IDropdownOption[] = [
	{ label: 'United States', value: 'us' },
	{ label: 'United Kingdom', value: 'gb' },
	{ label: 'Rest of the World', value: 'other' },
];

export const DEFAULT_DASHBOARD_ITEMS: IDashboardItem[] = [
	{ id: 'revenue-by-platform', type: DashboardItemType.list, visible: false, title: 'Revenue (Top Platforms)' },
	{ id: 'fill-rate-by-platform', type: DashboardItemType.list, visible: false, title: 'Fill Rate (Top Platforms)' },
	{ id: 'revenue-by-app', type: DashboardItemType.list, visible: false, title: 'Revenue (Top Apps)' },
	{ id: 'revenue-by-video', type: DashboardItemType.list, visible: false, title: 'Revenue (Top Video Apps)' },
	{ id: 'revenue-by-game', type: DashboardItemType.list, visible: false, title: 'Revenue (Top Game Apps)' },
	{ id: 'iap-by-game', type: DashboardItemType.list, visible: false, title: 'IAP Revenue (Top Game Apps)' },
	{ id: 'users-by-game', type: DashboardItemType.list, visible: false, title: 'Active Users (Games Apps)' },
	{ id: 'users-by-video', type: DashboardItemType.list, visible: false, title: 'Active Users (Video Apps)' },
	{ id: 'users', type: DashboardItemType['line-chart'], visible: false, title: 'Active Users' },
	{ id: 'newusers', type: DashboardItemType['line-chart'], visible: false, title: 'New Users' },
	{ id: 'sessions', type: DashboardItemType['line-chart'], visible: false, title: 'Sessions' },
	{ id: 'impressions', type: DashboardItemType['line-chart'], visible: false, title: 'Impressions' },
	{ id: 'opportunity', type: DashboardItemType['line-chart'], visible: false, title: 'Opportunity' },
	{ id: 'fill-rate', type: DashboardItemType['line-chart'], visible: false, title: 'Fill Rate' },
	{ id: 'total-revenue', type: DashboardItemType['line-chart'], visible: false, title: 'Total Revenue' },
	{ id: 'ads-revenue', type: DashboardItemType['line-chart'], visible: false, title: 'ADs Revenue' },
	{ id: 'iap-revenue', type: DashboardItemType['line-chart'], visible: false, title: 'IAP Revenue' },
	{ id: 'bright-data-revenue', type: DashboardItemType['line-chart'], visible: false, title: 'BD Revenue' },
	{ id: 'video-revenue', type: DashboardItemType['line-chart'], visible: false, title: 'Revenue (Video Apps)' },
	{ id: 'game-revenue', type: DashboardItemType['line-chart'], visible: false, title: 'Revenue (Game Apps)' },
	{ id: 'game-sessions-length', type: DashboardItemType.list, visible: false, title: 'Sessions Length (Game Apps)' },
	{ id: 'video-sessions-length', type: DashboardItemType.list, visible: false, title: 'Sessions Length (Video Apps)' },
	{ id: 'fill-rate-by-country', type: DashboardItemType.list, visible: false, title: 'Fill Rate (Top Countries)' },
	{ id: 'revenue-by-country', type: DashboardItemType.list, visible: false, title: 'Revenue (Top Countries)' },
];

export const ATTENDANCE_COLORS: Readonly<Record<OfficeAttendanceVariant, string>> = Object.freeze({
	[OfficeAttendanceVariant.none]: 'text.primary',
	[OfficeAttendanceVariant.office]: 'success.main',
	[OfficeAttendanceVariant.home]: 'warning.light',
	[OfficeAttendanceVariant.sick]: 'secondary.light',
	[OfficeAttendanceVariant.holiday]: 'primary.light',
	[OfficeAttendanceVariant.holidayHalfDay]: 'primary.light',
	[OfficeAttendanceVariant.vacation]: 'info.light',
	[OfficeAttendanceVariant.vacationFirstHalf]: 'info.light',
	[OfficeAttendanceVariant.vacationSecondHalf]: 'info.light',
	[OfficeAttendanceVariant.militaryDuty]: '#96a08d',
});

export const DEFAULT_CHART_LIMIT = 10;

export const DEFAULT_COMPARISON_LIMIT = 15;

export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';

export const DEFAULT_TIME_FORMAT: '12h' | '24h' = '24h';

export const DATE_FORMAT_OPTIONS = ['MMM D, YYYY', 'DD-MM-YYYY', 'DD/MM/YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY'];

export const TIME_FORMAT_OPTIONS = ['12h', '24h'];

export const MAX_TABLE_LIMIT = 100000;
export const TABLE_SCROLL_EVENT = 'table-scroll';

export const CPM_DIVIDER = 10;
export const REVENUE_DIVIDER = 10000;

export const IAP_MULTIPLIER = 0.01;

export const BRIGHT_DATA_DIVIDER = 100;

/** @description variable is used for dropdown menu items */
export const MENU_ITEM_HEIGHT = 38;

export const DAY_OFFICE_VISITORS_LIMIT = 10;

export const DEPARTMENTS = 'Departments';

export const TOKEN = 'token';
export const PUBLIC_KEY = 'public_key';
export const CERTIFICATE = 'certificate';

export const SUPPORT_EMAIL = 'pw-baas-admins@play.works';

export const MAX_FORM_WIDTH = 1024;

export const USER_AVATAR_BUCKET_UID = 'cms_users_avatars';

export const ANALYTICS_DAY_AVAILABLE_AFTER_HOURS_UTC = 3;

export const pathVars = Object.freeze({
	analytics: 'analytics',
	assets: 'assets',
	appId: 'appId',
	appIds: 'appIds',
	application: 'application',
	applicationsOverview: 'applications',
	appModeId: 'appModeId',
	appModeIds: 'appModeIds',
	appModes: 'app-modes',
	apps: 'apps',
	customReports: 'custom-reports',
	create: 'create',
	configurations: 'configurations',
	contractId: 'contractId',
	contractIds: 'contractIds',
	contracts: 'agreements',
	database: 'database',
	delete: 'delete',
	deploymentCreate: 'deploymentCreate',
	deploymentId: 'deploymentId',
	deploymentIds: 'deploymentIds',
	deployments: 'deployments',
	deploymentSettings: 'deploymentSettings',
	details: 'details',
	edit: 'edit',
	editApplication: 'editApplication',
	editEvent: 'editEvent',
	editMode: 'editMode',
	events: 'events',
	eventCreate: 'eventCreate',
	eventId: 'eventId',
	eventIds: 'eventIds',
	event: 'event',
	id: 'id',
	modeCreate: 'modeCreate',
	mode: 'mode',
	platform: 'platform',
	platformId: 'platformId',
	platformIds: 'platformIds',
	platformOverview: 'platforms',
	platforms: 'platforms',
	productOverview: 'products',
	productId: 'productId',
	productIds: 'productIds',
	product: 'product',
	pathUid: 'pathUid',
	sandbox: 'sandbox',
	scheduledReportId: 'scheduledReportId',
	scheduledReportIds: 'scheduledReportIds',
	securityGroups: 'security-groups',
	securityGroup: 'security-group',
	securityGroupId: 'securityGroupId',
	securityGroupIds: 'securityGroupIds',
	service: 'service',
	services: 'services',
	share: 'share',
	statistics: 'monitoring',
	toolsOverview: 'tools',
	toolId: 'toolId',
	teamStatusId: 'teamStatusId',
	teamStatusIds: 'teamStatusIds',
	templateId: 'templateId',
	templatesOverview: 'templates',
	hosting: 'hosting',
	users: 'users',
	userId: 'userId',
	userIds: 'userIds',
	scheduleReports: 'schedule-reports',
	reports: 'reports',
	reportDetails: 'report-details',
	status: 'status',
});

/** path */
const statistics = `/baas/${pathVars.statistics}`;
const securityGroups = `/${pathVars.securityGroups}`;
const securityGroupsEdit = `/${pathVars.securityGroups}/:${pathVars.securityGroupId}/${pathVars.edit}`;
const securityGroupsCreate = `/${pathVars.securityGroups}/${pathVars.create}`;
const securityGroupsDelete = `/${pathVars.securityGroups}/:${pathVars.securityGroupIds}/${pathVars.delete}`;
const services = `/${pathVars.services}`;
const service = `${services}/pw`;

const analytics = `/${pathVars.analytics}`;
const analyticsReports = `/${pathVars.analytics}/${pathVars.reports}`;
const analyticsCustomReports = `/${pathVars.analytics}/${pathVars.customReports}`;
const analyticsEditCustomReports = `/${pathVars.analytics}/${pathVars.customReports}/${pathVars.edit}`;
const analyticsSandboxReports = `/${pathVars.analytics}/${pathVars.sandbox}/${pathVars.reports}`;
const analyticsScheduledReports = `/${pathVars.analytics}/${pathVars.scheduleReports}`;
const analyticsCreateScheduledReports = `/${pathVars.analytics}/${pathVars.scheduleReports}/${pathVars.create}`;
const analyticsEditScheduledReports = `/${pathVars.analytics}/${pathVars.scheduleReports}/:${pathVars.scheduledReportId}/${pathVars.edit}`;
const analyticsDeleteScheduledReports = `/${pathVars.analytics}/${pathVars.scheduleReports}/:${pathVars.scheduledReportIds}/${pathVars.delete}`;
const analyticsSettings = `/${pathVars.analytics}/settings`;

const assets = `/${pathVars.assets}`;
const assetsCreate = `${assets}/${pathVars.create}`;
const assetsEdit = `${assets}/:${pathVars.pathUid}/${pathVars.edit}`;

const configurations = `/${pathVars.configurations}`;

const database = `/${pathVars.database}`;
const databaseSettings = `${database}/settings`;

const contracts = `${database}/${pathVars.contracts}`;
const contractCreate = `${contracts}/${pathVars.create}`;
const contractDetails = `${contracts}/:${pathVars.contractId}/${pathVars.details}`;
const contractEdit = `${contracts}/:${pathVars.contractId}/${pathVars.edit}`;
const contractDelete = `${contracts}/:${pathVars.contractIds}/${pathVars.delete}`;

const platformOverview = `/baas/${pathVars.platformOverview}`;
const platformDetails = `${platformOverview}/:${pathVars.platformId}/${pathVars.details}`;
const platformSettings = `${platformOverview}/:${pathVars.platformId}/${pathVars.platform}`;
const platformCreate = `${platformOverview}/${pathVars.create}`;
const platformDelete = `${platformOverview}/:${pathVars.platformId}/${pathVars.delete}`;
const platformMultiDelete = `${platformOverview}/:${pathVars.platformIds}/${pathVars.delete}`;

const productOverview = `/baas/${pathVars.productOverview}`;
const productEdit = `${productOverview}/:${pathVars.productId}/${pathVars.edit}`;
const productDetails = `${productOverview}/:${pathVars.productId}/${pathVars.details}`;
const productCreate = `${productOverview}/${pathVars.create}`;
const productDelete = `${productOverview}/${pathVars.delete}`;
const productMultiDelete = `${productOverview}/:${pathVars.productIds}/${pathVars.delete}`;

const deploymentSettings = `${platformSettings}/:${pathVars.deploymentId}/${pathVars.deploymentSettings}`;
const deploymentDetails = `${platformDetails}/:${pathVars.deploymentId}/${pathVars.details}`;
const deploymentCreate = `${platformSettings}/${pathVars.deploymentCreate}`;
const deploymentDelete = `${platformSettings}/:${pathVars.deploymentId}/${pathVars.delete}`;
const deploymentMultiDelete = `${platformSettings}/:${pathVars.deploymentIds}/${pathVars.delete}`;

const applications = `/baas/${pathVars.applicationsOverview}` as const;
const applicationSettings = `${applications}/:${pathVars.appId}/${pathVars.application}` as const;
const applicationDetails = `${applications}/:${pathVars.appId}/${pathVars.details}`;
const applicationCreate = `${applications}/${pathVars.create}`;
const applicationShare = `${applications}/:${pathVars.appId}/${pathVars.share}`;
const applicationShareEdit = `${applicationShare}/:${pathVars.deploymentId}/${pathVars.editApplication}`;
const applicationDelete = `${applications}/:${pathVars.appId}/${pathVars.delete}`;
const applicationsMultiDelete = `${applications}/:${pathVars.appIds}/${pathVars.delete}`;

const modeSettings = `${applicationSettings}/:${pathVars.appModeId}/${pathVars.mode}`;
const modeDetails = `${applicationDetails}/:${pathVars.appModeId}/${pathVars.details}`;
const modeDelete = `${applicationSettings}/:${pathVars.appModeId}/${pathVars.delete}`;
const modesMultiDelete = `${applicationSettings}/:${pathVars.appModeIds}/${pathVars.delete}`;
const modeCreate = `${applicationSettings}/${pathVars.modeCreate}`;
const modeShareEdit = `${applicationShareEdit}/:${pathVars.appModeId}/${pathVars.editMode}`;

// const shopOverview = `/baas/${pathVars.shops}` as const;
// const shopCreate = `${shopOverview}/${pathVars.shopCreate}`;
// const shopShare = `${shopOverview}/:${pathVars.shopId}/${pathVars.shopShare}`;
// const shopDetails = `${shopOverview}/:${pathVars.shopId}/${pathVars.details}`;
// const shopEdit = `${shopOverview}/:${pathVars.shopId}/${pathVars.shop}` as const;
//
// const appShopSelect = `${applicationSettings}/${pathVars.shopSelect}`;
// const appShopCreate = `${applicationSettings}/${pathVars.shopCreate}`;
// const appShopDetails = `${applicationSettings}/:${pathVars.shopId}/${pathVars.details}`;
// const appShopEdit = `${applicationSettings}/:${pathVars.shopId}/${pathVars.shop}` as const;
// const appShopShare = `${appShopEdit}/${pathVars.share}`;
// const appShopShareEdit = `${appShopShare}/:${pathVars.sharedAppId}/${pathVars.edit}`;
//
// const appShopSwimlineSelect = `${appShopEdit}/${pathVars.swimlineSelect}`;
// const appShopSwimlineCreate = `${appShopEdit}/${pathVars.swimlineCreate}`;
// const appShopSwimlineShare = `${appShopEdit}/:${pathVars.swimlineId}/${pathVars.swimlineShare}`;
// const appShopSwimlineDetails = `${appShopEdit}/:${pathVars.swimlineId}/${pathVars.details}`;
// const appShopSwimlineEdit = `${appShopEdit}/:${pathVars.swimlineId}/${pathVars.swimline}` as const;

const eventSettings = `${modeSettings}/:${pathVars.eventId}/${pathVars.event}`;
const eventDetails = `${modeDetails}/:${pathVars.eventId}/${pathVars.details}`;
const eventCreate = `${modeSettings}/${pathVars.eventCreate}`;
const eventDelete = `${eventSettings}/:${pathVars.eventId}/${pathVars.delete}`;
const eventMultiDelete = `${eventSettings}/:${pathVars.eventIds}/${pathVars.delete}`;
const eventShareEdit = `${modeShareEdit}/:${pathVars.eventId}/${pathVars.editEvent}`;

const emailTemplate = `/${pathVars.templatesOverview}`;
const emailTemplateCreate = `${emailTemplate}/${pathVars.create}`;
const emailTemplateDetails = `${emailTemplate}/:${pathVars.templateId}/${pathVars.details}`;
const emailTemplateEdit = `${emailTemplate}/:${pathVars.templateId}/${pathVars.edit}`;

const hosting = `/ems/${pathVars.hosting}`;
const hostingCreate = `${hosting}/${pathVars.create}`;
const hostingEdit = `${hosting}/:${pathVars.pathUid}/${pathVars.edit}`;

const users = `/${pathVars.users}`;
const userEdit = `${users}/:${pathVars.userId}/${pathVars.edit}`;
const userCreate = `${users}/${pathVars.create}`;
const userDelete = `${users}/:${pathVars.userIds}/${pathVars.delete}`;

const toolsOverview = `/${pathVars.toolsOverview}`;
const toolCreate = `${toolsOverview}/${pathVars.create}`;
const toolEdit = `${toolsOverview}/:${pathVars.toolId}/${pathVars.edit}`;
const toolDetails = `${toolsOverview}/:${pathVars.toolId}/${pathVars.details}`;

export const TEAM_STATUS_TYPES = Object.freeze({
	own: 'own',
	team: 'team',
});

const team = `/team`;
const teamStatus = `${team}/${pathVars.status}`;
const teamStatusCreate = `${teamStatus}/${pathVars.create}`;
const teamStatusEdit = `${teamStatus}/:${pathVars.teamStatusId}/${pathVars.edit}`;
const teamStatusDetails = `${teamStatus}/:${pathVars.userId}/${pathVars.details}`;
const teamStatusDetailsEdit = `${teamStatus}/:${pathVars.userId}/${pathVars.details}/:${pathVars.teamStatusId}/${pathVars.edit}`;
const teamStatusDetailsDelete = `${teamStatus}/:${pathVars.userId}/${pathVars.details}/:${pathVars.teamStatusIds}/${pathVars.delete}`;
const teamStatusReportDetails = `${teamStatus}/:${pathVars.userId}/${pathVars.details}/:${pathVars.teamStatusId}/${pathVars.reportDetails}`;
const teamStatusDelete = `${teamStatus}/:${pathVars.teamStatusIds}/${pathVars.delete}`;

const office = '/department';
const manageOffice = `${office}/manage`;
const root = '/';
const notFound = '/404';
const unauthorized = '/401';
const forbidden = '/403';
const undefinedError = '/error';
const auth = '/login';
const baasConfig = '/baas/settings';
const cmsConfig = '/ems/settings';
const cdn = '/cdn';
const login = '/login';
const dashboard = '/dashboard';
const getStarted = '/get-started';
const baasReboot = '/baas/reboot';
const forgot = `${login}/forgot`;
const cdnMonitoring = `${cdn}/monitoring`;

export const path = Object.freeze({
	analytics,
	analyticsCreateScheduledReports,
	analyticsCustomReports,
	analyticsDeleteScheduledReports,
	analyticsEditCustomReports,
	analyticsEditScheduledReports,
	analyticsReports,
	analyticsSandboxReports,
	analyticsScheduledReports,
	analyticsSettings,
	applicationCreate,
	applicationDelete,
	applicationDetails,
	applications,
	applicationSettings,
	applicationShare,
	applicationShareEdit,
	applicationsMultiDelete,
	assets,
	assetsCreate,
	assetsEdit,
	auth,
	baasConfig,
	baasReboot,
	cdn,
	cdnMonitoring,
	cmsConfig,
	configurations,
	contractCreate,
	contractDelete,
	contractDetails,
	contractEdit,
	contracts,
	dashboard,
	databaseSettings,
	deploymentCreate,
	deploymentDelete,
	deploymentDetails,
	deploymentMultiDelete,
	deploymentSettings,
	emailTemplate,
	emailTemplateCreate,
	emailTemplateDetails,
	emailTemplateEdit,
	eventCreate,
	eventDelete,
	eventDetails,
	eventMultiDelete,
	eventSettings,
	eventShareEdit,
	forbidden,
	forgot,
	getStarted,
	hosting,
	hostingCreate,
	hostingEdit,
	login,
	manageOffice,
	modeCreate,
	modeDelete,
	modeDetails,
	modeSettings,
	modeShareEdit,
	modesMultiDelete,
	notFound,
	office,
	platformCreate,
	platformDelete,
	platformDetails,
	platformMultiDelete,
	platformOverview,
	platformSettings,
	productCreate,
	productDelete,
	productDetails,
	productEdit,
	productMultiDelete,
	productOverview,
	root,
	securityGroups,
	securityGroupsCreate,
	securityGroupsDelete,
	securityGroupsEdit,
	service,
	services,
	statistics,
	// shopOverview,
	// shopCreate,
	// shopShare,
	// shopDetails,
	// shopEdit,
	// appShopSelect,
	// appShopCreate,
	// appShopShare,
	// appShopDetails,
	// appShopEdit,
	// appShopShareEdit,
	// appShopSwimlineSelect,
	// appShopSwimlineCreate,
	// appShopSwimlineShare,
	// appShopSwimlineDetails,
	// appShopSwimlineEdit,
	teamStatus,
	teamStatusCreate,
	teamStatusDelete,
	teamStatusDetails,
	teamStatusDetailsDelete,
	teamStatusDetailsEdit,
	teamStatusEdit,
	teamStatusReportDetails,
	toolCreate,
	toolDetails,
	toolEdit,
	toolsOverview,
	unauthorized,
	undefinedError,
	userCreate,
	userDelete,
	userEdit,
	users,
});
