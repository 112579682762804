// modules
import { Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// utils
import { useAuthBoundary } from 'hooks/useAuthBoundary';
// components
import { AnalyticsCustomVariablesContextProvider } from './Analytics/AnalyticsVariableInput/AnalyticsCustomVariablesContext';

export const AuthBoundary = () => {
	const loading = useAuthBoundary();
	if (loading) return <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />;

	return (
		<AnalyticsCustomVariablesContextProvider>
			<Outlet />
		</AnalyticsCustomVariablesContextProvider>
	);
};
