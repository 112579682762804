import { EventValue, PresetDate } from 'rc-picker/lib/interface';
// modules
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { NonIndexRouteObject } from 'react-router-dom';
import { SvgIconComponent } from '@mui/icons-material';
import { ReactNode } from 'react';
import { Moment } from 'moment';
// types
import { IEntityConfig } from './entity';
import { IPlatform } from './platform';
import { IDeployment } from './deployment';
import { IApp } from './app';
import { FormMode } from './form';
import { IBreadcrumb } from 'components/Breadcrumbs/types';
import { Access } from './security';
import { IUser } from './login';

export enum UserTeamRole {
	member = 'member',
	manager = 'manager',
	viewer = 'viewer',
}

export enum UserOfficeRole {
	member = 'member',
	manager = 'manager',
	viewer = 'viewer',
}

export enum OfficeAttendanceVariant {
	/** @description first variant is default. DO NOT MOVE */
	none = 'None',
	office = 'Office',
	home = 'Working Remotely',
	sick = 'Sick Day',
	holiday = 'Holiday',
	holidayHalfDay = 'Holiday (half-day)',
	vacation = 'Vacation',
	vacationFirstHalf = 'Vacation (1st half-day)',
	vacationSecondHalf = 'Vacation (2nd half-day)',
	militaryDuty = 'Military Duty',
}

export type PageType = 'list' | 'list.modal' | 'form' | 'custom';

export enum EntityEnum {
	service,
	platform,
	deployment,
	app,
	assets,
	'baas-config',
	'cms-config',
	contract,
	'app-mode',
	'event',
	'demo',
	'scheduled-report',
	'security-group',
	'team-status',
	user,
	product,
	tool,
	template,
	shop,
	swimline,
}

export enum EntityEnumPlural {
	services,
	platforms,
	deployments,
	apps,
	'app-modes',
	'baas-config',
	'cms-config',
	contracts,
	'demo',
	'scheduled-reports',
	'security-groups',
	'team-statuses',
	users,
	products,
	templates,
}

export type Entity = keyof typeof EntityEnum;

export type RequestType = 'create' | 'read' | 'update' | 'delete';

export type AnyObject = {
	[key: string]: string | boolean | number | null | undefined | AnyObject | AnyObject[];
};

export type AnyObjectWithMoment = {
	[key: string]: string | boolean | number | null | undefined | AnyObject | AnyObject[] | Moment;
};

declare module '@mui/material/styles' {
	interface Palette {
		neutral: {
			100: string;
			200: string;
			300: string;
			400: string;
			500: string;
			600: string;
			700: string;
			800: string;
			900: string;
		};
	}

	interface PaletteOptions {
		neutral: {
			100: string;
			200: string;
			300: string;
			400: string;
			500: string;
			600: string;
			700: string;
			800: string;
			900: string;
		};
		main: {
			100: string;
			200: string;
			300: string;
			400: string;
			500: string;
			600: string;
			700: string;
			800: string;
			900: string;
		};
		minor: {
			100: string;
			200: string;
			300: string;
			400: string;
			500: string;
			600: string;
			700: string;
			800: string;
			900: string;
		};
	}
}

export interface IPage extends Omit<NonIndexRouteObject, 'children'> {
	title: string;
	pageType: PageType;
	useLayout?: boolean;
	useInSearch?: boolean;
	useBreadcrumbs?: boolean /** default true */;
	breadcrumbs?: IBreadcrumb[];
	config?: IEntityConfig;
	children?: IPage[];
	customElement?: ReactNode;
	errorElement?: ReactNode;
	disabled?: boolean;

	/**
	 * If you want to manage page access in security forms, provide `id`, `protected` and `requiredAccess` fields;
	 * @example
	 * { ... , securityId: 'users', path:'/users', protected: true, requiredAccess: ['read'] }
	 * { ... , securityId: 'users', path:'/users/create', protected: true, requiredAccess: ['create'] }
	 * { ... , securityId: 'users', path:'/users/:id/edit', protected: true, requiredAccess: ['update'] }
	 * { ... , securityId: 'users', path:'/users/:id/delete', protected: true, requiredAccess: ['delete'] }
	 */

	/**
	 * @description might not be unique. This is an identifier for connected pages like `/users/create`, `/users/update`.
	 */
	securityId?: string;
	/** @description true if not provided */
	protected?: boolean;
	/** @description only for `protected` pages */
	requiredAccess?: Access[];
}

export interface IConfig {
	/** @description content internationalization languages */
	languages: string[];
	pages: IPage[];
	dateFormat: string;
	timeFormat: '12h' | '24h';
	sidebar: ISidebarConfig;
	/** @description seconds after 00:00 */
	scheduleReportRunTime: number;
}

export interface IPagination {
	limit?: number;
	offset?: number;
}

export enum OrderType {
	asc = 'asc',
	desc = 'desc',
}

export interface IOrder {
	order?: OrderType;
	orderBy?: string;
}

export type ButtonVariant = 'text' | 'contained' | 'outlined';

export type PaletteColor = 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success';

export type ThemeMode = 'dark' | 'light';

export type ThemeDirection = 'ltr' | 'rtl';

export type EntityData = IPlatform | IDeployment | IApp;

export interface ITheme {
	direction: ThemeDirection;
	responsiveFontSizes: boolean;
	mode: ThemeMode;
}

export interface IFetchParams {
	[key: string]: {
		type: 'single' | 'multiple';
		routeVariable: string;
	};
}

export interface IFetchOptions {
	endpoint: string;
	params?: IFetchParams;
}

export interface IPostOptions {
	endpoint: Record<FormMode, string>;
	params?: never;
}

export type RequestOptions = IFetchOptions | IPostOptions;

export type ValueOf<T> = T[keyof T];

export interface ISidebarItem {
	title: string;
	path: string;
	/** @description must be same with id in pages */
	pageId?: string;
	isExternal?: boolean;
	Icon?: SvgIconComponent;
	chip?: ReactNode;
	info?: ReactNode;
	children?: Array<ISidebarItem>;
	/** @description show additional list while item is pressed */
	pressChildren?: Array<Omit<ISidebarItem, 'Icon' | 'chip' | 'info' | 'children'>>;
	/** @description additional item availability check */
	isAvailable?: (user: IUser) => boolean;
}

export interface ISidebarSection {
	title: string;
	items: Array<ISidebarItem>;
}

export interface ISidebarConfig {
	sections: ISidebarSection[];
}

export type FormError =
	| string
	| FieldError
	| Partial<{
			type: string | number;
			message: string;
	  }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	| Merge<FieldError, FieldErrorsImpl<any>>
	| undefined;

export interface IDatePreset extends PresetDate<[EventValue<Moment>, EventValue<Moment>]> {
	previousPeriod: [EventValue<Moment>, EventValue<Moment>];
}
