// modules
import { FC } from 'react';
import { isRouteErrorResponse, Navigate, useLocation, useRouteError } from 'react-router-dom';
// utils
import { useAuth } from 'store/slices/auth';
import { path } from 'consts';

export const ErrorBoundary: FC = () => {
	const error = useRouteError();
	const location = useLocation();
	const { isAuthorized } = useAuth();

	if (!isAuthorized) {
		if (location.pathname === path.root) return <Navigate to={path.login} replace />;
		return <Navigate to={path.unauthorized} replace />;
	}

	if (isRouteErrorResponse(error)) {
		switch (error.status) {
			case 401:
				return <Navigate to={path.unauthorized} replace />;
			case 404:
				return <Navigate to={path.notFound} />;
		}
	}

	return <Navigate to={path.undefinedError} replace />;
};
