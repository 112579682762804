// modules
import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IVersionProps {
	customRender?: (version: string) => ReactNode;
	sx?: SxProps;
}

const parseVersionLabel = (version?: string) => {
	if (!version) return '';
	const split = version.replace('v', '').split('.');
	const major = Number(split[0]);
	const minor = Number(split[1]);
	const patch = Number(split[2].split('-')[0]);
	if (Number.isNaN(major) || Number.isNaN(minor) || Number.isNaN(patch)) return;

	return `${major}.${minor}.${patch}`;
};

export const Version: FC<IVersionProps> = (props) => {
	const version = parseVersionLabel(process.env.REACT_APP_VERSION);
	if (!version) return null;
	const { sx, customRender } = props;

	const renderLabel = () => {
		if (customRender) return customRender(version);
		return `v${version}`;
	};

	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				right: 0,
				color: 'text.default',
				px: 1,
				fontSize: 12,
				zIndex: 1300,
				backgroundColor: 'background.default',
				...sx,
			}}
		>
			{renderLabel()}
		</Box>
	);
};
