// modules
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
// types
import { IDropdownOption } from 'types/form';
import { IApplication, IAppModeWithEvents } from '../../types/application';
import { IAppModeEvents } from '../../types/appModeEvents';
import { RootState } from '../types';

type listOfDeploymentsTuple = [
	first: DeploymentsState['listOfDeployments'],
	second: DeploymentsState['listOfPlatforms'],
	thirth: DeploymentsState['listOfPWithDNames']
];
type SharedItemUpdateApp = { id: string; data: IApplication; entity: 'app' };
type SharedItemUpdateMode = { id: string; data: IAppModeWithEvents; entity: 'app-mode' };
type SharedItemUpdateEvent = { id: string; data: IAppModeEvents; entity: 'event' };
type SharedItemDeleteMode = { id: string; modeIds: string[] };
type SharedItemDeleteEvent = { id: string; modeId: string; eventIds: string[] };
type SharedItemUpdateActionPayload = SharedItemUpdateApp | SharedItemUpdateMode | SharedItemUpdateEvent;
export type ActionTrigger = 'platform' | 'deployment' | '' | 'add';

export interface Deployment {
	uid: string;
	id: string;
	name: { [key: string]: string };
	platformUid: string;
	platformId: string;
	platformName: { [key: string]: string };
	applicationGroup: string[];
}

export interface EditableDeployment extends Deployment {
	edited: boolean;
	linkingMode: boolean;
	linkingShop: boolean;
	applicationAttributes: IApplication;
}

type DeploymentsState = {
	currentApp: IApplication | null;
	initialSearchParams: string;
	linkingModeCheckbox: boolean;
	linkingShopCheckbox: boolean;
	allDeployments: Deployment[];
	filteredDeployments: Deployment[];
	selectedPlatform: Record<string, string>;
	selectedDeployments: string[];
	listOfDeployments: Record<string, Deployment>;
	listOfPlatforms: Record<string, Deployment[]>;
	listOfPWithDNames: Record<string, string[]>;
	listOfDNamesToId: Record<string, string[]>;
	sharedItems: EditableDeployment[];
};

const initialState: DeploymentsState = {
	currentApp: null,
	initialSearchParams: '',
	linkingModeCheckbox: false,
	linkingShopCheckbox: false,
	allDeployments: [],
	filteredDeployments: [],
	selectedPlatform: {},
	selectedDeployments: [],
	listOfDeployments: {},
	listOfPlatforms: {},
	listOfPWithDNames: {},
	listOfDNamesToId: {},
	sharedItems: [],
};

export const shareApplications = createSlice({
	name: 'shareApplications',
	initialState,
	reducers: {
		clearState: () => {
			return initialState;
		},
		setInitialSearchParams: (state, action: PayloadAction<string>) => {
			state.initialSearchParams = action.payload;
		},
		clearSelectedItems: (state) => {
			state.selectedDeployments = [];
			state.selectedPlatform = {};
			state.sharedItems = [];
		},
		setAllDeployments: (state, action: PayloadAction<Deployment[]>) => {
			state.allDeployments = action.payload;
		},
		setCurrentApp: (state, action: PayloadAction<IApplication>) => {
			state.currentApp = { ...action.payload, ...action.payload.extConfig };
			state.filteredDeployments = state.allDeployments.filter(({ applicationGroup }) => !applicationGroup.includes(action.payload.uid));
			const [listOfD, listOfP, listOfPWithDNames] = state.filteredDeployments.reduce(
				(acc: listOfDeploymentsTuple, depl) => {
					acc[0] = { ...acc[0], [depl.id]: depl };
					if (!acc[1][depl.platformId]) {
						acc[1][depl.platformId] = [];
						acc[2][depl.platformId] = [];
					}
					acc[1][depl.platformId].push(depl);
					acc[2][depl.platformId].push(depl.name['en'] || depl.name[Object.keys(depl.name)[0]]);
					return [acc[0], acc[1], acc[2]];
				},
				[{}, {}, {}]
			);
			state.listOfDeployments = listOfD;
			state.listOfPlatforms = listOfP;
			state.listOfPWithDNames = listOfPWithDNames;
			state.listOfDNamesToId = reverseLookup(listOfPWithDNames);
		},

		setSelectedPlatform: (state, action: PayloadAction<{ ids: string[]; actionTrigger: ActionTrigger }>) => {
			if (action.payload.actionTrigger === 'add' || action.payload.actionTrigger === 'deployment') return;

			const oldSelectedPlatformIds = new Set(Object.keys(state.selectedPlatform));
			const newSelectedPlatformIds = new Set(action.payload.ids);

			const newlyAdded = [...Array.from(newSelectedPlatformIds)].filter((pid) => !oldSelectedPlatformIds.has(pid));
			const newlyRemoved = [...Array.from(oldSelectedPlatformIds)].filter((pid) => !newSelectedPlatformIds.has(pid));

			const newPlatformRecord: Record<string, string> = {};
			action.payload.ids.forEach((pId) => {
				const platformName = state.listOfPlatforms[pId]?.[0].platformName;
				if (platformName) {
					newPlatformRecord[pId] = platformName['en'] || platformName[Object.keys(platformName)[0]];
				}
			});
			state.selectedPlatform = newPlatformRecord;

			const updatedSharedItems = [...state.sharedItems];

			const selectedDeploymentNames = [...state.selectedDeployments];
			newlyAdded.forEach((pId) => {
				const deploymentsOfPlatform = state.listOfPlatforms[pId] || [];
				selectedDeploymentNames.forEach((depName) => {
					const found = deploymentsOfPlatform.find((d) => {
						const dName = d.name['en'] || d.name[Object.keys(d.name)[0]];
						return dName === depName;
					});
					if (found) {
						const alreadyExists = updatedSharedItems.some((item) => item.id === found.id);
						if (!alreadyExists) {
							updatedSharedItems.push({
								...found,
								edited: false,
								linkingMode: false,
								linkingShop: false,
								applicationAttributes: state.currentApp || ({} as IApplication),
							});
						}
					}
				});
			});

			newlyRemoved.forEach((removedPid) => {
				for (let i = updatedSharedItems.length - 1; i >= 0; i--) {
					if (updatedSharedItems[i].platformId === removedPid) {
						updatedSharedItems.splice(i, 1);
					}
				}
			});

			state.sharedItems = updatedSharedItems;
		},

		setSelectedDeployments: (state, action: PayloadAction<{ ids: string[]; actionTrigger: ActionTrigger }>) => {
			if (action.payload.actionTrigger === 'add' || action.payload.actionTrigger === 'platform') return;

			const oldSelected = new Set(state.selectedDeployments);
			const newSelected = new Set(action.payload.ids);

			const newlyAdded = [...Array.from(newSelected)].filter((depName) => !oldSelected.has(depName));
			const newlyRemoved = [...Array.from(oldSelected)].filter((depName) => !newSelected.has(depName));

			state.selectedDeployments = action.payload.ids;

			const selectedPlatformIds = Object.keys(state.selectedPlatform);
			const updatedSharedItems = [...state.sharedItems];

			newlyAdded.forEach((depName) => {
				selectedPlatformIds.forEach((pId) => {
					const deploymentsOfPlatform = state.listOfPlatforms[pId] || [];
					const found = deploymentsOfPlatform.find((d) => {
						const dName = d.name['en'] || d.name[Object.keys(d.name)[0]];
						return dName === depName;
					});
					if (found) {
						const alreadyExists = updatedSharedItems.some((item) => item.id === found.id);
						if (!alreadyExists) {
							const newEditable: EditableDeployment = {
								...found,
								edited: false,
								linkingMode: false,
								linkingShop: false,
								applicationAttributes: cloneDeep(state.currentApp) || ({} as IApplication),
							};
							updatedSharedItems.push(newEditable);
						}
					}
				});
			});

			newlyRemoved.forEach((depName) => {
				for (let i = updatedSharedItems.length - 1; i >= 0; i--) {
					const item = updatedSharedItems[i];
					const itemDepName = item.name['en'] || item.name[Object.keys(item.name)[0]];
					if (itemDepName === depName) {
						updatedSharedItems.splice(i, 1);
					}
				}
			});

			state.sharedItems = updatedSharedItems;
		},

		setSharedItems: (state, action: PayloadAction<EditableDeployment>) => {
			const newPlatforms: Record<string, string> = {};
			const newDeployment: Set<string> = new Set();
			const updatedSharedItems = [...state.sharedItems, action.payload];
			updatedSharedItems.forEach(({ name, platformName, platformId }) => {
				newDeployment.add(name['en'] || name[Object.keys(name)[0]]);
				newPlatforms[platformId] = platformName['en'] || platformName[Object.keys(name)[0]];
			});
			if (Object.keys(newPlatforms).length > Object.keys(state.selectedPlatform).length) {
				state.selectedPlatform = newPlatforms;
			}
			if (newDeployment.size > state.selectedDeployments.length) {
				state.selectedDeployments = Array.from(newDeployment);
			}
			state.sharedItems = updatedSharedItems;
		},

		updateShareItem: (state, action: PayloadAction<SharedItemUpdateActionPayload>) => {
			const indexOfEditedDeployment = state.sharedItems.findIndex((item) => item.id === action.payload.id);
			if (indexOfEditedDeployment !== -1) {
				switch (action.payload.entity) {
					case 'app': {
						state.sharedItems[indexOfEditedDeployment].edited = true;
						state.sharedItems[indexOfEditedDeployment].applicationAttributes = action.payload.data;
						break;
					}
					case 'app-mode': {
						const modeId = action.payload.data.id;
						const currentModeIndex = state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes.findIndex((mode) => mode.id === modeId);
						if (currentModeIndex !== -1) {
							state.sharedItems[indexOfEditedDeployment].edited = true;
							state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes[currentModeIndex] = action.payload.data;
						} else {
							alert('dispatch updateShareItem case app-mode => currentModeIndex === -1');
						}
						break;
					}
					case 'event': {
						const eventId = action.payload.data.id;
						const appModeId = action.payload.data.appModeId;
						const currentModeIndex = state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes.findIndex(
							(mode) => mode.id === appModeId
						);
						if (currentModeIndex !== -1) {
							const currentEventIndex = state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes[currentModeIndex].events.findIndex(
								(event) => event.id === eventId
							);
							if (currentEventIndex !== -1) {
								state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes[currentModeIndex].events[currentEventIndex] =
									action.payload.data;
							} else {
								alert('dispatch updateShareItem case event => currentEventIndex === -1');
							}
						} else {
							alert('dispatch updateShareItem case event => currentModeIndex === -1');
						}
						break;
					}
				}
			}
		},

		deleteSharedMode: (state, action: PayloadAction<SharedItemDeleteMode>) => {
			const indexOfEditedDeployment = state.sharedItems.findIndex((item) => item.id === action.payload.id);
			state.sharedItems[indexOfEditedDeployment].edited = true;
			state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes = state.sharedItems[
				indexOfEditedDeployment
			].applicationAttributes.modes.filter((mode) => !action.payload.modeIds.includes(mode.id));
		},

		deleteSharedEvent: (state, action: PayloadAction<SharedItemDeleteEvent>) => {
			const indexOfEditedDeployment = state.sharedItems.findIndex((item) => item.id === action.payload.id);
			const indexOfEditedMode = state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes.findIndex(
				(mode) => mode.id === action.payload.modeId
			);
			state.sharedItems[indexOfEditedDeployment].edited = true;
			state.sharedItems[indexOfEditedDeployment].applicationAttributes.modes[indexOfEditedMode].events = state.sharedItems[
				indexOfEditedDeployment
			].applicationAttributes.modes[indexOfEditedMode].events.filter((event) => !action.payload.eventIds.includes(event.id));
		},

		deleteSharedItem: (state, action: PayloadAction<string>) => {
			const updatedSharedItems = state.sharedItems.filter((item) => item.id !== action.payload);
			updatePlatformsAndDeployments(state, updatedSharedItems);
			state.sharedItems = updatedSharedItems;
		},

		multiDeleteSharedItems: (state, action: PayloadAction<Set<string>>) => {
			const updatedSharedItems = state.sharedItems.filter((item) => !action.payload.has(item.id));
			updatePlatformsAndDeployments(state, updatedSharedItems);
			state.sharedItems = updatedSharedItems;
		},

		setLinkingMode: (state, action: PayloadAction<{ id: string; value: boolean }>) => {
			const index = state.sharedItems.findIndex((el) => el.id === action.payload.id);
			if (action.payload.value && state.currentApp) {
				state.sharedItems[index].applicationAttributes.modes = state.currentApp.modes;
			}
			state.sharedItems[index].linkingMode = action.payload.value;
		},
		toggleLinkingModes: (state, action: PayloadAction<'on' | 'off'>): void => {
			switch (action.payload) {
				case 'on': {
					state.sharedItems = state.sharedItems.map((item) => ({ ...item, linkingMode: true }));
					state.linkingModeCheckbox = true;
					break;
				}
				case 'off': {
					state.sharedItems = state.sharedItems.map((item) => ({ ...item, linkingMode: false }));
					state.linkingModeCheckbox = false;
					break;
				}
				default: {
					return action.payload;
				}
			}
		},
		setLinkingShop: (state, action: PayloadAction<{ id: string; value: boolean }>): void => {
			const index = state.sharedItems.findIndex((el) => el.id === action.payload.id);
			state.sharedItems[index].linkingShop = action.payload.value;
		},
		toggleLinkingShop: (state, action: PayloadAction<'on' | 'off'>): void => {
			switch (action.payload) {
				case 'off': {
					state.sharedItems = state.sharedItems.map((item) => ({ ...item, linkingShop: false }));
					state.linkingShopCheckbox = false;
					break;
				}
				case 'on': {
					state.sharedItems = state.sharedItems.map((item) => ({ ...item, linkingShop: true }));
					state.linkingShopCheckbox = true;
					break;
				}
				default: {
					return action.payload;
				}
			}
		},
	},
});

export const useShareApplication = () => useSelector((state: RootState) => state.shareApplication);

export const getLinkingModeCheckboxValue = (state: RootState) => state.shareApplication.linkingModeCheckbox;

export const getLinkingShopCheckboxValue = (state: RootState) => state.shareApplication.linkingShopCheckbox;

export const getUnselected_D_P = (state: RootState) => {
	const selectedP = state.shareApplication.selectedPlatform;
	const selectedDSet = new Set(state.shareApplication.selectedDeployments);
	const listOfPWithDNames = state.shareApplication.listOfPWithDNames;
	const listOfP = state.shareApplication.listOfPlatforms;
	const unselectedPlatform: Record<string, string> = Object.keys(listOfP).reduce((acc, id) => {
		const platformName = listOfP[id][0].platformName;
		return { ...acc, [id]: platformName['en'] || platformName[Object.keys(platformName)[0]] };
	}, {});
	for (const id in selectedP) {
		if (listOfPWithDNames[id].every((name) => selectedDSet.has(name))) {
			delete unselectedPlatform[id];
		}
	}
	return {
		setOfPlatformKeys: new Set(Object.keys(unselectedPlatform)),
		ddOptions: Object.keys(unselectedPlatform).map((value) => ({ value, label: unselectedPlatform[value] })),
	};
};

export const getDeploymentsAsDropdownOptions = (state: RootState): IDropdownOption[] => {
	const setNameOfDeployments = new Set(state.shareApplication.filteredDeployments.map(({ name }) => name['en'] || name[Object.keys(name)[0]]));
	const dropdownOptions = Array.from(setNameOfDeployments).map((value) => ({ value, label: value }));
	return dropdownOptions;
};

export const getUniqPlatformsAsDropdownOptions = (state: RootState): IDropdownOption[] => {
	const uniqPlatforms: Record<string, string> = {};
	state.shareApplication.filteredDeployments.forEach(({ platformId, platformName }) => {
		if (!uniqPlatforms[platformId]) {
			uniqPlatforms[platformId] = platformName['en'] || platformName[Object.keys(platformName)[0]];
		}
	});
	return Object.keys(uniqPlatforms).map((value) => ({ value, label: uniqPlatforms[value] }));
};

const updatePlatformsAndDeployments = (state: DeploymentsState, updatedSharedItems: EditableDeployment[]) => {
	const newPlatforms: Record<string, string> = {};
	const newDeployment: Set<string> = new Set();
	updatedSharedItems.forEach(({ name, platformName, platformId }) => {
		newDeployment.add(name['en'] || name[Object.keys(name)[0]]);
		newPlatforms[platformId] = platformName['en'] || platformName[Object.keys(name)[0]];
	});

	if (Object.keys(newPlatforms).length < Object.keys(state.selectedPlatform).length) {
		state.selectedPlatform = newPlatforms;
	}
	if (newDeployment.size < state.selectedDeployments.length) {
		state.selectedDeployments = Array.from(newDeployment);
	}
};

export const reverseLookup = (uuidMap: Record<string, string[]>) => {
	const stringToUuids: Record<string, string[]> = {};

	Object.entries(uuidMap).forEach(([uuid, strings]) => {
		strings.forEach((string) => {
			if (!stringToUuids[string]) {
				stringToUuids[string] = [];
			}
			stringToUuids[string].push(uuid);
		});
	});

	return stringToUuids;
};
