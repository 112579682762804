// modules
import lowerCase from 'lodash/lowerCase';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import upperCase from 'lodash/upperCase';
import moment from 'moment';
import { FC, PropsWithChildren, createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// utils
import { ANALYTICS_SECTIONS, GLOBAL_ANALYTICS_VARIABLE_PREFIX, pathVars } from 'consts';
import { useIntervalLoading } from 'hooks/useIntervalLoading';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAnalyticsQuery } from 'service/analytics';
import { getAnalyticsVariableLabel } from '../utils/getAnalyticsVariableLabel';
import { DEFAULT_ANALYTICS_VARIABLES } from './defaultVariables';
// types
import { AnalyticsMetrics } from 'types/analytics';
import { IDropdownGroupOption, IDropdownOption } from 'types/form';
import { CustomVariableGroup, CustomVariableSubgroup, ICustomVariable, VariableFormat } from '../types';

export interface IAnalyticsCustomVariablesContext {
	addVariable: (variable: ICustomVariable) => void;
	variables: Map<string, ICustomVariable>;
	options: Array<IDropdownGroupOption<ICustomVariable>>;
	getPureVariableValue: (variable: ICustomVariable) => Array<string | number>;
	getOptionsBySearch: (search: string, limit?: number) => IDropdownGroupOption<ICustomVariable>[];
	loading: boolean;
}

export interface IAnalyticsCustomVariablesProviderProps extends PropsWithChildren {
	variables?: ICustomVariable[];
}

const ARITHMETIC_OPERATORS = ['+', '-', '*', '/', '%', '(', ')'];

/**
 * @description
 * 1. Sort by group;
 * 2. Sort by name;
 * */
const sortVariables = (variables: ICustomVariable[]) =>
	variables.sort((a, b) => {
		const groupA = snakeCase(a.group);
		const groupB = snakeCase(b.group);
		const nameA = snakeCase(a.name);
		const nameB = snakeCase(b.name);
		const groupCompare = groupA.localeCompare(groupB);
		const nameCompare = nameA.localeCompare(nameB);
		if (groupCompare !== 0) return groupCompare;
		return nameCompare;
	});

const variablesToDropdownGroupOption = (variables: Map<string, ICustomVariable>) => {
	const items = sortVariables(Array.from(variables.values()));
	const variablesBelongToUsers: Record<string, IDropdownOption<ICustomVariable>[]> = {};
	const groups: Record<string, IDropdownOption<ICustomVariable>[]> = {};
	items.forEach((item) => {
		const belongToUser = item.subgroup === CustomVariableSubgroup.ownVariables;
		const formula = belongToUser ? item.value.join(' ') : '';
		const normalizedItem = { label: getAnalyticsVariableLabel(item), value: item.id, hint: formula, helperText: item.description, data: item };
		const targetObject = item.subgroup === CustomVariableSubgroup.ownVariables ? variablesBelongToUsers : groups;
		if (targetObject[item.subgroup]) targetObject[item.subgroup].push(normalizedItem);
		else targetObject[item.subgroup] = [normalizedItem];
	}, {});

	const globalVariables = Object.entries(groups).map(([group, options]) => ({
		label: startCase(group),
		options,
	})) as IDropdownGroupOption<ICustomVariable>[];
	const userVariables = Object.entries(variablesBelongToUsers).map(([group, options]) => ({
		label: startCase(group),
		options,
	})) as IDropdownGroupOption<ICustomVariable>[];
	return [...userVariables, ...globalVariables];
};

const initialValue: IAnalyticsCustomVariablesContext = {
	addVariable: () => null,
	variables: new Map(),
	options: [],
	getPureVariableValue: () => [],
	getOptionsBySearch: () => [],
	loading: true,
};

export const AnalyticsCustomVariablesContext = createContext<IAnalyticsCustomVariablesContext>(initialValue);

const _AnalyticsCustomVariablesContextProvider: FC<IAnalyticsCustomVariablesProviderProps> = (props) => {
	const { variables: providedVariables, children } = props;
	const { variables: userVariables } = useUserConfig();
	const location = useLocation();
	const [variables, setVariables] = useState<Map<string, ICustomVariable>>(() => DEFAULT_ANALYTICS_VARIABLES);
	const [request, response] = useAnalyticsQuery<{ data: Array<{ eventcategory?: string; eventaction?: string }> }>(
		'actions-by-category',
		ANALYTICS_SECTIONS.custom
	);
	const loading = useIntervalLoading(response.isFetching, { wait: 400, initial: true });
	const hasData = response.data?.data || [];
	const skip = Boolean(hasData.length || !location.pathname.includes(pathVars.customReports));

	const hasProvidedVariables = Boolean(providedVariables?.length);

	const downloadedVariables = useMemo(() => {
		const result = new Map();
		(response.data?.data || []).forEach((i) => {
			if (!i.eventaction || !i.eventcategory) return;
			result.set(i.eventaction, {
				id: i.eventaction,
				name: i.eventaction,
				value: [i.eventaction],
				pureValue: [i.eventaction],
				group: CustomVariableGroup.submetric,
				subgroup: i.eventcategory as CustomVariableSubgroup,
				metric: AnalyticsMetrics.eventaction,
				filters: [
					{ metric: AnalyticsMetrics.eventcategory, included: [i.eventcategory] },
					{ metric: AnalyticsMetrics.eventaction, included: [i.eventaction] },
				],
				valueMetric: AnalyticsMetrics.eventscount,
				format: VariableFormat.number,
			});
		}, []);
		return result;
	}, [response.data?.data?.length]);

	useEffect(() => {
		if (hasProvidedVariables || skip) return;
		const params: Record<string, unknown> = {
			startdate: moment().subtract(3, 'month'),
			enddate: moment(),
			metrics: [AnalyticsMetrics.eventaction, AnalyticsMetrics.eventcategory],
		};
		request({ params });
	}, [hasProvidedVariables, skip]);

	useEffect(() => {
		/** @description override all variables with if provided in props */
		if (providedVariables) {
			setVariables(new Map(providedVariables.map((i) => [i.id, i])));
			return;
			/** @description put downloaded variables and default variables */
		}
		if (downloadedVariables.size) {
			const merged = new Map();
			userVariables.forEach((i) => merged.set(i.id, i));
			DEFAULT_ANALYTICS_VARIABLES.forEach((i) => {
				const isAlreadyDefined = merged.has(i.id);
				if (isAlreadyDefined) return;
				merged.set(i.id, i);
			});
			downloadedVariables.forEach((value, key) => {
				const isAlreadyDefined = DEFAULT_ANALYTICS_VARIABLES.has(key);
				if (isAlreadyDefined) return;
				merged.set(key, value);
			});
			setVariables(merged);
		} else {
			const merged = new Map();
			userVariables.forEach((i) => merged.set(i.id, i));
			DEFAULT_ANALYTICS_VARIABLES.forEach((i) => {
				const isAlreadyDefined = merged.has(i.id);
				if (isAlreadyDefined) return;
				merged.set(i.id, i);
			});
			setVariables(merged);
		}
	}, [providedVariables, downloadedVariables, userVariables]);

	const addVariable = useCallback(
		(variable: ICustomVariable) => {
			const updatedVariables = new Map(variables);
			updatedVariables.set(variable.id, variable);
			setVariables(updatedVariables);
		},
		[variables]
	);

	const getPureVariableValue = useCallback(
		(variable: ICustomVariable): Array<string | number> => {
			if (variable.pureValue?.length) return variable.pureValue;
			return variable.value.reduce<Array<string | number>>((acc, valueItem) => {
				const isCustomVariable =
					isNaN(Number(valueItem)) &&
					!ARITHMETIC_OPERATORS.includes(valueItem.toString()) &&
					!valueItem.toString().includes(GLOBAL_ANALYTICS_VARIABLE_PREFIX);
				if (!isCustomVariable) return [...acc, valueItem];
				const customVariable = variables.get(valueItem.toString());
				const customVariableValue =
					customVariable && customVariable.value.length > 1 ? ['(', ...getPureVariableValue(customVariable), ')'] : [valueItem];
				return [...acc, ...customVariableValue];
			}, []);
		},
		[variables]
	);

	/** @description - `limit` per category. Default `100` */
	const getOptionsBySearch = useCallback(
		(search: string, limit = 100) => {
			const searchStr = lowerCase(search.trim());
			const categoryItemsLimit = limit;
			const foundVariables: ICustomVariable[] = [];
			variables.forEach((variable) => {
				/** @description there's no need to limit important categories like ads. Paste here garbage categories with large amount of records */
				const useLimit = [CustomVariableSubgroup.custom, CustomVariableSubgroup.crossPromotionDetails].includes(variable.subgroup);
				if (useLimit && foundVariables.length >= categoryItemsLimit) return;
				const name = lowerCase(variable.name.trim());
				const isSearchPass = !searchStr || name.includes(searchStr);
				if (!isSearchPass) return;
				foundVariables.push(variable);
			});
			const categories = foundVariables.reduce<Record<string, IDropdownOption<ICustomVariable>[]>>((acc, curr) => {
				const category = curr.subgroup;
				const item = { label: getAnalyticsVariableLabel(curr), value: curr.id, data: curr };
				if (acc[category]) acc[category].push(item);
				else acc[category] = [item];
				return acc;
			}, {});

			const res = Object.keys(categories)
				.sort((a, b) => {
					switch (true) {
						case b === CustomVariableSubgroup.ownVariables && a !== CustomVariableSubgroup.ownVariables:
							return 1;
						case a === CustomVariableSubgroup.ownVariables && b !== CustomVariableSubgroup.ownVariables:
							return -1;
						case b === CustomVariableSubgroup.predefinedVariables && a !== CustomVariableSubgroup.predefinedVariables:
							return 1;
						case a === CustomVariableSubgroup.predefinedVariables && b !== CustomVariableSubgroup.predefinedVariables:
							return -1;
						case b === CustomVariableSubgroup.general && a !== CustomVariableSubgroup.general:
							return 1;
						case a === CustomVariableSubgroup.general && b !== CustomVariableSubgroup.general:
							return -1;
						case b === CustomVariableSubgroup.custom:
							return -1;
						case a === CustomVariableSubgroup.custom:
							return 1;
						default:
							return a.localeCompare(b);
					}
				})
				.map((category) => ({ label: upperCase(category), options: categories[category] }));
			return res as IDropdownGroupOption<ICustomVariable>[];
		},
		[variables]
	);

	const options = useMemo(() => variablesToDropdownGroupOption(variables), [variables]);

	const value = useMemo(
		() => ({
			loading,
			variables,
			options,
			addVariable,
			getPureVariableValue,
			getOptionsBySearch: getOptionsBySearch as (search: string, limit?: number) => IDropdownGroupOption<ICustomVariable>[],
		}),
		[loading, variables, options, addVariable, getPureVariableValue]
	);

	return <AnalyticsCustomVariablesContext.Provider value={value}>{children}</AnalyticsCustomVariablesContext.Provider>;
};

export const useCustomVariablesContext = () => useContext(AnalyticsCustomVariablesContext);

export const AnalyticsCustomVariablesContextProvider = memo(_AnalyticsCustomVariablesContextProvider);
