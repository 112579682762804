// modules
import moment, { Moment } from 'moment';

/**
 * @description
 * Parses moment to timestamp seconds at start of a day without timezone
 * @example
 * dateToSeconds({ * Moment * }) // 123456789456
 */
export const dateToSeconds = (date?: Moment, dateFromDay?: 'start' | 'end', format?: string) => {
	if (!date) return 0;
	if (typeof date === 'number') return date;
	let normalized;
	if (dateFromDay === 'end') normalized = moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
	else normalized = moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
	return Number(moment.utc(normalized).format(format || 'X'));
};
