// modules
import { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
// utils
import { useUserConfig } from 'hooks/useUserConfig';
import { ANALYTICS_SECTIONS } from 'consts';
import { dropdownOptionsToString } from './dropdownOptionsToString';
import { pages } from 'config/pages';
// types
import { ValueOf } from 'types/general';
import { AnalyticsContentItem } from 'store/types';
import { IAnalyticsParams } from 'types/analytics';
import { RuleType } from 'types/security';

export const useConfigRestrictions = () => {
	const { permissions, mergedPermissions } = useUserConfig();

	const tabsElements = useMemo(() => {
		return Object.values(mergedPermissions?.analytics?.tabs || {}).reduce<Record<string, AnalyticsContentItem>>((acc, c) => {
			c.content?.forEach((el) => {
				acc[el.id] = el;
			});
			return acc;
		}, {});
	}, [mergedPermissions]);

	/** @description get restricted filters for analytics requests */
	const getAllowedSectionFilters = useCallback(
		(section: ValueOf<typeof ANALYTICS_SECTIONS>) => {
			const { adtype, appname, apptype, country, eventaction, eventcategory, platform, source, mode, cohortsize } =
				mergedPermissions?.analytics?.tabs?.[section]?.filters || {};
			return {
				adtype: (adtype || []).map(dropdownOptionsToString),
				appname: (appname || []).map(dropdownOptionsToString),
				apptype: (apptype || []).map(dropdownOptionsToString),
				country: (country || []).map(dropdownOptionsToString),
				eventaction: (eventaction || []).map(dropdownOptionsToString),
				eventcategory: (eventcategory || []).map(dropdownOptionsToString),
				platform: (platform || []).map(dropdownOptionsToString),
				source: (source || []).map(dropdownOptionsToString),
				mode: (mode || []).map(dropdownOptionsToString),
				cohortsize,
			};
		},
		[mergedPermissions]
	);

	/** @description remove restricted filters */
	const filterAnalyticsSectionParams = useCallback(
		(section: ValueOf<typeof ANALYTICS_SECTIONS>, params: IAnalyticsParams) => {
			const allowedFilters = getAllowedSectionFilters(section);
			return {
				...params,
				adtype: params.adtype?.filter(({ value }) => !allowedFilters.adtype.length || allowedFilters.adtype.includes(value as string)),
				appname: params.appname?.filter(({ value }) => !allowedFilters.appname.length || allowedFilters.appname.includes(value as string)),
				apptype: params.apptype?.filter(({ value }) => !allowedFilters.apptype.length || allowedFilters.apptype.includes(value as string)),
				country: params.country?.filter(({ value }) => !allowedFilters.country.length || allowedFilters.country.includes(value as string)),
				eventaction: params.eventaction?.filter(
					({ value }) => !allowedFilters.eventaction.length || allowedFilters.eventaction.includes(value as string)
				),
				eventcategory: params.eventcategory?.filter(
					({ value }) => !allowedFilters.eventcategory.length || allowedFilters.eventcategory.includes(value as string)
				),
				platform: params.platform?.filter(({ value }) => !allowedFilters.platform.length || allowedFilters.platform.includes(value as string)),
				source: params.source?.filter(({ value }) => !allowedFilters.source.length || allowedFilters.source.includes(value as string)),
				mode: params.mode?.filter(({ value }) => !allowedFilters.mode.length || allowedFilters.mode.includes(value as string)),
				cohortsize: allowedFilters.cohortsize || params.cohortsize,
			};
		},
		[getAllowedSectionFilters]
	);

	/** @description check if analytics content item is not restricted (table, chart, etc.) */
	const isAnalyticsContentItemAvailable = useCallback(
		(elementId: string) => {
			if (!mergedPermissions || isEmpty(mergedPermissions?.analytics)) return true;
			return Boolean(tabsElements[elementId]?.visible);
		},
		[mergedPermissions, tabsElements]
	);

	/** @description checks whether page (found by provided path) is not restricted */
	const isPageAvailable = useCallback(
		(path: string) => {
			if (!path) return true;
			const page = pages.find((page) => page.path === path);
			if (!page?.requiredAccess?.length) return true;
			const rule = mergedPermissions?.rules?.find((rule) => rule.type === RuleType.page && rule.key === page.securityId);
			return page.requiredAccess.reduce((acc, c) => Boolean(acc && rule?.access?.includes(c)), true);
		},
		[mergedPermissions]
	);

	/** @description check if analytics section is not restricted */
	const isSectionAvailable = useCallback(
		(section: ValueOf<typeof ANALYTICS_SECTIONS>) => {
			if (!mergedPermissions || isEmpty(mergedPermissions)) return true;
			const isVisible = mergedPermissions?.analytics?.tabs?.[section]
				? mergedPermissions.analytics.tabs[section].visible && mergedPermissions?.analytics.tabsOrder?.includes(section)
				: false;
			return isVisible !== false;
		},
		[mergedPermissions]
	);

	/** @description check if analytics section filter is not restricted */
	const isAnalyticsFilterAvailable = useCallback(
		(section: ValueOf<typeof ANALYTICS_SECTIONS>, filter: string) => {
			if (!mergedPermissions || isEmpty(mergedPermissions)) return true;
			const isHidden = mergedPermissions?.analytics?.tabs?.[section].hiddenFilters?.includes(filter);
			return isHidden !== true;
		},
		[mergedPermissions]
	);

	return {
		getAllowedSectionFilters,
		isAnalyticsContentItemAvailable,
		isSectionAvailable,
		isPageAvailable,
		isAnalyticsFilterAvailable,
		permissions,
		mergedPermissions,
		hasPermissions: !isEmpty(permissions),
		filterAnalyticsSectionParams,
	};
};
