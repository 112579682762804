import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import { HostingItem } from './hosting';
import { AssetConfigureItem } from './assetsSettings';
import { IApplicationBaasAsset } from '../../types/application';

export interface AssetItem {
	assetType: string;
	size: string;
	resolution: string;
	imageUrl: string;
	name: string;
	id: string;
	isNew: boolean;
	bucketPath: string;
}

export interface AssetItemTableRow {
	assetType: string;
	resolution: string;
	size: string;
	imageUrl: string;
	name: string;
	id: string;
	isNew: boolean;
	bucketPath: string;
}

interface IState {
	assets: AssetItem[];
	files: Record<string, File>;
	assetList: Record<string, AssetConfigureItem>;
	hostingList: Record<string, HostingItem>;
	applicationAssets: Array<IApplicationBaasAsset>;
	isReadyToUpdate: boolean;
}

const initialState: IState = {
	assets: [],
	files: {},
	assetList: {},
	hostingList: {},
	applicationAssets: [],
	isReadyToUpdate: false,
};

export const appAssets = createSlice({
	name: 'appAssets',
	initialState,
	reducers: {
		clearState: () => initialState,
		addApplicationAssets: (state, action: PayloadAction<IApplicationBaasAsset[]>) => {
			state.applicationAssets = action.payload;
		},
		addItem: (state, action: PayloadAction<AssetItem & { shouldUpdate?: boolean }>) => {
			const { shouldUpdate = true, ...rest } = action.payload;
			state.assets.push(rest);
			if (shouldUpdate) {
				state.isReadyToUpdate = true;
			}
		},
		editItem: (state, action: PayloadAction<AssetItem>) => {
			const index = state.assets.findIndex((el) => action.payload.id === el.id);
			if (index !== -1) {
				state.assets[index] = action.payload;
				state.isReadyToUpdate = true;
			}
		},
		deleteItem: (state, action: PayloadAction<string>) => {
			state.assets = state.assets.filter((item) => item.imageUrl !== action.payload);
			delete state.files[action.payload];
			state.isReadyToUpdate = Boolean(state.assets.length);
		},
		saveFile: (state, action: PayloadAction<{ id: string; file: File }>) => {
			state.files = {
				...state.files,
				[action.payload.id]: action.payload.file,
			};
		},
		addAssetList: (state, action: PayloadAction<Record<string, AssetConfigureItem>>) => {
			state.assetList = action.payload;
		},
		addHostingList: (state, action: PayloadAction<Record<string, HostingItem>>) => {
			state.hostingList = action.payload;
		},
		changeIsReadyStatus: (state, action: PayloadAction<boolean>) => {
			state.isReadyToUpdate = action.payload;
		},
	},
});

export const useAssets = () => useSelector((state: RootState) => state.assets);
