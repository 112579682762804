// modules
import { createContext, useContext } from 'react';

export enum AnalyticsMode {
	'prod',
	'dev',
}

export interface IAnalyticsConfigContext {
	baseURL: string;
	mode: AnalyticsMode;
}

const defaultValues: IAnalyticsConfigContext = {
	baseURL: `${process.env.REACT_APP_ANALYTICS_ENDPOINT}`,
	mode: AnalyticsMode.prod,
};

export const AnalyticsConfigContext = createContext<IAnalyticsConfigContext>(defaultValues);

export const useAnalyticsConfigContext = () => useContext(AnalyticsConfigContext);
