// modules
import { Moment } from 'moment';
import { SxProps } from '@mui/material';
// types
import { IDropdownOption } from './form';
import { Config } from 'store/types';

export interface IAnalyticsClientSettings {
	host: string;
}

export interface IAnalyticsSessionData {
	activetime: number; // average active session time
	adprovider: string; // D3
	adtagname: string; // D6
	adtype: string; // D2
	advendor: string; // D1
	appname: string;
	apptype: string;
	avgeventvalue: number;
	country: string;
	creator: string; // D4
	d1: string;
	d2: string;
	d3: string;
	d4: string;
	d5: string;
	d6: string;
	devicemodel: string;
	eventaction: string;
	eventcategory: string;
	eventlabel: string;
	eventscount: number;
	eventvalue: number;
	genre: string;
	impression: number;
	inactivetime: number; // average inactive session time
	mode: string;
	newusers: number; // total new users
	opportunity: number;
	platform: string;
	rewarded: number;
	rewardedPerImpressions: number;
	sessions: number; // total count of sessions
	source: string;
	useragent: string; // D5
	users: number; // total active users count;
	'paying-users': number;
	/** @description count of combined records. Need for some calculations */
	_count?: number;
	/** @description free use */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_data?: any;
}

export interface IAnalyticsUserData extends IAnalyticsSessionData {
	date: string | number;
}

export interface IAnalyticsResponse<TData = IAnalyticsSessionData[]> {
	data: TData;
	isPacked: false;
}

export interface IAnalyticsResponsePacked {
	data: Array<number[]>;
	dict: string[];
	isPacked: true;
	propertyNames: string[];
	useDict: boolean[];
}

export enum AnalyticsMetrics {
	activetime = 'activetime' /** general category */,
	adprovider = 'adprovider', // D3 /** ads category */
	ads = 'ads',
	adtagname = 'adtagname', // D6 /** ads category */
	adtype = 'adtype', // D2 /** ads category */
	advendor = 'advendor', // D1 /** ads category */
	appname = 'appname' /** general category */,
	apptype = 'apptype' /** general category */,
	avgeventvalue = 'avgeventvalue',
	country = 'country' /** general category */,
	creator = 'creator', // D4 /** general category */
	d1 = 'd1',
	d2 = 'd2',
	d3 = 'd3',
	d4 = 'd4',
	d5 = 'd5',
	d6 = 'd6',
	date = 'date',
	devicemodel = 'devicemodel' /** general category */,
	eventaction = 'eventaction',
	eventcategory = 'eventcategory',
	eventlabel = 'eventlabel',
	eventscount = 'eventscount',
	eventvalue = 'eventvalue',
	genre = 'genre' /** general category */,
	inactivetime = 'inactivetime' /** general category */,
	mode = 'mode' /** general category */,
	newusers = 'newusers' /** general category */,
	payment = 'payment' /** payment category */,
	platform = 'platform' /** general category */,
	productid = 'productid',
	sessions = 'sessions' /** general category */,
	source = 'source' /** general category */,
	useragent = 'useragent', // D5 /** general category */
	users = 'users' /** general category */,
	// depends on ChartSize
	day = 'date',
	week = 'date_week',
	month = 'date_month',
}

export enum AdType {
	rewarded = 'rewarded',
	'pre-roll' = 'pre-roll',
	'mid-roll' = 'mid-roll',
	'post-roll' = 'post-roll',
	overlay = 'overlay',
	/** @description preload is a hidden adtype (works in request but not displayed everywhere) */
	// preload = 'preload'
}

export enum AnalyticsSyntheticMetrics {
	adsARPU = 'ads-arpu',
	adsCpm = 'adsCpm',
	adsRevenue = 'adsRevenue',
	avgIapCost = 'avgIapCost',
	avgSessionTime = 'avgSessionTime',
	avgViewedTime = 'avgViewedTime',
	bannerName = 'bannerName',
	brightData = 'bright-data',
	cohortsize = 'cohortsize',
	ctr = 'ctr',
	fillRate = 'fillRate',
	iapARPPU = 'iap-arppu',
	iapARPU = 'iap-arpu',
	iapConversionRate = 'iapConversionRate',
	iapPurchase = 'purchase',
	iapRecurringSubscription = 'recurring-subscription',
	iapRefund = 'refund',
	iapRevenue = 'iapRevenue',
	iapSales = 'iapSales',
	iapSubscription = 'subscription',
	iapTransactionsCount = 'iapTransactionsCount',
	impression = 'impression',
	launch = 'launch',
	opportunityPerSession = 'opportunityPerSession',
	opportunityPerUser = 'opportunityPerUser',
	overlay = 'overlay',
	overlayAdsARPU = 'overlayAdsARPU',
	overlayAdsCpm = 'overlayAdsCpm',
	overlayAdsRevenue = 'overlayAdsRevenue',
	overlayImpression = 'overlayImpression',
	payingUser = 'payingUsers',
	payingUsers = 'payingUsers',
	placement = 'placement',
	request = 'request',
	requestToPlay = 'request-to-play',
	response = 'response',
	responsesPerRequest = 'responsesPerRequest',
	returnedUsers = 'returnedUsers',
	rewarded = 'rewarded',
	rewardedPerImpressions = 'rewardedPerImpressions',
	sessionPerUser = 'sessionPerUser',
	started = 'started',
	totalRevenue = 'totalRevenue',
	totalRevenuePerUser = 'totalRevenuePerUser',
	totalTime = 'totalTime',
	totalTimePerUser = 'totalTimePerUser',
	videoAdsARPU = 'videoAdsARPU',
	videoAdsCpm = 'videoAdsCpm',
	videoAdsRevenue = 'videoAdsRevenue',
	videoImpression = 'videoImpression',
	videoTitle = 'videoTitle',
	videoViews = 'videoViews',
	view = 'view',
	viewedTime = 'viewed-time',
	brightDataRevenue = 'brightDataRevenue',
	brightDataARPU = 'brightDataARPU',
}

export interface IAnalyticsMetricsData {
	[AnalyticsMetrics.adtype]?: string[];
	[AnalyticsMetrics.appname]?: string[];
	[AnalyticsMetrics.apptype]?: string[];
	[AnalyticsMetrics.country]?: string[];
	[AnalyticsMetrics.eventaction]?: string[];
	[AnalyticsMetrics.eventcategory]?: string[];
	[AnalyticsMetrics.platform]?: string[];
	[AnalyticsMetrics.source]?: string[];
	[AnalyticsMetrics.mode]?: string[];
	[AnalyticsMetrics.genre]?: string[];
}

export interface IAnalyticsHash<AnalyticsFiltersType = IAnalyticsRequestParams> {
	config?: Partial<Config<AnalyticsFiltersType>>;
	createdBy?: string;
	dateFormat?: string;
	timeFormat?: '12h' | '24h';
}

export enum PostProcessors {
	crossPromotion = 'crossPromotion',
}

export interface IAnalyticsFilter {
	metric: AnalyticsMetrics;
	included?: string[];
	excluded?: string[];
}

/**
 * @description Internal params for forms
 * @WARNING - if something is changed, please check `mergePermissions` function
 */
export interface IAnalyticsParams {
	[AnalyticsMetrics.adtype]?: IDropdownOption[];
	[AnalyticsMetrics.appname]?: IDropdownOption[];
	[AnalyticsMetrics.apptype]?: IDropdownOption[];
	[AnalyticsMetrics.country]?: IDropdownOption[];
	[AnalyticsMetrics.eventaction]?: IDropdownOption[];
	[AnalyticsMetrics.eventcategory]?: IDropdownOption[];
	[AnalyticsMetrics.platform]?: IDropdownOption[];
	[AnalyticsMetrics.source]?: IDropdownOption[];
	[AnalyticsMetrics.mode]?: IDropdownOption[];
	[AnalyticsMetrics.genre]?: IDropdownOption[];
	cache?: 0 | 1;
	cohortsize?: IDropdownOption;
	date?: [Moment, Moment];
	enddate?: Moment;
	filters?: IAnalyticsFilter[];
	limit?: number;
	metrics?: AnalyticsMetrics[];
	orderby?: AnalyticsMetrics[];
	orderbyasc?: AnalyticsMetrics[];
	pack?: 0 | 1;
	postProcessors?: PostProcessors[];
	preset?: string;
	startdate?: Moment;
	userperapp?: 0 | 1;
	/** @description triggers comparison with previous period */
	useComparison?: boolean;
	/** @description used for comparison with previous period */
	previousDate?: [Moment, Moment];
}

/**
 * @description External params for requests
 * @WARNING - if something is changed, please check `mergePermissions` function
 */
export interface IAnalyticsRequestParams {
	[AnalyticsMetrics.adtype]?: string[];
	[AnalyticsMetrics.appname]?: string[];
	[AnalyticsMetrics.apptype]?: string[];
	[AnalyticsMetrics.country]?: string[];
	[AnalyticsMetrics.eventaction]?: string[];
	[AnalyticsMetrics.eventcategory]?: string[];
	[AnalyticsMetrics.platform]?: string[];
	[AnalyticsMetrics.source]?: string[];
	[AnalyticsMetrics.mode]?: string[];
	[AnalyticsMetrics.genre]?: string[];
	cache?: 0 | 1;
	enddate?: number;
	filters?: Array<{ metric: AnalyticsMetrics; included?: string[]; excluded?: string[] }>;
	limit?: number;
	metrics?: AnalyticsMetrics[];
	orderby?: AnalyticsMetrics[];
	orderbyasc?: AnalyticsMetrics[];
	pack?: 0 | 1;
	/** @description cohortsize */
	period?: string;
	postProcessors?: PostProcessors[];
	preset?: string;
	startdate?: number;
	userperapp?: 0 | 1;
}

/** don't change the order. indexes are used for legacy records */
export enum LaunchType {
	direct = 'direct',
	mainMenu = 'mainMenu',
	moreApps = 'moreApps',
	exitScreen = 'exitScreen',
	avodChannel = 'avodChannel',
	gamesPortal = 'gamesPortal',
	history = 'history',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAnalyticsItemProps<TData = any> {
	id: string;
	onLoad?: () => void;
	skip?: boolean;
	params?: IAnalyticsParams;
	width?: 6 | 12;
	sx?: SxProps;
	containerSx?: SxProps;
	height?: string | number;
	/** @description filename */
	name?: string;
	data?: TData;
	/** @description displayed in XLSX files */
	date?: [Moment, Moment];
}
