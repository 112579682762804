// modules
import { ColGroupDef } from 'ag-grid-community';
// types
import { AGTableColumn } from 'components/AGTable';
// utils
import { getColumnId } from 'components/AGTable/getColumnId';

export const getDeepHiddenColumns = (columns: AGTableColumn[], next?: string[]) => {
	const result = next || [];
	columns?.forEach((col) => {
		if (col.hiddenDefault) result.push(getColumnId(col));
		getDeepHiddenColumns((col as ColGroupDef).children || [], result);
	});
	return result;
};
