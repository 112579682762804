// modules
import { createBrowserRouter, RouteObject } from 'react-router-dom';
// components
import { AuthBoundary } from 'components/AuthBoundary';
import { ErrorBoundary } from 'components/ErrorBoundary';
import Layout from 'components/Layout';
// constants
import { config } from 'config/config';
// types
import { IPage } from 'types/general';

const [defaultRoutes, protectedRoutes, protectedLayoutRoutes] = config.pages.reduce<Array<IPage[]>>(
	(acc, c: IPage) => {
		if (c.disabled) return acc;
		switch (true) {
			case !c.protected:
				acc[0].push(c);
				break;
			case c.useLayout:
				acc[2].push(c);
				break;
			default:
				acc[1].push(c);
		}
		return acc;
	},
	[[], [], []]
);

/** All routes array */
export const ROUTES = [...defaultRoutes, ...protectedRoutes, ...protectedLayoutRoutes];

const routes: RouteObject[] = [
	...defaultRoutes,
	{
		element: <AuthBoundary />,
		children: [
			{
				element: <Layout />,
				children: protectedLayoutRoutes,
			},
			...protectedRoutes,
		],
		errorElement: <ErrorBoundary />,
	},
];

export const router = createBrowserRouter(routes);
