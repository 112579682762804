// modules
import { decompress as decompressJSON, Compressed } from 'compress-json';
import CryptoJS from 'crypto-js';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
// utils
import { TOKEN } from 'consts';
// types
import { AnyObject } from 'types/general';
import { ILoginData } from 'types/login';
import { IBaaSResponse } from 'service/types';

export interface AuthContextValue extends ILoginData {
	isPretend?: boolean;
	setToken: (value: string, isPretend?: boolean) => void;
	setPublicKey: (value: string) => void;
	setCertificate: (value: string) => void;
	reset: () => void;
}

interface IAuthProviderProps extends PropsWithChildren {
	publicKey: string;
	certificate: string;
	setToken: (value: string) => void;
	setPublicKey: (value: string) => void;
	setCertificate: (value: string) => void;
}

const initContextValue = {
	token: '',
	publicKey: '',
	certificate: '',
	setToken: () => '',
	setPublicKey: () => '',
	setCertificate: () => '',
	reset: () => '',
};

const AuthContext = createContext<AuthContextValue>(initContextValue);

export const decompress = (response: IBaaSResponse) => {
	if (!response.isCompressed) return response;
	const compressed = response.data as unknown as Compressed;
	const decompressed = decompressJSON(compressed);
	return { ...response, data: decompressed };
};

export const encrypt = (data: AnyObject, { publicKey, certificate }: Record<'publicKey' | 'certificate', string>) => {
	const stringified = JSON.stringify(data);
	const encrypted = CryptoJS.MD5(stringified + publicKey);
	return `${encrypted}.${certificate}`;
};

export const AuthProvider = (props: IAuthProviderProps) => {
	const [token, setToken] = useState(() => localStorage.getItem(TOKEN) || '');
	const [publicKey, setPublicKey] = useState(() => props.publicKey);
	const [certificate, setCertificate] = useState(() => props.certificate);
	const [isPretend, setPretend] = useState(false);

	const handleChangePublicKey = useCallback((value: string) => {
		props.setPublicKey(value);
		setPublicKey(value);
	}, []);

	const handleChangeCertificate = useCallback((value: string) => {
		props.setCertificate(value);
		setCertificate(value);
	}, []);

	const handleSetToken = useCallback((value: string, isPretend?: boolean) => {
		if (isPretend) setPretend(true);
		setToken(value);
		props.setToken(value);
	}, []);

	const reset = useCallback(() => {
		setPretend(false);
		handleSetToken('');
		handleChangeCertificate('');
		handleChangePublicKey('');
	}, [handleChangePublicKey, handleChangeCertificate, handleSetToken]);

	useEffect(() => {
		if (isPretend) return;
		const isTokenValid = token && token !== 'null';
		if (isTokenValid) localStorage.setItem(TOKEN, token || '');
		else localStorage.removeItem(TOKEN);
	}, [token, isPretend]);

	const context = useMemo(
		() => ({
			isPretend,
			token,
			publicKey,
			certificate,
			setToken: handleSetToken,
			setPublicKey: handleChangePublicKey,
			setCertificate: handleChangeCertificate,
			reset,
			decompress,
			encrypt,
		}),
		[isPretend, token, publicKey, certificate, handleSetToken, handleChangeCertificate, handleChangePublicKey, reset]
	);

	return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
