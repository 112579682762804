// modules
import { styled } from '@mui/material/styles';

enum LogoVariant {
	primary,
	black,
	white,
}

interface ILogoProps {
	variant?: LogoVariant;
}

const getColor = (variant?: LogoVariant) => {
	switch (variant) {
		case LogoVariant.black:
			return { bg: '#000000', text: '#ffffff' };
		case LogoVariant.white:
			return { bg: '#ffffff', text: '#000000' };
		case LogoVariant.primary:
		default:
			return { bg: '#EC4B4B', text: '#ffffff' };
	}
};

export const Logo = styled((props: ILogoProps) => {
	const { variant, ...other } = props;

	const { bg, text } = getColor(variant);

	return (
		<svg id="logo" version="1.1" width="73px" height="87px" viewBox="0 0 73.0 87.0" xmlns="http://www.w3.org/2000/svg" {...other}>
			<defs>
				<clipPath id="i0">
					<path d="M1440,0 L1440,6095 L0,6095 L0,0 L1440,0 Z"></path>
				</clipPath>
				<clipPath id="i1">
					<path d="M71.9888627,0 L71.9888627,71.9888627 L0,71.9888627 L0,0 L71.9888627,0 Z"></path>
				</clipPath>
				<clipPath id="i2">
					<path d="M2.17183006,0 C3.37106264,0 4.34366013,0.972597488 4.34366013,2.17236711 C4.34366013,3.31945914 3.45379777,4.25919506 2.32690513,4.33874281 L2.17183006,4.34419718 C0.972060438,4.34419718 0,3.37159969 0,2.17236711 C0,0.972597488 0.972060438,0 2.17183006,0 Z"></path>
				</clipPath>
				<clipPath id="i3">
					<path d="M4.42260647,0 L6.64330808,7.24372994 L8.99075348,0 L12.9402189,0 L15.1609205,7.33449139 L17.508903,0 L21.5856493,0 L17.508903,12.7404363 L12.9036995,12.7404363 L10.8473352,5.93332802 L8.6814127,12.7404363 L4.07674629,12.7404363 L0,0 L4.42260647,0 Z"></path>
				</clipPath>
				<clipPath id="i4">
					<path d="M6.13364766,0 C7.31032414,0 8.33608957,0.193875038 9.20933282,0.582162163 C10.0831131,0.970449289 10.7565738,1.5284442 11.2297148,2.25668396 C11.7028558,2.98438666 11.9396948,3.83399971 11.9396948,4.804449 C11.9396948,5.71421164 11.7334677,6.50958264 11.3210133,7.1884138 C10.9080219,7.86831905 10.3135075,8.40859132 9.5369333,8.80869354 L12.2125162,12.7398993 L7.62610952,12.7398993 L5.460187,9.53639625 L4.29532563,9.53639625 L4.29532563,12.7398993 L0,12.7398993 L0,0 L6.13364766,0 Z M5.86082628,3.31198714 L4.29532563,3.31198714 L4.29532563,6.29691085 L5.86082628,6.29691085 C6.44298844,6.29691085 6.88014711,6.16640771 7.1712282,5.90593848 C7.46230928,5.64493219 7.60784982,5.27812707 7.60784982,4.804449 C7.60784982,4.33130798 7.46230928,3.96450285 7.1712282,3.70349657 C6.88014711,3.44249028 6.44298844,3.31198714 5.86082628,3.31198714 Z"></path>
				</clipPath>
				<clipPath id="i5">
					<path d="M4.22228683,0 L4.22228683,4.62238906 L8.40859132,0 L13.0857595,0 L7.98969234,5.6051905 L13.3408582,12.7398993 L8.37207192,12.7398993 L5.18682857,8.51761246 L4.22228683,9.59117534 L4.22228683,12.7398993 L0,12.7398993 L0,0 L4.22228683,0 Z"></path>
				</clipPath>
				<clipPath id="i6">
					<path d="M5.93332802,0 C6.80710832,0 7.66531416,0.0939837441 8.50848261,0.281414182 C9.35165106,0.46991872 10.0949282,0.745425353 10.7383141,1.10954523 L9.4456348,4.22228683 C8.19591953,3.59125312 7.01279846,3.27546774 5.89680863,3.27546774 C4.7926339,3.27546774 4.24054653,3.54238158 4.24054653,4.07674629 C4.24054653,4.33130798 4.40434677,4.52249776 4.73194725,4.64977861 C5.05954773,4.77705945 5.59928295,4.91347014 6.35168995,5.05954773 C7.32267629,5.24160767 8.14167748,5.45052011 8.80923059,5.68735914 C9.47624665,5.92366113 10.0557236,6.309263 10.5471243,6.84309067 C11.038525,7.37691833 11.2839568,8.10515809 11.2839568,9.02673583 C11.2839568,9.82801438 11.05947,10.5530318 10.6110332,11.2017882 C10.1615224,11.8510816 9.49128405,12.3666496 8.59978111,12.7490291 C7.70774111,13.1308717 6.62504838,13.3220615 5.35062881,13.3220615 C4.29532563,13.3220615 3.26687494,13.2039105 2.26581381,12.9670714 C2.14551461,12.9391448 2.03595642,12.9053107 1.92102773,12.8746988 L0.332970979,10.7828892 L1.18258403,8.88119529 C1.78945049,9.23350007 2.46881869,9.51598835 3.2212257,9.72758603 C3.9736327,9.94025782 4.69542785,10.0460567 5.38714821,10.0460567 C5.98166252,10.0460567 6.40646904,9.98859232 6.66156778,9.87366363 C6.91612946,9.75819788 7.0434103,9.58526779 7.0434103,9.35487336 C7.0434103,9.08795952 6.87638776,8.88763989 6.54287974,8.7544515 C6.20883466,8.62072605 5.65996959,8.47518551 4.89574749,8.31729282 C3.91294605,8.11106564 3.09394485,7.889264 2.4387439,7.65296202 C1.78354294,7.41612298 1.21319587,7.02837291 0.727702704,6.48810064 C0.242746585,5.94836542 0,5.21744042 0,4.29478858 C0,3.49404708 0.223949836,2.76580732 0.673460657,2.11060637 C1.12189738,1.45594246 1.79267279,0.94037449 2.68417573,0.563902464 C3.57621572,0.187430438 4.65944551,0 5.93332802,0 Z"></path>
				</clipPath>
				<clipPath id="i7">
					<path d="M6.99400171,0 C8.36509027,0 9.59654584,0.284636482 10.6889055,0.855520596 C11.7807281,1.42533061 12.6362487,2.21747931 13.2549302,3.23035555 C13.8741488,4.24323178 14.1829526,5.38661116 14.1829526,6.66103073 C14.1829526,7.93491325 13.8741488,9.07882968 13.2549302,10.0917059 C12.6362487,11.1051192 11.7807281,11.8967308 10.6889055,12.4670779 C9.59654584,13.0368879 8.36509027,13.3225985 6.99400171,13.3225985 C5.62237609,13.3225985 4.39145757,13.0368879 3.29963499,12.4670779 C2.20727536,11.8967308 1.35175476,11.1051192 0.733073204,10.0917059 C0.390435325,9.53102575 0.15252219,8.92577044 0,8.28506983 L2.12349557,1.64713225 C2.48170789,1.35175476 2.86677272,1.08108158 3.29963499,0.855520596 C4.39145757,0.284636482 5.62237609,0 6.99400171,0 Z M6.99400171,3.47632443 C6.45963699,3.47632443 5.97790317,3.60629052 5.5471891,3.86729681 C5.11647503,4.12830309 4.77383715,4.49833052 4.51873841,4.97737909 C4.26417673,5.4569647 4.13635884,6.01818192 4.13635884,6.66103073 C4.13635884,7.30441659 4.26417673,7.86509675 4.51873841,8.34468237 C4.77383715,8.82426799 5.11647503,9.19375837 5.5471891,9.45476465 C5.97790317,9.71630799 6.45963699,9.84627408 6.99400171,9.84627408 C7.52782937,9.84627408 8.01010024,9.71630799 8.44081432,9.45476465 C8.87152839,9.19375837 9.21416627,8.82426799 9.469265,8.34468237 C9.72436373,7.86509675 9.85164458,7.30441659 9.85164458,6.66103073 C9.85164458,6.01818192 9.72436373,5.4569647 9.469265,4.97737909 C9.21416627,4.49833052 8.87152839,4.12830309 8.44081432,3.86729681 C8.01010024,3.60629052 7.52782937,3.47632443 6.99400171,3.47632443 Z"></path>
				</clipPath>
				<clipPath id="i8">
					<path d="M6.11055451,0 C7.28239754,0 8.30440362,0.193337988 9.17442457,0.580013963 C10.0449826,0.967226989 10.715758,1.5230737 11.1872878,2.24809116 C11.6588177,2.97364566 11.8945826,3.81949936 11.8945826,4.78672635 C11.8945826,5.75395334 11.6588177,6.59980703 11.1872878,7.32536154 C10.715758,8.05037899 10.0449826,8.60622571 9.17442457,8.99343873 C8.30440362,9.38065176 7.28239754,9.57345269 6.11055451,9.57345269 L4.27921413,9.57345269 L4.27921413,12.6921018 L0,12.6921018 L0,0 L6.11055451,0 Z M5.83827018,3.29963499 L4.27921413,3.29963499 L4.27921413,6.2738177 L5.83827018,6.2738177 C6.41828414,6.2738177 6.85329462,6.14385161 7.14383865,5.88391943 C7.43384563,5.62398724 7.57884912,5.25825622 7.57884912,4.78672635 C7.57884912,4.31519648 7.43384563,3.94946545 7.14383865,3.68953327 C6.85329462,3.43013813 6.41828414,3.29963499 5.83827018,3.29963499 Z"></path>
				</clipPath>
				<clipPath id="i9">
					<path d="M9.75497558,0 L15.3032388,12.6915648 L10.8790212,12.6915648 L10.0272599,10.479993 L5.20401417,10.479993 L4.35171587,12.6915648 L0,12.6915648 L5.5482632,0 L9.75497558,0 Z M7.61536852,4.20671238 L6.3823018,7.39786328 L8.84843524,7.39786328 L7.61536852,4.20671238 Z"></path>
				</clipPath>
				<clipPath id="i10">
					<path d="M4.27921413,0 L4.27921413,9.37420716 L10.7857893,9.37420716 L9.34681761,12.6921018 L0,12.6921018 L0,0 L4.27921413,0 Z"></path>
				</clipPath>
				<clipPath id="i11">
					<path d="M4.51444201,0 L7.1078563,4.38823527 L9.71845619,0 L13.8526668,0 L9.06594048,8.08689839 L9.06594048,12.6921018 L4.78672635,12.6921018 L4.78672635,8.03211929 L0,0 L4.51444201,0 Z"></path>
				</clipPath>
			</defs>
			<g transform="translate(-138.0 0.0)">
				<g clipPath="url(#i0)">
					<g transform="translate(137.99999999999966 0.0)">
						<g clipPath="url(#i1)">
							<polygon points="0,0 71.9888627,0 71.9888627,71.9888627 0,71.9888627 0,0" stroke="none" fill={bg}></polygon>
						</g>
						<g transform="translate(0.000161114989964517 82.36450692331482)">
							<g clipPath="url(#i2)">
								<polygon points="0,0 4.34366013,0 4.34366013,4.34419718 0,4.34419718 0,0" stroke="none" fill={bg}></polygon>
							</g>
						</g>
						<g transform="translate(2.5099567211804015 73.96842886958393)">
							<g clipPath="url(#i3)">
								<polygon points="0,0 21.5856493,0 21.5856493,12.7404363 0,12.7404363 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(36.57363973577151 73.96869739456679)">
							<g clipPath="url(#i4)">
								<polygon points="0,0 12.2125162,0 12.2125162,12.7398993 0,12.7398993 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(49.36063831166314 73.9686436895704)">
							<g clipPath="url(#i5)">
								<polygon points="0,0 13.3408582,0 13.3408582,12.7398993 0,12.7398993 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(61.23680014518869 73.67745519799519)">
							<g clipPath="url(#i6)">
								<polygon points="0,0 11.2839568,0 11.2839568,13.3220615 0,13.3220615 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(22.11195825084701 73.6774014929988)">
							<g clipPath="url(#i7)">
								<polygon points="0,0 14.1829526,0 14.1829526,13.3225985 0,13.3225985 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(2.1720985875587075 57.13846444451474)">
							<g clipPath="url(#i8)">
								<polygon points="0,0 11.8945826,0 11.8945826,12.6921018 0,12.6921018 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(25.79198573298982 57.09039847255553)">
							<g clipPath="url(#i9)">
								<polygon points="0,0 15.3032388,0 15.3032388,12.6915648 0,12.6915648 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(15.462527801595341 57.13846444451474)">
							<g clipPath="url(#i10)">
								<polygon points="0,0 10.7857893,0 10.7857893,12.6921018 0,12.6921018 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
						<g transform="translate(38.39837440530982 57.13819591953143)">
							<g clipPath="url(#i11)">
								<polygon points="0,0 13.8526668,0 13.8526668,12.6921018 0,12.6921018 0,0" stroke="none" fill={text}></polygon>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
})``;
