import React, { useEffect } from 'react';

export const useClickOutside = (ref: React.MutableRefObject<unknown>, handler: (event: MouseEvent | TouchEvent) => void, skip?: boolean) => {
	useEffect(() => {
		if (skip) return;
		const listener = (event: MouseEvent | TouchEvent) => {
			const parent = ref.current as Node;
			if (parent?.contains(event.target as Node)) return;
			handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler, skip]);
};
