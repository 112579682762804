// slices
import { auth } from './slices/auth';
import { config } from './slices/config';
import { shareApplications } from './slices/shareApplications';
import { tableDropdownOptions } from './slices/tableDropdownOptions';
import { hosting } from './slices/hosting';
import { appAssets } from './slices/appAssets';
import { assetsConfigure } from './slices/assetsSettings';

export const actions = Object.freeze({
	auth: auth.actions,
	config: config.actions,
	shareApplications: shareApplications.actions,
	tableDropdownOptions: tableDropdownOptions.actions,
	hosting: hosting.actions,
	assets: appAssets.actions,
	assetsConfigure: assetsConfigure.actions,
});
