// modules
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
// utils
import { path } from '../consts';
// types
import { IEntityConfig } from '../types/entity';

export const applicationsConfig: IEntityConfig = {
	key: 'app',
	list: {
		title: 'Applications',
		clickable: true,
		searchable: true,
		checkable: true,
		path: path.applicationSettings,
		columns: [
			{ key: 'name', label: 'Name (English)', copyable: true, hiddenDefault: false },
			{ key: 'type', label: 'Type', copyable: true, hiddenDefault: false },
			{ key: 'uid', label: 'Product Id', copyable: true, hiddenDefault: false },
			{ key: 'platformName', label: 'Platform', copyable: true, hiddenDefault: false },
			{ key: 'deploymentName', label: 'Deployment', copyable: true, hiddenDefault: false },
		],
		creatable: true,
		actions: [
			{
				label: 'Create app',
				path: path.applicationCreate,
				Icon: AddBoxIcon,
			},
		],
		rowActions: [
			{
				label: 'Details',
				path: path.applicationDetails,
				Icon: InfoRoundedIcon,
				separator: true,
			},
			{
				label: 'Edit',
				path: path.applicationSettings,
				Icon: EditIcon,
			},
			{
				label: 'Share',
				path: path.applicationShare,
				Icon: ShareRoundedIcon,
			},
			{
				label: 'Delete',
				path: path.applicationsMultiDelete,
				Icon: DeleteIcon,
				variant: 'error',
			},
		],
		multiRowActions: [{ label: 'Delete Applications', variant: 'error', path: path.applicationsMultiDelete }],
		fetchOptions: {
			endpoint: '/apps/get/all',
		},
		deleteOptions: {
			endpoint: '/app/delete',
			params: {
				id: {
					type: 'multiple',
					routeVariable: 'appIds',
				},
			},
		},
	},
	form: {
		submitOptions: {
			endpoint: {
				create: '/app/create',
				edit: '/app/update',
			},
		},
		fields: [],
	},
};
