// modules
import { useQuery as useTanstackQuery, useMutation as useTanstackMutation, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
// types
import { AnyObject } from 'types/general';
import { IBaaSError, IBaaSResponse, IMutationOptions, IQueryOptions } from './types';
// utils
import { decompress, encrypt } from 'utils/security';
import { inMemory } from 'index';

/** general axios instance */
export const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
	transformResponse: (data) => {
		if (!data) return data;
		try {
			const objectData: IBaaSResponse = JSON.parse(data);
			const decompressed = decompress(objectData);
			if (!decompressed.data && decompressed.status?.id === 0) return true;
			if (decompressed.status?.id !== 0) {
				throw decompressed;
			}
			return decompressed?.data || {};
		} catch (e) {
			if (Object.hasOwnProperty.call((e as { status: string }).status || {}, 'id')) {
				throw e;
			}
		}
	},
});

instance.interceptors.request.use(
	function (config) {
		const unprotectedRoutes = ['/cms/authorize', '/cms/logout'];
		const isPathNotEncrypted = unprotectedRoutes.includes(config.url || '');
		const { publicKey, certificate } = inMemory;

		if (isPathNotEncrypted) return config;
		config.headers['Authorization'] = `Basic ${encrypt(config.data, {
			publicKey,
			certificate,
		})}`;
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

/** useQuery wrapper */
export const useCustomQuery = <TOptions = AnyObject, TData = AnyObject>(
	options: IQueryOptions<TOptions>
): [(data?: TOptions, options?: Omit<IQueryOptions<TOptions>, 'url'>) => void, UseQueryResult<{ data: TData }, IBaaSError>] => {
	const { url, key, retry } = options;
	const [enabled, setEnabled] = useState(false);
	const [body, setBody] = useState<TOptions | undefined>(undefined);
	const [opts, setOpts] = useState<Omit<IQueryOptions<TOptions>, 'url'>>(() => options);
	const { instant, cache, config } = opts;

	const trigger = useCallback(
		(data?: TOptions, options?: Omit<IQueryOptions<TOptions>, 'url'>) => {
			if (options) setOpts(options);
			setEnabled(true);
			setBody(data || options?.data || opts.data);
		},
		[opts.data, options?.data]
	);

	useEffect(() => {
		if (!instant) return;
		trigger();
	}, [instant, trigger]);

	const response = useTanstackQuery<{ data: TData }, IBaaSError>([key], {
		queryFn: ({ signal }) => instance({ url, method: 'post', signal, data: body, ...config }),
		enabled,
		retry: retry,
		refetchOnWindowFocus: false,
		cacheTime: cache ? Infinity : 0,
		staleTime: cache ? Infinity : 0,
	});

	useEffect(() => {
		if (!enabled) return;
		response.refetch().then(() => setEnabled(false));
	}, [body, enabled]);

	return [trigger, response];
};

/** [start] Hooks for Entity pages */
export const useQueryAll = <TOptions = AnyObject, TData = AnyObject>(options: IQueryOptions<TOptions>) => {
	return useCustomQuery<TOptions, TData[]>({ key: options.url, ...options });
};

export const useQueryOne = <TOptions = AnyObject, TData = AnyObject>(options: IQueryOptions<TOptions>) => {
	return useCustomQuery<TOptions, TData>({ ...options, cache: false });
};

export function useSaveMutation<TData = AnyObject, TResponse = AnyObject>(options: IMutationOptions) {
	const { url } = options;
	return useTanstackMutation({
		mutationFn: (data: TData) => instance<TResponse>({ url, method: 'post', data }),
	});
}

export const useDeleteMutation = (options: IMutationOptions) => {
	const { url } = options;
	return useTanstackMutation({
		mutationFn: (data: AnyObject) =>
			instance({
				url,
				method: 'post',
				data,
			}),
	});
};

/** [end] Hooks for Entity pages */
