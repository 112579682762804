// modules
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import { startCase } from 'lodash';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// components
import { Breadcrumb } from './Breadcrumb';
// types
import { IBreadcrumb, IBreadcrumbProps } from './types';
import { pathVars, SET_BREADCRUMBS } from 'consts';

type PageParamsRulesType = {
	[key: string]: string[];
};

const pageParamsRules = {
	application: ['platformName', 'deploymentName', 'applicationName'],
	editApplication: ['platformName', 'deploymentName', 'applicationName'],
	shop: ['shopName'],
	mode: ['mode'],
	editMode: ['mode'],
	event: ['event'],
	editEvent: ['event'],
};

const separator = <ArrowForwardIosIcon sx={{ height: 60, width: 20, color: 'neutral.400' }} />;

const getSearchParamsForPath = (path: string, fullSearchParams: URLSearchParams) => {
	const split = path.split('/').filter(Boolean);
	const resultParams = new URLSearchParams();
	split.forEach((segment) => {
		const allowedParams = (pageParamsRules as PageParamsRulesType)[segment] || [];
		allowedParams.forEach((param: string) => {
			const value = fullSearchParams.get(param);
			if (value) {
				resultParams.set(param, value);
			}
		});
	});

	return resultParams;
};

const generateItems = (path: string, search: string) => {
	const split = path.split('/').filter(Boolean);
	const fullSearchParams = new URLSearchParams(search);

	return split.reduce((acc: IBreadcrumb[], curr: string, index: number) => {
		const isPage = (Object.values(pathVars) as string[]).includes(curr);
		if (!isPage) return acc;

		const pathBase = `/${split.slice(0, index + 1).join('/')}`;
		const pathSearchParams = getSearchParamsForPath(pathBase, fullSearchParams);
		const combinedPath = `${pathBase}${search ? `?${pathSearchParams.toString()}` : ''}`;
		acc.push({ label: startCase(curr), path: combinedPath });

		return acc;
	}, []);
};

export const Breadcrumbs: FC<IBreadcrumbProps> = (props) => {
	const { items } = props;
	const { pathname, search } = useLocation();
	const [eventBreadcrumbs, setEventBreadcrumbs] = useState<IBreadcrumb[] | null>(null);

	const watchBreadcrumbsEvent = useCallback((event: Event) => {
		const breadcrumbs: IBreadcrumb[] | null = (event as CustomEvent).detail;
		setEventBreadcrumbs(breadcrumbs || null);
	}, []);

	useEffect(() => {
		document.addEventListener(SET_BREADCRUMBS, watchBreadcrumbsEvent);
		return () => document.removeEventListener(SET_BREADCRUMBS, watchBreadcrumbsEvent);
	}, [watchBreadcrumbsEvent]);

	const computedItems = useMemo(() => eventBreadcrumbs || items || generateItems(pathname, search), [items, pathname, search, eventBreadcrumbs]);

	const renderItem = (item: IBreadcrumb) => <Breadcrumb key={item.path || item.label} item={item} />;

	return (
		<MUIBreadcrumbs
			separator={separator}
			sx={{
				height: '100%',
				backgroundColor: 'transparent',
				display: 'flex',
				alignItems: 'center',
				px: 3,
			}}
		>
			{computedItems.map(renderItem)}
		</MUIBreadcrumbs>
	);
};
