// utils
import { instance } from './general';
// types
import { Endpoints } from 'consts/endpoints';
import { ICertificate, ISecurityGroup } from 'types/security';

export const getUserSecurityGroupIds = async (userCertificate: string | undefined) => {
	if (!userCertificate) return [];
	try {
		const certificates = await instance({ method: 'POST', url: Endpoints.security_certificates_get, data: {} }).then(
			(res) => res.data as ICertificate[]
		);
		return certificates?.find(({ certificate }) => certificate === userCertificate)?.accessGroupsIds || [];
	} catch {
		return [];
	}
};

export const getUserSecurityGroupsData = async (userCertificate: string | undefined) => {
	if (!userCertificate) return [];
	try {
		const securityGroupIds = await getUserSecurityGroupIds(userCertificate);
		if (!securityGroupIds.length) return [];

		const securityGroups = await instance({
			method: 'POST',
			url: Endpoints.security_accessGroup_get,
			data: { ids: securityGroupIds },
		}).then((res) => res.data as ISecurityGroup[]);
		return securityGroups || [];
	} catch {
		return [];
	}
};
