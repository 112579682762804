// modules
import round from 'lodash/round';

export const numberWithCommas = (value?: string | number, decimals = 2): string => {
	if (!value) return '0';
	const formattedNumberValue = typeof value === 'string' || !decimals ? value : round(value, 2);
	const string = typeof value === 'string' ? value : formattedNumberValue.toString();
	const [integer, decimal] = string.split('.');

	return integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (decimal ? `.${decimal}` : '');
};
