// modules
// types
import { path } from 'consts';
import { IEntityConfig } from 'types/entity';
// constants

export const appModeConfig: IEntityConfig = {
	key: 'app-mode',
	list: {
		title: 'App Modes',
		searchable: true,
		clickable: true,
		path: path.modeSettings,
		columns: [
			{ key: 'name.en', label: 'Name (EN)', copyable: true },
			{ key: 'uid', label: 'Mode UID', copyable: true },
			{ key: 'id', label: 'Mode ID', copyable: true },
		],
		fetchOptions: {
			endpoint: '/appmodes/get',
			params: {
				appId: {
					type: 'single',
					routeVariable: 'appId',
				},
			},
		},
		deleteOptions: {
			endpoint: '/appmode/delete',
			params: {
				id: {
					type: 'multiple',
					routeVariable: 'appModeIds',
				},
			},
		},
	},
	form: {
		submitOptions: {
			endpoint: {
				create: '/appmode/create',
				edit: '/appmode/update',
			},
		},
		fetchOptions: {
			endpoint: '/appmodes/get',
			params: {
				ids: {
					type: 'multiple',
					routeVariable: 'appModeId',
				},
			},
		},
		fields: [],
	},
};
