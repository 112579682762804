// modules
import { Box } from '@mui/material';

export const Overlay = () => {
	return (
		<Box
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.700' : 'neutral.900'),
				opacity: 0.7,
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
			}}
		/>
	);
};
