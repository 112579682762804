// modules
import { createListenerMiddleware } from '@reduxjs/toolkit';
// utils
import { instance } from 'service';
import { actions } from 'store/actions';
// types
import { Config, RootState } from 'store/types';
import { Endpoints } from 'consts/endpoints';
import { inMemory } from 'index';

const save = (data: { token: string; data: { config: Partial<Config> } }) => instance({ url: Endpoints.cms_user_update, method: 'POST', data });

export const userConfigListener = createListenerMiddleware();

const userConfigActions = [
	actions.auth.changeAnalyticsCustomTab,
	actions.auth.changeChartConfig,
	actions.auth.changeCustomVariable,
	actions.auth.changeTableConfig,
	actions.auth.changeTheme,
	actions.auth.changeUserConfig,
	actions.auth.changeUserData,
	actions.auth.deleteAnalyticsCustomTab,
	actions.auth.deleteAnalyticsItemById,
	actions.auth.deleteCustomVariables,
	actions.auth.setAnalyticsCustomTabFilters,
	actions.auth.setAnalyticsCustomTabsOrder,
	actions.auth.setAnalyticsCustomVariable,
	actions.auth.setAnalyticsTabConfig,
	actions.auth.setAnalyticsTabFilters,
	actions.auth.setAnalyticsTabsOrder,
	actions.auth.setUserConfig,
	actions.auth.setUserProxyRules,
];

userConfigListener.startListening({
	predicate: (action) => {
		const isConfigAction = Boolean(userConfigActions.find((i) => i.type === action.type));
		return isConfigAction;
	},
	effect: async (_action, listenerApi) => {
		listenerApi.cancelActiveListeners();
		await listenerApi.delay(2000);
		const token = inMemory.token;

		if (token) {
			const state = listenerApi.getState() as RootState;
			const userConfig = state.auth.user?.data?.config || {};
			const isConfigShared = Boolean(state.auth.user?.data?.isConfigShared);
			if (!isConfigShared) save({ token, data: { config: userConfig } });
		}
	},
});
