import { defaultCrossPromotionV2Preset } from './../components/Analytics/sections/CrossPromotionV2/crossPromotionV2Presets';
// utils
import { defaultAdvertisingPreset } from 'components/Analytics/sections/Advertising/advertisingPresets';
import { defaultAvodsPreset } from 'components/Analytics/sections/Avods/avodsPresets';
import { defaultComparisonPreset } from 'components/Analytics/sections/Comparison/comparisonPresets';
import { defaultEventPreset } from 'components/Analytics/sections/Events/eventsPresets';
import { defaultRevenuePresets } from 'components/Analytics/sections/Revenue/revenuePresets';
import { defaultUsageBehaviorPreset } from 'components/Analytics/sections/UsageBehavior/usageBehaviorPresets';
import { defaultGeneralPreset } from 'components/Analytics/sections/general/generalPresets';
import { ANALYTICS_SECTIONS, COHORT_SIZE, DEFAULT_COMPARISON_LIMIT, MAX_TABLE_LIMIT } from 'consts';
import { defaultCrossPromotionPreset } from 'components/Analytics/sections/CrossPromotion/crossPromotionPresets';
// types
import { AnalyticsMetrics, AnalyticsSyntheticMetrics, IAnalyticsRequestParams } from 'types/analytics';
import { OrderType, ValueOf } from 'types/general';
import { AnalyticsTabConfig, ChartConfig, ChartSize, TableConfig } from './types';

export const tables: Readonly<Record<string, TableConfig>> = Object.freeze({
	[`${ANALYTICS_SECTIONS.avods}:applications-usage`]: {
		order: OrderType.desc,
		orderBy: AnalyticsMetrics.users,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'country',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'users',
				type: 'numericColumn',
				headerName: 'Active Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'newusers',
				type: 'numericColumn',
				headerName: 'New Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'returnedUsers',
				type: 'numericColumn',
				headerName: 'Returned Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'sessions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'sessionPerUser',
				headerName: 'Sessions/User',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'totalTime',
				headerName: 'Total Time',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'totalTimePerUser',
				headerName: 'Total Time/User',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'avgSessionTime',
				type: 'numericColumn',
				headerName: 'Avg. Session Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'activetime',
				type: 'numericColumn',
				headerName: 'Avg. Active Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'inactivetime',
				type: 'numericColumn',
				headerName: 'Avg. Inactive Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'viewed-time',
				headerName: 'Total Watch Time',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
		],
		hiddenColumns: [],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.avods}:videos-usage`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.videoViews,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'videoTitle',
				headerName: 'Video Title',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'creator',
				headerName: 'Creator',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'country',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'videoViews',
				headerName: 'Views',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'viewed-time',
				headerName: 'Total Watch Time',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'avgViewedTime',
				headerName: 'Avg. Watch Time',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.overview}:applications-usage`]: {
		order: OrderType.desc,
		orderBy: AnalyticsMetrics.users,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'country',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'users',
				type: 'numericColumn',
				headerName: 'Active Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'newusers',
				type: 'numericColumn',
				headerName: 'New Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'returnedUsers',
				type: 'numericColumn',
				headerName: 'Returned Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'sessions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'sessionPerUser',
				headerName: 'Sessions/User',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'totalTime',
				headerName: 'Total Time',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'totalTimePerUser',
				headerName: 'Total Time/User',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'avgSessionTime',
				type: 'numericColumn',
				headerName: 'Avg. Session Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'activetime',
				type: 'numericColumn',
				headerName: 'Avg. Active Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'inactivetime',
				type: 'numericColumn',
				headerName: 'Avg. Inactive Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
		],
		hiddenColumns: [],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.advertising}:advertising-data`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.impression,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'country',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'users',
				type: 'numericColumn',
				headerName: 'Active Users',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'sessions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'sessionPerUser',
				headerName: 'Sessions/User',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'request-to-play',
				headerName: 'Opportunity',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'impression',
				headerName: 'Total Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'rewarded',
				headerName: 'Rewarded Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'rewardedPerImpressions',
				headerName: 'Rewarded/Total Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'fillRate',
				headerName: 'Fill Rate',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'opportunityPerUser',
				headerName: 'Opportunity/User',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'opportunityPerSession',
				headerName: 'Opportunity/Session',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				type: 'numericColumn',
				colId: 'request',
				field: 'request',
				filter: 'agNumberColumnFilter',
				headerName: 'Requests',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'response',
				field: 'response',
				filter: 'agNumberColumnFilter',
				headerName: 'Responses',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'responsesPerRequest',
				field: 'responsesPerRequest',
				filter: 'agNumberColumnFilter',
				headerName: 'Request/Response',
				hiddenDefault: false,
			},
			{
				colId: 'avgSessionTime',
				field: 'avgSessionTime',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				headerName: 'Avg. Session Time',
				hiddenDefault: false,
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.advertising}:creators-data`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.impression,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'country',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'creator',
				headerName: 'Creator',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'request-to-play',
				headerName: 'Opportunity',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'impression',
				headerName: 'Total Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'rewarded',
				headerName: 'Rewarded Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'rewardedPerImpressions',
				headerName: 'Rewarded/Total Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				field: 'fillRate',
				headerName: 'Fill Rate',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.events}:events-data`]: {
		order: OrderType.desc,
		orderBy: AnalyticsMetrics.eventscount,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'eventcategory',
				headerName: 'Category',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'eventaction',
				headerName: 'Action',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'eventlabel',
				headerName: 'Label',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'country',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'd1',
				headerName: 'Dimension 1',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'd2',
				headerName: 'Dimension 2',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'd3',
				headerName: 'Dimension 3',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'd4',
				headerName: 'Dimension 4',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'd5',
				headerName: 'Dimension 5',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'd6',
				headerName: 'Dimension 6',
				hiddenDefault: false,
				filter: true,
			},
			{ colId: 'mode', field: 'mode', headerName: 'Mode', filter: true, hiddenDefault: false },
			{ colId: 'source', field: 'source', headerName: 'Source', filter: true, hiddenDefault: false },
			{ colId: 'genre', field: 'genre', headerName: 'Genre', filter: true, hiddenDefault: false },
			{ colId: 'devicemodel', field: 'devicemodel', headerName: 'Device Model', filter: true, hiddenDefault: false },
			{ colId: 'productid', field: 'productid', headerName: 'Product ID', filter: true, hiddenDefault: false },
			{
				field: 'avgeventvalue',
				headerName: 'Avg. Event Value',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				hiddenDefault: false,
			},
			{
				field: 'eventvalue',
				headerName: 'Event Value',
				type: 'numericColumn',
				filter: 'agNumberColumnFilter',
				hiddenDefault: false,
			},
			{
				field: 'eventscount',
				headerName: 'Total Events',
				filter: 'agNumberColumnFilter',
				type: 'numericColumn',
				hiddenDefault: false,
			},
		],
		limit: Math.min(MAX_TABLE_LIMIT, 50000),
	},
	[`${ANALYTICS_SECTIONS.crossPromotion}:cross-promotion`]: {
		order: OrderType.desc,
		orderBy: AnalyticsMetrics.sessions,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				field: 'platform',
				colId: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				headerName: 'Application',
				field: 'eventlabel',
				colId: 'eventlabel',
				hiddenDefault: false,
				filter: true,
			},
			{
				headerName: 'Launched from',
				marryChildren: true,
				groupId: 'launchedFrom',
				hiddenDefault: false,
				children: [
					{
						headerName: 'Direct',
						groupId: 'direct',
						marryChildren: true,
						columnGroupShow: 'open',
						hiddenDefault: false,
						children: [
							{
								columnGroupShow: 'open',
								headerName: 'Count',
								field: 'directCount',
								colId: 'directCount',
								type: 'numericColumn',
								hiddenDefault: false,
							},
							{
								columnGroupShow: 'open',
								headerName: '%',
								field: 'directPercent',
								colId: 'directPercent',
								type: 'numericColumn',
								hiddenDefault: false,
							},
						],
					},
					{
						headerName: 'Game Portals',
						groupId: 'gamePortals',
						marryChildren: true,
						columnGroupShow: 'open',
						hiddenDefault: false,
						children: [
							{
								columnGroupShow: 'open',
								headerName: 'Count',
								field: 'games-portal-count',
								colId: 'games-portal-count',
								type: 'numericColumn',
								hiddenDefault: false,
							},
							{
								columnGroupShow: 'open',
								headerName: '%',
								field: 'games-portal-percent',
								colId: 'games-portal-percent',
								type: 'numericColumn',
								hiddenDefault: false,
							},
						],
					},
					{
						headerName: 'Banners',
						groupId: 'banners',
						marryChildren: true,
						columnGroupShow: 'open',
						hiddenDefault: false,
						children: [
							{
								columnGroupShow: 'open',
								headerName: 'Count',
								field: 'game-count',
								colId: 'game-count',
								type: 'numericColumn',
								hiddenDefault: false,
							},
							{
								columnGroupShow: 'open',
								headerName: '%',
								field: 'game-percent',
								colId: 'game-percent',
								type: 'numericColumn',
								hiddenDefault: false,
							},
						],
					},
					{
						headerName: 'Video Channels',
						marryChildren: true,
						columnGroupShow: 'open',
						hiddenDefault: false,
						children: [
							{
								columnGroupShow: 'open',
								headerName: 'Count',
								field: 'video-channel-count',
								colId: 'video-channel-count',
								type: 'numericColumn',
								hiddenDefault: false,
							},
							{
								columnGroupShow: 'open',
								headerName: '%',
								field: 'video-channel-percent',
								colId: 'video-channel-percent',
								type: 'numericColumn',
								hiddenDefault: false,
							},
						],
					},
				],
			},
			{
				headerName: 'Total',
				field: 'sessions',
				colId: 'sessions',
				type: 'numericColumn',
				hiddenDefault: false,
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.crossPromotion}:banners`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.view,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				colId: 'platform',
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'appname',
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'apptype',
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'eventlabel',
				field: 'eventlabel',
				headerName: 'Label',
				hiddenDefault: false,
				filter: true,
			},
			{
				type: 'numericColumn',
				colId: 'view',
				field: 'view',
				filter: 'agNumberColumnFilter',
				headerName: 'Views',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'launch',
				field: 'launch',
				filter: 'agNumberColumnFilter',
				headerName: 'Launch',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'ctr',
				field: 'ctr',
				filter: 'agNumberColumnFilter',
				headerName: 'CTR',
				hiddenDefault: false,
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.revenue}:total-revenue-data`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.impression,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				colId: 'platform',
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'appname',
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'apptype',
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'country',
				field: 'country',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'users',
				field: 'users',
				filter: 'agNumberColumnFilter',
				headerName: 'Active Users',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				field: 'avgSessionTime',
				type: 'numericColumn',
				headerName: 'Avg. Session Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				colId: 'adsRevenue',
				field: 'adsRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Ads Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'ads-arpu',
				field: 'ads-arpu',
				filter: 'agNumberColumnFilter',
				headerName: 'Ads ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'brightDataRevenue',
				field: 'brightDataRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'BD Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'brightDataARPU',
				field: 'brightDataARPU',
				filter: 'agNumberColumnFilter',
				headerName: 'BD ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'refund',
				field: 'refund',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Refunds',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iapRevenue',
				field: 'iapRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iap-arpu',
				field: 'iap-arpu',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iap-arppu',
				field: 'iap-arppu',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP ARPPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'totalRevenue',
				field: 'totalRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'totalRevenuePerUser',
				field: 'totalRevenuePerUser',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Revenue/User',
				orderBy: 'impression',
				hiddenDefault: false,
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.revenue}:ads-revenue-data`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.impression,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				colId: 'platform',
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'appname',
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'apptype',
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'country',
				field: 'country',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'users',
				field: 'users',
				filter: 'agNumberColumnFilter',
				headerName: 'Active Users',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				field: 'avgSessionTime',
				type: 'numericColumn',
				headerName: 'Avg. Session Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				colId: 'impression',
				field: 'impression',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'videoImpression',
				field: 'videoImpression',
				filter: 'agNumberColumnFilter',
				headerName: 'Video Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'overlayImpression',
				field: 'overlayImpression',
				filter: 'agNumberColumnFilter',
				headerName: 'Overlay Impressions',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'adsCpm',
				field: 'adsCpm',
				filter: 'agNumberColumnFilter',
				headerName: 'Total CPM',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'videoAdsCpm',
				field: 'videoAdsCpm',
				filter: 'agNumberColumnFilter',
				headerName: 'Video CPM',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'overlayAdsCpm',
				field: 'overlayAdsCpm',
				filter: 'agNumberColumnFilter',
				headerName: 'Overlay CPM',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'adsRevenue',
				field: 'adsRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Ads Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'videoAdsRevenue',
				field: 'videoAdsRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Video Ads Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'overlayAdsRevenue',
				field: 'overlayAdsRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Overlay Ads Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'brightDataRevenue',
				field: 'brightDataRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'BD Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'ads-arpu',
				field: 'ads-arpu',
				filter: 'agNumberColumnFilter',
				headerName: 'Ads ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'videoAdsARPU',
				field: 'videoAdsARPU',
				filter: 'agNumberColumnFilter',
				headerName: 'Video Ads ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'overlayAdsARPU',
				field: 'overlayAdsARPU',
				filter: 'agNumberColumnFilter',
				headerName: 'Overlay Ads ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'brightDataARPU',
				field: 'brightDataARPU',
				filter: 'agNumberColumnFilter',
				headerName: 'BD ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'totalRevenue',
				field: 'totalRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'totalRevenuePerUser',
				field: 'totalRevenuePerUser',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Revenue/User',
				orderBy: 'impression',
				hiddenDefault: false,
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.revenue}:iap-revenue-data`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.impression,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				colId: 'platform',
				field: 'platform',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'appname',
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'apptype',
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'country',
				field: 'country',
				type: 'numericColumn',
				hiddenDefault: false,
				filter: true,
			},
			{
				field: 'genre',
				hiddenDefault: false,
				filter: true,
			},
			{
				colId: 'users',
				field: 'users',
				filter: 'agNumberColumnFilter',
				headerName: 'Active Users',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'payingUsers',
				field: 'payingUsers',
				filter: 'agNumberColumnFilter',
				headerName: 'Paying Users',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				field: 'avgSessionTime',
				type: 'numericColumn',
				headerName: 'Avg. Session Time',
				hiddenDefault: false,
				filter: 'agNumberColumnFilter',
			},
			{
				colId: 'iapSales',
				field: 'iapSales',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Sales',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'refund',
				field: 'refund',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Refunds',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iapRevenue',
				field: 'iapRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iap-arpu',
				field: 'iap-arpu',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP ARPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iap-arppu',
				field: 'iap-arppu',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP ARPPU',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iapTransactionsCount',
				field: 'iapTransactionsCount',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Transactions Count',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'avgIapCost',
				field: 'avgIapCost',
				filter: 'agNumberColumnFilter',
				headerName: 'Avg IAP Cost',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'iapConversionRate',
				field: 'iapConversionRate',
				filter: 'agNumberColumnFilter',
				headerName: 'IAP Conversion Rate',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'totalRevenue',
				field: 'totalRevenue',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Revenue',
				type: 'numericColumn',
				hiddenDefault: false,
			},
			{
				colId: 'totalRevenuePerUser',
				field: 'totalRevenuePerUser',
				filter: 'agNumberColumnFilter',
				headerName: 'Total Revenue/User',
				orderBy: 'impression',
				hiddenDefault: false,
			},
		],
		limit: MAX_TABLE_LIMIT,
	},
	[`${ANALYTICS_SECTIONS.crossPromotionV2}:cross-promotion`]: {
		order: OrderType.asc,
		orderBy: AnalyticsMetrics.appname,
		limit: MAX_TABLE_LIMIT,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				colId: 'platform',
				field: 'platform',
				filter: true,
				hiddenDefault: false,
			},
			{
				colId: 'appname',
				field: 'appname',
				filter: true,
				headerName: 'Application',
				hiddenDefault: false,
			},
			{
				colId: 'apptype',
				field: 'apptype',
				filter: true,
				headerName: 'Content Type',
				hiddenDefault: false,
			},
			{
				colId: 'genre',
				field: 'genre',
				filter: true,
				headerName: 'Genre',
				hiddenDefault: false,
			},
			{
				headerName: 'Launched from',
				type: 'aligned',
				groupId: 'launchedFrom',
				marryChildren: true,
				hiddenDefault: false,
				children: [
					{
						headerName: 'Direct',
						marryChildren: true,
						columnGroupShow: 'open',
						groupId: 'direct',
						hiddenDefault: false,
						children: [
							{
								type: 'numericColumn',
								colId: 'direct-count',
								field: 'direct-count',
								headerName: 'Count',
								columnGroupShow: 'open',
								hiddenDefault: false,
							},
							{
								type: 'numericColumn',
								colId: 'direct-percent',
								field: 'direct-percent',
								headerName: '%',
								columnGroupShow: 'open',
								hiddenDefault: false,
							},
						],
					},
					{
						headerName: 'Game Portals',
						marryChildren: true,
						columnGroupShow: 'open',
						groupId: 'gamePortals',
						hiddenDefault: false,
						children: [
							{
								type: 'numericColumn',
								colId: 'games-portal-count',
								field: 'games-portal-count',
								headerName: 'Count',
								columnGroupShow: 'open',
								hiddenDefault: false,
							},
							{
								type: 'numericColumn',
								colId: 'games-portal-percent',
								field: 'games-portal-percent',
								headerName: '%',
								columnGroupShow: 'open',
								hiddenDefault: false,
							},
						],
					},
					{
						headerName: 'Banners',
						marryChildren: true,
						columnGroupShow: 'open',
						groupId: 'banners',
						hiddenDefault: false,
						children: [
							{
								headerName: 'Main Menu',
								marryChildren: true,
								columnGroupShow: 'open',
								groupId: 'main-menu',
								hiddenDefault: false,
								children: [
									{
										type: 'numericColumn',
										colId: 'main-menu-count',
										field: 'main-menu-count',
										headerName: 'Count',
										columnGroupShow: 'open',
										hiddenDefault: false,
									},
									{
										type: 'numericColumn',
										colId: 'main-menu-percent',
										field: 'main-menu-percent',
										headerName: '%',
										columnGroupShow: 'open',
										hiddenDefault: false,
									},
								],
							},
							{
								headerName: 'More Apps',
								marryChildren: true,
								columnGroupShow: 'open',
								groupId: 'more-apps',
								hiddenDefault: false,
								children: [
									{
										type: 'numericColumn',
										colId: 'more-apps-count',
										field: 'more-apps-count',
										headerName: 'Count',
										columnGroupShow: 'open',
										hiddenDefault: false,
									},
									{
										type: 'numericColumn',
										colId: 'more-apps-percent',
										field: 'more-apps-percent',
										headerName: '%',
										columnGroupShow: 'open',
										hiddenDefault: false,
									},
								],
							},
							{
								headerName: 'Exit Screen',
								marryChildren: true,
								columnGroupShow: 'open',
								groupId: 'exit-screen',
								hiddenDefault: false,
								children: [
									{
										type: 'numericColumn',
										colId: 'exit-screen-count',
										field: 'exit-screen-count',
										headerName: 'Count',
										columnGroupShow: 'open',
										hiddenDefault: false,
									},
									{
										type: 'numericColumn',
										colId: 'exit-screen-percent',
										field: 'exit-screen-percent',
										headerName: '%',
										columnGroupShow: 'open',
										hiddenDefault: false,
									},
								],
							},
						],
					},
					{
						headerName: 'Video Channels',
						marryChildren: true,
						columnGroupShow: 'open',
						hiddenDefault: false,
						children: [
							{
								type: 'numericColumn',
								colId: 'video-channel-count',
								field: 'video-channel-count',
								headerName: 'Count',
								columnGroupShow: 'open',
								hiddenDefault: false,
							},
							{
								type: 'numericColumn',
								colId: 'video-channel-percent',
								field: 'video-channel-percent',
								headerName: '%',
								columnGroupShow: 'open',
								hiddenDefault: false,
							},
						],
					},
				],
			},
			{
				type: 'numericColumn',
				colId: 'total',
				field: 'total',
				headerName: 'Total',
				hiddenDefault: false,
			},
		],
	},
	[`${ANALYTICS_SECTIONS.crossPromotionV2}:banners`]: {
		order: OrderType.desc,
		orderBy: AnalyticsSyntheticMetrics.view,
		limit: MAX_TABLE_LIMIT,
		filterModel: {},
		sortingModel: {},
		columns: [
			{
				colId: 'platform',
				field: 'platform',
				hiddenDefault: false,
			},
			{
				colId: 'appname',
				field: 'appname',
				headerName: 'Application',
				hiddenDefault: false,
			},
			{
				colId: 'bannerName',
				field: 'bannerName',
				headerName: 'Banner/Application Name',
				hiddenDefault: false,
			},
			{
				colId: 'apptype',
				field: 'apptype',
				headerName: 'Content Type',
				hiddenDefault: false,
			},
			{
				colId: 'placement',
				field: 'd1',
				headerName: 'Placement',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'view',
				field: 'view',
				filter: 'agNumberColumnFilter',
				headerName: 'View',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'launch',
				field: 'launch',
				filter: 'agNumberColumnFilter',
				headerName: 'Launch',
				hiddenDefault: false,
			},
			{
				type: 'numericColumn',
				colId: 'ctr',
				field: 'ctr',
				filter: 'agNumberColumnFilter',
				headerName: 'Ad CTR',
				hiddenDefault: false,
			},
		],
	},
});

export const charts: Readonly<Record<string, ChartConfig>> = Object.freeze({
	[`${ANALYTICS_SECTIONS.overview}:users-pie`]: {
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.overview}:users-by-country`]: {} as ChartConfig,
	[`${ANALYTICS_SECTIONS.overview}:users-2`]: {
		defaultSize: ChartSize.day,
		datasets: {
			'Active Users': {
				visible: true,
			},
			'New Users': {
				visible: true,
			},
		},
	},
	[`${ANALYTICS_SECTIONS.overview}:active-users`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.overview}:new-users`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.overview}:returned-users`]: {
		defaultSize: ChartSize.day,
		limit: 10,
		showAverageAll: true,
		showLimitedAverage: true,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.overview}:sessions-count`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.overview}:sessions-length`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.overview}:total-user-time`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.avods}:users-pie`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.avods}:users-by-country`]: {} as ChartConfig,
	[`${ANALYTICS_SECTIONS.advertising}:opportunities/responses/impressions`]: {
		defaultSize: ChartSize.day,
		datasets: {
			Opportunities: {
				visible: true,
			},
			Responses: {
				visible: true,
			},
			Impressions: {
				visible: true,
			},
		},
	},
	[`${ANALYTICS_SECTIONS.advertising}:fill-rate`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.advertising}:impressions`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.advertising}:impressions-per-user`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.advertising}:impressions-per-session`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.advertising}:requests`]: {
		defaultSize: ChartSize.day,
		datasets: {
			Requests: {
				visible: true,
			},
			Responses: {
				visible: true,
			},
		},
	},
	[`${ANALYTICS_SECTIONS.advertising}:ad-server-fill-rate`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.avods}:users-2`]: {
		defaultSize: ChartSize.day,
		datasets: {
			'Active Users': {
				visible: true,
			},
			'New Users': {
				visible: true,
			},
		},
	},
	[`${ANALYTICS_SECTIONS.avods}:active-users`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.avods}:new-users`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.avods}:returned-users`]: {
		defaultSize: ChartSize.day,
		limit: 10,
		showAverageAll: true,
		showLimitedAverage: true,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.avods}:sessions-count`]: {
		defaultSize: ChartSize.day,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.avods}:sessions-length`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.avods}:total-user-time`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.comparison}:users`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:new-users`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:returned-users`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:sessions-count`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:sessions-length`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:sessions-per-user`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:total-user-time`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:impressions`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:impressions-per-user`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:ads-revenue`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:iap-revenue`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:total-revenue`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:ads-arpu`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:iap-arpu`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.comparison}:total-revenue-per-user`]: {
		defaultSize: ChartSize.day,
		showAverage: false,
		showBaseDatasets: true,
	},
	[`${ANALYTICS_SECTIONS.revenue}:revenue`]: {
		defaultSize: ChartSize.day,
		datasets: {
			['IAP Revenue']: { visible: true },
			['ADs Revenue']: { visible: true },
			['BD Revenue']: { visible: true },
			['Total Revenue']: { visible: true },
		},
	},
	[`${ANALYTICS_SECTIONS.revenue}:iap`]: {
		defaultSize: ChartSize.day,
		datasets: {
			['IAP Sales']: { visible: true },
			['IAP Revenue']: { visible: true },
			['IAP Refunds']: { visible: true },
		},
	},
	[`${ANALYTICS_SECTIONS.revenue}:ads-revenue-top-platforms`]: {
		defaultSize: ChartSize.day,
		showBaseDatasets: true,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.revenue}:ads-revenue-top-content-type`]: {
		defaultSize: ChartSize.day,
		showBaseDatasets: true,
		limit: 10,
	},
	[`${ANALYTICS_SECTIONS.revenue}:ads-revenue`]: {
		defaultSize: ChartSize.day,
		datasets: {
			Total: { visible: true },
			Video: { visible: true },
			Overlay: { visible: true },
		},
	},
	[`${ANALYTICS_SECTIONS.revenue}:cpm-chart`]: {
		defaultSize: ChartSize.day,
		datasets: {
			Total: { visible: true },
			Video: { visible: true },
			Overlay: { visible: true },
		},
	},
	[`${ANALYTICS_SECTIONS.revenue}:fill-rate`]: {
		defaultSize: ChartSize.day,
	},
	[`${ANALYTICS_SECTIONS.revenue}:iap-revenue-top-platforms`]: {
		defaultSize: ChartSize.day,
		showBaseDatasets: true,
		limit: 10,
	},
});

const defaultFilters: IAnalyticsRequestParams = {
	[AnalyticsMetrics.adtype]: [],
	[AnalyticsMetrics.appname]: [],
	[AnalyticsMetrics.apptype]: [],
	[AnalyticsMetrics.country]: [],
	[AnalyticsMetrics.eventaction]: [],
	[AnalyticsMetrics.eventcategory]: [],
	[AnalyticsMetrics.platform]: [],
	[AnalyticsMetrics.source]: [],
	[AnalyticsMetrics.mode]: [],
	[AnalyticsMetrics.genre]: [],
	preset: defaultGeneralPreset,
};

export const tabs: Readonly<Record<string, AnalyticsTabConfig>> = Object.freeze({
	[ANALYTICS_SECTIONS.overview]: {
		autoload: true,
		content: [
			{
				id: `${ANALYTICS_SECTIONS.overview}:usage`,
				type: 'stats',
				visible: true,
				content: [
					{ id: `${ANALYTICS_SECTIONS.overview}:active-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:new-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:returned-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:sessions`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:avg-session-time`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:sessions-per-user`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:total-time-per-user`, type: 'stat-item', visible: true },
				],
			},
			{
				id: `${ANALYTICS_SECTIONS.overview}:users`,
				type: 'group-element',
				visible: true,
				content: [
					{ id: `${ANALYTICS_SECTIONS.overview}:users-pie`, type: 'pie-chart', visible: true },
					{ id: `${ANALYTICS_SECTIONS.overview}:users-by-country`, type: 'map', visible: true },
				],
			},
			{ id: `${ANALYTICS_SECTIONS.overview}:applications-usage`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:users-2`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:active-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:new-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:returned-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:sessions-count`, type: 'bar-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:sessions-length`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.overview}:total-user-time`, type: 'line-chart', visible: true },
		],
		filters: { ...defaultFilters, preset: defaultGeneralPreset },
		defaultFilters: { ...defaultFilters, preset: defaultGeneralPreset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.advertising]: {
		autoload: true,
		content: [
			{
				id: `${ANALYTICS_SECTIONS.advertising}:usage`,
				type: 'stats',
				visible: true,
				content: [
					{ id: `${ANALYTICS_SECTIONS.advertising}:active-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:opportunity`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:impressions`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:fill-rate`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:opportunity-per-user`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:opportunity-per-session`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:impressions-per-user`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:impressions-per-session`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:sessions-per-user`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:avg-session-time`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.advertising}:total-time-per-user`, type: 'stat-item', visible: true },
				],
			},
			{ id: `${ANALYTICS_SECTIONS.advertising}:advertising-data`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:creators-data`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:opportunities/responses/impressions`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:fill-rate`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:impressions`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:impressions-per-user`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:impressions-per-session`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:requests`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.advertising}:ad-server-fill-rate`, type: 'line-chart', visible: true },
		],
		filters: { ...defaultFilters, preset: defaultAdvertisingPreset },
		defaultFilters: { ...defaultFilters, preset: defaultAdvertisingPreset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.retention]: {
		autoload: false,
		filters: { ...defaultFilters, preset: defaultUsageBehaviorPreset, cohortsize: COHORT_SIZE[0] },
		defaultFilters: {
			...defaultFilters,
			preset: defaultUsageBehaviorPreset,
			cohortsize: COHORT_SIZE[0],
		},
		visible: true,
	},
	[ANALYTICS_SECTIONS.avods]: {
		autoload: true,
		content: [
			{
				id: `${ANALYTICS_SECTIONS.avods}:usage`,
				type: 'stats',
				visible: true,
				content: [
					{ id: `${ANALYTICS_SECTIONS.avods}:active-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:new-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:returned-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:sessions`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:avg-session-time`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:sessions-per-user`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:total-time-per-user`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:total-watch-time`, type: 'stat-item', visible: true },
				],
			},
			{
				id: `${ANALYTICS_SECTIONS.avods}:users`,
				type: 'group-element',
				visible: true,
				content: [
					{ id: `${ANALYTICS_SECTIONS.avods}:users-pie`, type: 'pie-chart', visible: true },
					{ id: `${ANALYTICS_SECTIONS.avods}:users-by-country`, type: 'map', visible: true },
				],
			},
			{ id: `${ANALYTICS_SECTIONS.avods}:applications-usage`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:videos-usage`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:users-2`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:active-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:new-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:returned-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:sessions-count`, type: 'bar-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:sessions-length`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.avods}:total-user-time`, type: 'line-chart', visible: true },
		],
		filters: { ...defaultFilters, [AnalyticsMetrics.apptype]: ['video-channel'], preset: defaultAvodsPreset },
		defaultFilters: { ...defaultFilters, [AnalyticsMetrics.apptype]: ['video-channel'], preset: defaultAvodsPreset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.events]: {
		autoload: false,
		content: [{ id: `${ANALYTICS_SECTIONS.events}:events-data`, type: 'table', visible: true }],
		filters: { ...defaultFilters, preset: defaultEventPreset },
		defaultFilters: { ...defaultFilters, preset: defaultEventPreset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.crossPromotion]: {
		autoload: true,
		content: [
			{ id: `${ANALYTICS_SECTIONS.crossPromotion}:cross-promotion`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.crossPromotion}:banners`, type: 'table', visible: true },
		],
		filters: { ...defaultFilters, preset: defaultCrossPromotionPreset },
		defaultFilters: { ...defaultFilters, preset: defaultCrossPromotionPreset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.crossPromotionV2]: {
		autoload: true,
		content: [
			{ id: `${ANALYTICS_SECTIONS.crossPromotionV2}:cross-promotion`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.crossPromotionV2}:banners`, type: 'table', visible: true },
		],
		filters: { ...defaultFilters, preset: defaultCrossPromotionV2Preset },
		defaultFilters: { ...defaultFilters, preset: defaultCrossPromotionV2Preset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.comparison]: {
		autoload: false,
		content: [
			{ id: `${ANALYTICS_SECTIONS.comparison}:users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:new-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:returned-users`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:sessions-count`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:sessions-length`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:sessions-per-user`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:total-user-time`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:impressions`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:impressions-per-user`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:ads-revenue`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:iap-revenue`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:total-revenue`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:ads-arpu`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:iap-arpu`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.comparison}:total-revenue-per-user`, type: 'line-chart', visible: true },
		],
		filterLimit: DEFAULT_COMPARISON_LIMIT,
		filters: { ...defaultFilters, preset: defaultComparisonPreset },
		defaultFilters: { ...defaultFilters, preset: defaultComparisonPreset },
		visible: true,
	},
	[ANALYTICS_SECTIONS.revenue]: {
		autoload: true,
		content: [
			{
				id: `${ANALYTICS_SECTIONS.revenue}:usage`,
				type: 'stats',
				visible: true,
				content: [
					{ id: `${ANALYTICS_SECTIONS.revenue}:active-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:paying-users`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:impressions`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:video-impressions`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:overlay-impressions`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:cpm`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:video-cpm`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:overlay-cpm`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:ads-revenue`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:video-ads-revenue`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:overlay-ads-revenue`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:bright-data-revenue`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:ads-arpu`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:video-ads-arpu`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:overlay-ads-arpu`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:bright-data-arpu`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:iap-sales`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:iap-refunds`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:iap-revenue`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:iap-arpu`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:iap-arppu`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:avg-iap-cost`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:iap-conversion-rate`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:total-revenue`, type: 'stat-item', visible: true },
					{ id: `${ANALYTICS_SECTIONS.revenue}:total-revenue-per-user`, type: 'stat-item', visible: true },
				],
			},
			{ id: `${ANALYTICS_SECTIONS.revenue}:total-revenue-data`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:ads-revenue-data`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:iap-revenue-data`, type: 'table', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:revenue`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:iap`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:ads-revenue-top-platforms`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:ads-revenue-top-content-type`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:ads-revenue`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:cpm-chart`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:fill-rate`, type: 'line-chart', visible: true },
			{ id: `${ANALYTICS_SECTIONS.revenue}:iap-revenue-top-platforms`, type: 'line-chart', visible: true },
		],
		filters: { ...defaultFilters, preset: defaultRevenuePresets },
		defaultFilters: { ...defaultFilters, preset: defaultRevenuePresets },
		visible: true,
	},
});

export const defaultTabsOrder: Array<ValueOf<typeof ANALYTICS_SECTIONS>> = [
	ANALYTICS_SECTIONS.overview,
	ANALYTICS_SECTIONS.advertising,
	ANALYTICS_SECTIONS.avods,
	ANALYTICS_SECTIONS.revenue,
	ANALYTICS_SECTIONS.retention,
	ANALYTICS_SECTIONS.comparison,
	ANALYTICS_SECTIONS.crossPromotion,
	ANALYTICS_SECTIONS.crossPromotionV2,
	ANALYTICS_SECTIONS.events,
];
