// modules
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';
// utils
import { manageCustomVariableInCharts, manageCustomVariableInTables, manageCustomVariableInTabs } from 'utils/manageCustomVariable';
// types
import { ICustomVariable } from 'components/Analytics/types';
import { ANALYTICS_SECTIONS } from 'consts';
import { IAnalyticsParams, IAnalyticsRequestParams } from 'types/analytics';
import { ITheme, ValueOf } from 'types/general';
import { IMetricsProxyRule, IUserData } from 'types/login';
import { ISecurityPermissions } from 'types/security';
import { AnalyticsTabConfig, Auth, ChartConfig, Config, RootState, TableConfig } from '../types';

const initialState: Auth = {
	isAuthorized: false,
	loading: false,
	user: null,
};

export const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => ({
			...state,
			loading: action.payload,
		}),
		login: (state, action: PayloadAction<Partial<Auth>>) => ({
			...state,
			...action.payload,
		}),
		/**
		 * @description replace current rules with provided rules
		 */
		setUserProxyRules: (
			state,
			action: PayloadAction<{
				metricsProxy: IMetricsProxyRule[];
				isConfigShared?: boolean;
				mergedPermissions?: ISecurityPermissions<IAnalyticsParams> | null;
			}>
		) => {
			if (!state.user) return state;
			let mergedPermissions;

			if (action.payload.mergedPermissions) {
				mergedPermissions = {
					...state.user.data?.mergedPermissions,
					...action.payload.mergedPermissions,
				};
			}

			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						metricsProxy: action.payload.metricsProxy,
						isConfigShared: Boolean(action.payload.isConfigShared),
						mergedPermissions,
					},
				},
			};
		},
		/**
		 * @description spread provided partial data
		 */
		changeUserData: (state, action: PayloadAction<IUserData>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						...action.payload,
					},
				},
			};
		},
		/**
		 * @description replace current config with provided partial config
		 */
		setUserConfig: (state, action: PayloadAction<{ config: Partial<Config>; isConfigShared?: boolean }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: action.payload.config,
						isConfigShared: Boolean(action.payload.isConfigShared),
					},
				},
			};
		},
		/**
		 * @description spread provided partial config
		 */
		changeUserConfig: (state, action: PayloadAction<{ config: Partial<Config>; isConfigShared?: boolean }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...(state.user.data?.config || {}),
							...action.payload.config,
						},
						isConfigShared: Boolean(action.payload.isConfigShared),
					},
				},
			};
		},
		/**
		 * @description spreading provided partial table config
		 */
		changeTableConfig: (state, action: PayloadAction<{ tableId: string; config: Partial<TableConfig> }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							tables: {
								...state.user.data?.config?.tables,
								[action.payload.tableId]: {
									...state.user.data?.config?.tables?.[action.payload.tableId],
									...action.payload.config,
								},
							},
						},
					},
				},
			};
		},
		/**
		 * @description spreading provided partial chart config
		 */
		changeChartConfig: (state, action: PayloadAction<{ chartId: string; config: Partial<ChartConfig> }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							charts: {
								...state.user.data?.config?.charts,
								[action.payload.chartId]: {
									...state.user.data?.config?.charts?.[action.payload.chartId],
									...action.payload.config,
								},
							},
						},
					},
				},
			};
		},
		/**
		 * @description
		 * 1. Update existing variable
		 * 2. Update variables in analytics items
		 */
		changeCustomVariable: (state, action: PayloadAction<{ variable: ICustomVariable }>) => {
			if (!state.user) return state;

			const newVariables = state.user.data?.config?.variables?.reduce<ICustomVariable[]>((acc, c) => {
				const isEqual = c.id === action.payload.variable.id;
				if (isEqual) acc.push({ ...c, ...action.payload.variable });
				else acc.push(c);
				return acc;
			}, []);

			const charts = manageCustomVariableInCharts(action.payload.variable, state.user.data?.config?.charts || {});
			const tables = manageCustomVariableInTables(action.payload.variable, (state.user.data?.config?.tables || {}) as Record<string, TableConfig>);
			const customTabs = manageCustomVariableInTabs(action.payload.variable, state.user.data?.config?.analytics?.customTabs || {});

			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							variables: newVariables,
							charts,
							tables,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: { ...state.user.data?.config?.analytics?.tabs },
								customTabs,
							},
						},
					},
				},
			};
		},
		/**
		 * @description
		 * 1. Delete existing variables
		 * 2. Delete variables from analytics items
		 */
		deleteCustomVariables: (state, action: PayloadAction<{ variables: ICustomVariable[] }>) => {
			if (!state.user) return state;
			const ids = action.payload.variables.map((i) => i.id);
			const newVariables = state.user.data?.config?.variables?.filter((i) => !ids.includes(i.id));

			let charts = state.user.data?.config?.charts;
			let tables = state.user.data?.config?.tables;
			let customTabs = state.user.data?.config?.analytics?.customTabs;

			action.payload.variables.forEach((variable) => {
				charts = manageCustomVariableInCharts(variable, charts || {}, 'delete');
				tables = manageCustomVariableInTables(variable, (tables || {}) as Record<string, TableConfig>, 'delete');
				customTabs = manageCustomVariableInTabs(variable, customTabs || {}, 'delete');
			});

			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							variables: newVariables,
							charts,
							tables,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: { ...state.user.data?.config?.analytics?.tabs },
								customTabs,
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current config with provided partial tab config
		 */
		setAnalyticsTabConfig: (state, action: PayloadAction<{ tab: string; config: AnalyticsTabConfig }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: {
									...state.user.data?.config?.analytics?.tabs,
									[action.payload.tab]: action.payload.config,
								},
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current tab filters with provided tab filters
		 */
		setAnalyticsTabFilters: (state, action: PayloadAction<{ tab: string; filters: IAnalyticsRequestParams }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: {
									...state.user.data?.config?.analytics?.tabs,
									[action.payload.tab]: {
										...state.user.data?.config?.analytics?.tabs?.[action.payload.tab],
										filters: action.payload.filters,
									},
								},
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current tab filters with provided tab filters
		 */
		setAnalyticsCustomTabFilters: (state, action: PayloadAction<{ tab: string; filters: IAnalyticsRequestParams }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: { ...state.user.data?.config?.analytics?.tabs },
								customTabs: {
									...state.user.data?.config?.analytics?.customTabs,
									[action.payload.tab]: {
										...state.user.data?.config?.analytics?.customTabs?.[action.payload.tab],
										filters: action.payload.filters,
									},
								},
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current tabsOrder with provided one
		 */
		setAnalyticsTabsOrder: (state, action: PayloadAction<ValueOf<typeof ANALYTICS_SECTIONS>[]>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: {
									...state.user.data?.config?.analytics?.tabs,
								},
								tabsOrder: action.payload,
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current customTabsOrder with provided one
		 */
		setAnalyticsCustomTabsOrder: (state, action: PayloadAction<string[]>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: {
									...state.user.data?.config?.analytics?.tabs,
								},
								customTabsOrder: action.payload,
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current tab config with provided one. Creates tab if not exists
		 */
		changeAnalyticsCustomTab: (state, action: PayloadAction<{ tab: string; config: AnalyticsTabConfig }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: {
									...state.user.data?.config?.analytics?.tabs,
								},
								customTabs: {
									...state.user.data?.config?.analytics?.customTabs,
									[action.payload.tab]: action.payload.config,
								},
							},
						},
					},
				},
			};
		},
		/**
		 * @description replace current tab config with provided one. Creates tab if not exists
		 */
		deleteAnalyticsCustomTab: (state, action: PayloadAction<{ tab: string }>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							analytics: {
								...state.user.data?.config?.analytics,
								tabs: { ...state.user.data?.config?.analytics?.tabs },
								customTabs: omit(state.user.data?.config?.analytics?.customTabs, action.payload.tab),
								customTabsOrder: (state.user.data?.config?.analytics?.customTabsOrder || []).filter((i) => i !== action.payload.tab),
							},
						},
					},
				},
			};
		},
		/** @description delete charts, tables by ids */
		deleteAnalyticsItemById: (state, action: PayloadAction<{ ids: string[] }>) => {
			if (!state.user || !action.payload.ids.length) return state;
			const filterObj = (acc: Record<string, unknown>, c: [string, ChartConfig | TableConfig]) => {
				const [id, config] = c;
				if (action.payload.ids.includes(id)) return acc;
				acc[id] = config;
				return acc;
			};
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							charts: Object.entries(state.user.data?.config?.charts || {}).reduce<Record<string, unknown>>(filterObj, {}) as Record<
								string,
								ChartConfig
							>,
							tables: Object.entries(state.user.data?.config?.tables || {}).reduce<Record<string, unknown>>(filterObj, {}) as Record<
								string,
								TableConfig
							>,
						},
					},
				},
			};
		},
		/**
		 * @description spreading provided partial theme config
		 */
		changeTheme: (state, action: PayloadAction<Partial<ITheme>>) => {
			if (!state.user) return state;
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							theme: {
								...state.user.data?.config?.theme,
								...action.payload,
							},
						},
					},
				},
			};
		},
		/**
		 * @description create/replace variable
		 */
		setAnalyticsCustomVariable: (state, action: PayloadAction<{ variable: ICustomVariable }>) => {
			if (!state.user) return state;
			const { variable } = action.payload;
			const variables = (state.user.data?.config?.variables || []).filter((i) => i.id !== variable.id);
			variables.push(variable);
			return {
				...state,
				user: {
					...state.user,
					data: {
						...state.user.data,
						config: {
							...state.user.data?.config,
							variables,
						},
					},
				},
			};
		},
		logout: (state) => ({
			...state,
			isAuthorized: false,
			user: null,
		}),
	},
});

export const useAuth = () => useSelector((state: RootState) => state.auth);
