// modules
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import { useCallback, useMemo } from 'react';
// utils
import { getColumnId } from 'components/AGTable/getColumnId';
import { DEFAULT_DASHBOARD_ITEMS, SECURITY_SEPARATOR } from 'consts';
import { charts as defaultCharts, tables as defaultTables } from 'store/defaultState';
import { useAuth } from 'store/slices/auth';
// types
import { AnalyticsConfig, AnalyticsContentItem, ChartConfig, IDashboardItem, TableConfig } from 'store/types';
import { IAnalyticsParams, IAnalyticsRequestParams } from 'types/analytics';
import { Access, ISecurityPermissions, ISecurityRule, RuleType } from 'types/security';
import { AGTableColumn } from './../components/AGTable/types';

export const useSecurity = (permissions?: ISecurityPermissions<IAnalyticsParams> | ISecurityPermissions<IAnalyticsRequestParams>) => {
	const { user: currentUser } = useAuth();
	const contextPermissions = permissions || currentUser?.data?.mergedPermissions || currentUser?.data?.permissions;

	const mergeAnalyticsContentPermissions = useCallback((...contentPermissions: AnalyticsContentItem[][]) => {
		const result = contentPermissions.reduce<AnalyticsContentItem[] | undefined>((leftContent, rightContent) => {
			if (!leftContent) return rightContent;

			return leftContent.map((leftContentItem) => {
				const rightItemConfig = rightContent.find((i) => leftContentItem.id === i.id);
				if (!rightItemConfig) return leftContentItem;
				const result = cloneDeep(leftContentItem);
				result.visible = leftContentItem.visible !== false && rightItemConfig.visible !== false;

				if (!result.visible || !result.content) return result;
				result.content = mergeAnalyticsContentPermissions(result.content, rightItemConfig.content || []);
				return result;
			});
		}, undefined);
		return result || [];
	}, []);

	const mergeTablesColumns = useCallback((hiddenColumns: string[], ...columnsPermissions: AGTableColumn[][]): AGTableColumn[] => {
		const result = columnsPermissions.reduce<AGTableColumn[] | undefined>((left, right) => {
			if (!left) return right;
			return left.map((leftColumn) => {
				const columnId = getColumnId(leftColumn);
				const rightColumn = right.find((i) => columnId === getColumnId(i));
				if (!rightColumn) return leftColumn;
				const hiddenDefault = hiddenColumns.includes(columnId) || leftColumn.hiddenDefault === true || rightColumn.hiddenDefault === true;
				const leftChildren = get(leftColumn, 'children') as AGTableColumn[] | undefined;
				if (hiddenDefault || !leftChildren) return { ...leftColumn, hiddenDefault };
				const rightChildren = get(rightColumn, 'children') as AGTableColumn[] | undefined;
				const children = mergeTablesColumns(hiddenColumns, leftChildren, rightChildren || []);
				return { ...leftColumn, children, hiddenDefault };
			});
		}, undefined);
		return result || [];
	}, []);

	/**
	 * @description
	 * - merge permissions in priority of provided index
	 * - 0 index is a top priority
	 * - limit - permissions can be only limited
	 * - extend - each permission extend final result
	 */
	const mergePermissions = useCallback(
		(mode: 'limit' | 'extend', ...permissions: Array<ISecurityPermissions<IAnalyticsParams> | undefined>): ISecurityPermissions<IAnalyticsParams> => {
			const isExtend = mode === 'extend';
			const isLimit = mode === 'limit';
			const clonedPermissions = cloneDeep(permissions.filter(Boolean)) as ISecurityPermissions<IAnalyticsParams>[];
			const rules = clonedPermissions.reduce<ISecurityRule[] | null>((left, right) => {
				if (!left) return right.rules;
				if (!left.length) return left;
				right.rules.forEach((rightRule) => {
					const leftRuleIdx = left.findIndex((i) => i.key === rightRule.key && i.type === rightRule.type);

					switch (true) {
						case leftRuleIdx === -1:
							left.push(rightRule);
							break;
						case !left[leftRuleIdx].access.length:
							break;
						case !rightRule.access.length:
							left[leftRuleIdx].access = [];
							break;
						default: {
							const combined = uniq([...left[leftRuleIdx].access, ...rightRule.access]);
							/** @description keep exact access order */
							const ordered = (Object.keys(Access) as Access[]).filter((i) => combined.includes(i));
							left[leftRuleIdx].access = ordered;
						}
					}
				});

				return left;
			}, null);

			const analytics = clonedPermissions.reduce<AnalyticsConfig<IAnalyticsParams> | undefined>((left, right) => {
				if (!left) return right.analytics || undefined;
				Object.keys(right.analytics?.tabs || {}).forEach((tabKey) => {
					const rightTabConfig = right.analytics?.tabs[tabKey];
					if (!rightTabConfig) return;
					if (!left.tabs[tabKey] && isLimit) return;
					if (!left.tabs[tabKey] && isExtend) {
						left.tabs = { ...right.analytics?.tabs, ...left.tabs, [tabKey]: rightTabConfig };
						return;
					}
					left.tabs[tabKey].visible = left.tabs[tabKey].visible ?? rightTabConfig.visible ?? false;
					if (left.tabs[tabKey].filterLimit || rightTabConfig.filterLimit) {
						left.tabs[tabKey].filterLimit = left.tabs[tabKey].filterLimit ?? rightTabConfig.filterLimit;
					}
					left.tabs[tabKey].hiddenFilters = uniq([...(left.tabs[tabKey].hiddenFilters || []), ...(rightTabConfig.hiddenFilters || [])]);
					left.tabs[tabKey].filters = Object.keys(rightTabConfig.filters || {}).reduce((leftFilters, filterKey) => {
						try {
							if (['date', 'preset', 'enddate', 'startdate', 'metrics'].includes(filterKey)) return leftFilters;

							const leftFilterValue = get(leftFilters, filterKey) as unknown;
							const rightFilterValue = get(rightTabConfig.filters, filterKey) as unknown;
							const isDifferentTypes =
								typeof leftFilterValue !== typeof rightFilterValue || Array.isArray(leftFilterValue) !== Array.isArray(rightFilterValue);
							if (isDifferentTypes) {
								const result = leftFilterValue ?? rightFilterValue;
								if (result !== undefined) set(leftFilters, filterKey, result);
								return leftFilters;
							}

							switch (true) {
								case Array.isArray(leftFilterValue): {
									if (!(rightFilterValue as unknown[] | undefined)?.length) return leftFilters;
									const result = uniqWith([...(leftFilterValue as unknown[]), ...(rightFilterValue as unknown[])], isEqual);
									set(leftFilters, filterKey, result);
									return leftFilters;
								}
								case typeof leftFilterValue === 'number': {
									set(leftFilters, filterKey, Math.min(leftFilterValue as number, rightFilterValue as number));
									return leftFilters;
								}
								default:
									return leftFilters;
							}
						} catch (err) {
							// eslint-disable-next-line no-console
							console.error(`Failed to merge "${filterKey}" analytics filter`);
							return leftFilters;
						}
					}, left.tabs[tabKey].filters || {});

					if (left.tabs[tabKey].content) {
						left.tabs[tabKey].content = mergeAnalyticsContentPermissions(left.tabs[tabKey].content || [], rightTabConfig.content || []);
					}
				});

				left.tabsOrder = uniq([...(left.tabsOrder || []), ...(right.analytics?.tabsOrder || [])]);
				return left;
			}, undefined);

			const dashboardItems = clonedPermissions.reduce<Array<IDashboardItem> | undefined>((left, right) => {
				if (!left) return right.dashboard?.items || undefined;
				const items = uniqBy([...(right.dashboard?.items || []), ...(left || [])], 'id');
				return items.reduce<IDashboardItem[]>((acc, i) => {
					const defaultItem = DEFAULT_DASHBOARD_ITEMS?.find((d) => d.id === i.id);
					if (!defaultItem) return acc;
					const leftItem = left?.find((r) => r.id === i.id) || ({} as IDashboardItem);
					const rightItem = right?.dashboard?.items?.find((r) => r.id === i.id) || ({} as IDashboardItem);
					const visible = leftItem?.visible === false ? false : Boolean(rightItem?.visible ?? leftItem?.visible);
					acc.push({ ...defaultItem, ...leftItem, ...rightItem, visible });
					return acc;
				}, []);
			}, undefined);

			const dashboardLocations = clonedPermissions.reduce<string[] | undefined>((left, right) => {
				if (!left) return right.dashboard?.locations || undefined;
				return left || [];
			}, undefined);

			if (!analytics) {
				const result: ISecurityPermissions<IAnalyticsParams> = { rules: rules || [] };
				if (dashboardItems) result.dashboard = { items: dashboardItems, locations: dashboardLocations || [] };
				return result;
			}

			const charts = clonedPermissions.reduce<Record<string, ChartConfig> | undefined>((acc, right) => {
				if (!acc || isEmpty(acc)) return { ...defaultCharts, ...right.charts };
				Object.keys(acc || {}).forEach((chartId) => {
					const defaultChartConfig = defaultCharts[chartId] || {};
					const leftChartConfig = acc[chartId] || {};
					const rightChartConfig = right.charts?.[chartId] || {};
					set(acc, chartId, { ...defaultChartConfig, ...rightChartConfig, ...leftChartConfig });
					if (acc[chartId].datasets) {
						Object.keys(acc[chartId].datasets || {}).forEach((datasetId) => {
							set(acc, `${chartId}.datasets.${datasetId}`, {
								...defaultChartConfig.datasets?.[datasetId],
								...leftChartConfig.datasets?.[datasetId],
								...rightChartConfig.datasets?.[datasetId],
								visible: Boolean(
									leftChartConfig.datasets?.[datasetId]?.visible !== false && rightChartConfig.datasets?.[datasetId]?.visible !== false
								),
							});
						});
					}
				});
				return acc;
			}, undefined);

			const tables = clonedPermissions.reduce<Record<string, TableConfig> | undefined>((acc, right) => {
				if (!acc || isEmpty(acc)) return { ...defaultTables, ...right.tables };
				const rightTablesConfig = right.tables;
				Object.keys(acc || {}).forEach((tableKey) => {
					const defaultTableConfig: TableConfig | undefined = defaultTables[tableKey];
					const leftTableConfig = acc[tableKey];
					const rightTableConfig = rightTablesConfig?.[tableKey];
					acc[tableKey] = { ...defaultTableConfig, ...rightTableConfig, ...leftTableConfig };
					acc[tableKey].hiddenColumns = uniq([...(leftTableConfig.hiddenColumns || []), ...(rightTableConfig?.hiddenColumns || [])]);

					if (leftTableConfig.columns) {
						acc[tableKey].columns = mergeTablesColumns(
							acc[tableKey].hiddenColumns || [],
							defaultTableConfig?.columns || [],
							leftTableConfig?.columns || [],
							rightTableConfig?.columns || []
						);
					}
				});
				return acc;
			}, undefined);

			const result: ISecurityPermissions<IAnalyticsParams> = { analytics, charts, rules: rules || [], tables };
			if (dashboardItems)
				result.dashboard = {
					items: dashboardItems,
					locations: dashboardLocations || [],
				};
			return result;
		},
		[mergeAnalyticsContentPermissions, mergeTablesColumns]
	);

	/**
	 * @example
	 * const SECURITY_SEPARATOR = '.'
	 * keyToObject('platform.Roku.deployment.QA.appname.Tetris')
	 * // Result: { platform: 'Roku', deployment: 'QA', appname: 'Tetris' }
	 */
	const keyToObject = useCallback((key: string) => {
		const split = (key || '').split(SECURITY_SEPARATOR);
		return split.reduce<Record<string, string | undefined>>((acc, c, idx, arr) => {
			if (idx % 2) return acc;
			const value: string | undefined = arr[idx + 1];
			if (Object.hasOwnProperty.call(acc, c)) throw new Error('Security Rule Key has duplicated property');
			acc[c] = value;
			return acc;
		}, {});
	}, []);

	/**
	 * @example
	 * objectToKey({ platform: 'Roku', deployment: 'QA', appname: 'Tetris' })
	 * // Result: 'platform.Roku.deployment.QA.appname.Tetris'
	 */
	const objectToKey = useCallback((object: Record<string, string>) => {
		const arrKey = [];

		for (const key in object) {
			arrKey.push(key, object[key]);
		}

		return arrKey.join(SECURITY_SEPARATOR);
	}, []);

	const parseRule = useCallback(
		(rule: ISecurityRule) => {
			const arrKey = (rule.key || '').split(SECURITY_SEPARATOR);

			return {
				...rule,
				arrKey,
				objectKey: keyToObject(rule.key || ''),
				read: (rule.access || []).includes(Access.read),
				create: (rule.access || []).includes(Access.create),
				update: (rule.access || []).includes(Access.update),
				delete: (rule.access || []).includes(Access.delete),
				original: rule,
			};
		},
		[keyToObject]
	);

	const parsedRules = useMemo(() => (contextPermissions?.rules || []).map(parseRule), [parseRule, contextPermissions?.rules]);

	const allowedPlatforms = useMemo(() => {
		const result = parsedRules.reduce<string[]>((acc, c) => {
			if (acc.includes('*') || !c.access.length) return acc;
			const platform = c.objectKey.platform;
			if (platform && !acc.includes(platform)) acc.push(platform);
			return acc;
		}, []);
		if (result.includes('*')) return ['*'];
		return result;
	}, [parsedRules]);

	const allowedDeployments = useMemo(() => {
		const result = parsedRules.reduce<string[]>((acc, c) => {
			if (acc.includes('*') || !c.access.length) return acc;
			const deployment = c.objectKey.deployment;
			if (deployment && !acc.includes(deployment)) acc.push(deployment);
			return acc;
		}, []);
		if (result.includes('*')) return ['*'];
		return result;
	}, [parsedRules]);

	const allowedApplications = useMemo(() => {
		const result = parsedRules.reduce<string[]>((acc, c) => {
			if (acc.includes('*') || !c.access.length) return acc;
			const application = c.objectKey.appname;
			if (application && !acc.includes(application)) acc.push(application);
			return acc;
		}, []);
		if (result.includes('*')) return ['*'];
		return result;
	}, [parsedRules]);

	/**
	 * @description
	 * - checks whether elements has provided access
	 * - if access not provided will search for any access
	 * @example
	 * isItemAccessible('some-id', 'read');
	 * // Rule: { key: 'some-id', access: ['read'] };
	 * // Returns: true
	 * isItemAccessible('some-id');
	 * // Rule: { key: 'some-id', access: ['read'] };
	 * // Returns: true
	 * isItemAccessible('some-id');
	 * // Rule: { key: 'some-id', access: [] };
	 * // Returns: false
	 * isItemAccessible('some-id', 'write');
	 * // Rule: { key: 'some-id', access: ['read'] };
	 * // Returns: false
	 * isItemAccessible('some-id', ['read', 'write']);
	 * // Rule: { key: 'some-id', access: ['read', 'write'] };
	 * // Returns: true
	 * isItemAccessible('some-id', ['read', 'write']);
	 * // Rule: { key: 'some-id', access: ['read'] };
	 * // Returns: false
	 * isItemAccessible('some-id', 'write');
	 * // Rule: undefined;
	 * // Returns: false
	 */
	const isItemAccessible = useCallback(
		(key: string, type: RuleType, access?: Access | Access[]) => {
			const rule = contextPermissions?.rules.find((i) => i.key === key && i.type === type);
			if (!access?.length) return Boolean(rule?.access.length);
			if (Array.isArray(access))
				return access.reduce((acc, curr) => {
					if (!acc) return false;
					return Boolean(rule?.access?.includes(curr));
				}, true);
			return Boolean(rule?.access.includes(access));
		},
		[contextPermissions]
	);

	/** @description checks if platform accessible (BaaS) */
	const isPlatformAccessible = useCallback(
		(platformName: string) => allowedPlatforms.includes('*') || allowedPlatforms.includes(platformName),
		[allowedPlatforms]
	);

	/** @description checks if deployment accessible (BaaS) */
	const isDeploymentAccessible = useCallback(
		(deploymentName: string) => allowedDeployments.includes('*') || allowedDeployments.includes(deploymentName),
		[allowedDeployments]
	);

	/** @description checks if application accessible (BaaS) */
	const isApplicationAccessible = useCallback(
		(appName: string) => allowedApplications.includes('*') || allowedApplications.includes(appName),
		[allowedApplications]
	);

	return {
		isItemAccessible,
		isPlatformAccessible,
		isDeploymentAccessible,
		isApplicationAccessible,
		keyToObject,
		mergePermissions,
		objectToKey,
		parsedRules,
		parseRule,
		permissions: contextPermissions,
	};
};
