const pulseOut = {
	'0%': {
		transform: 'scale(1)',
	},
	'10%': {
		transform: 'scale(1.1)',
	},
	'25%': {
		transform: 'scale(.9)',
	},
	'40%': {
		transform: 'scale(1.05)',
	},
	'50%': {
		transform: 'scale(1)',
	},
};

const pulseIn = {
	'0%': {
		transform: 'scale(1)',
	},
	'10%': {
		transform: 'scale(.9)',
	},
	'25%': {
		transform: 'scale(1.1)',
	},
	'40%': {
		transform: 'scale(.95)',
	},
	'50%': {
		transform: 'scale(1)',
	},
};

const rotate = {
	'0%': {
		transform: 'rotate(0deg) scale(1)',
	},
	'5%': {
		transform: 'rotate(20deg) scale(1.2)',
	},
	'60%': {
		transform: 'rotate(1080deg) scale(1)',
	},
	'100%': {
		transform: 'rotate(1080deg) scale(1)',
	},
};

const rolyPoly = {
	'0%': {
		transform: 'rotate(0deg)',
	},
	'20%': {
		transform: 'rotate(10deg) scale(1.1)',
	},
	'40%': {
		transform: 'rotate(-20deg) scale(1.2)',
	},
	'60%': {
		transform: 'rotate(25deg) scale(1.1)',
	},
	'80%': {
		transform: 'rotate(-25deg) scale(0.9)',
	},
	'100%': {
		transform: 'rotate(0deg) scale(1)',
	},
};

const slingshot = {
	'0%': {
		transform: 'translate(0, 0)',
	},
	'40%': {
		transform: 'translate(0, 20px)',
	},
	'50%': {
		transform: 'translate(0, -30px)',
	},
	'60%': {
		transform: 'translate(0, 10px)',
	},
	'70%': {
		transform: 'translate(0, -5px)',
	},
	'75%': {
		transform: 'translate(0, 3px)',
	},
	'80%': {
		transform: 'translate(0, -1px)',
	},
	'85%': {
		transform: 'translate(0, 0)',
	},
};

const textSlide = {
	'0%': {
		transform: 'translate(-125%, 0%)',
	},
	'100%': {
		transform: 'translate(0%, 0%)',
	},
};

const ANIMATIONS = {
	pulseIn,
	pulseOut,
	rolyPoly,
	rotate,
	slingshot,
	textSlide,
};

type Easing = 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out' | string;

type AnimationKey = keyof typeof ANIMATIONS;

/** @description all time in seconds */
export const anim = (
	animationKey: AnimationKey,
	duration: number,
	easing?: Easing,
	delay?: number,
	infinite?: boolean,
	forwards?: boolean
): Record<string, string | object> => {
	const animationFunction = ANIMATIONS[animationKey];
	const easingFunc = easing ? ` ${easing}` : '';
	const delaySec = delay ? ` ${delay}s` : '';
	const iteration = infinite ? ` infinite` : '';
	const forward = forwards ? ` forwards` : '';

	return {
		animation: `${animationKey} ${duration}s${easingFunc}${delaySec}${iteration}${forward}`,
		[`@keyframes ${animationKey}`]: animationFunction,
	};
};
