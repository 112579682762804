// modules
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { Box, Divider, Drawer, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// components
import { Logo } from 'components/Logo';
import { Version } from 'components/Version';
import { SidebarSection } from './SidebarSection';
// utils
import { config } from 'config/config';
import { pages } from 'config/pages';
import { useSecurity } from 'hooks/useSecurity';
import { actions } from 'store/actions';
import { useConfig } from 'store/slices/config';
// types
import { Access, RuleType } from 'types/security';

export const Sidebar = () => {
	const dispatch = useDispatch();
	const { sidebarOpen: open } = useConfig();
	const { sections } = config.sidebar;
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
	const { isItemAccessible } = useSecurity();

	const logoClickRedirectPath = useMemo(() => {
		return pages.find((i) => i.securityId && isItemAccessible(i.securityId, RuleType.page, Access.read))?.path || '';
	}, [pages, isItemAccessible]);

	const handleSidebarClose = useCallback(() => {
		dispatch(actions.config.closeSidebar());
	}, []);

	const handleSidebarTrigger = useCallback(() => {
		dispatch(actions.config.triggerSidebar());
	}, []);

	const renderCloseButton = () => {
		if (!lgUp) return null;
		return (
			<Box
				display="flex"
				justifyContent="center"
				onClick={handleSidebarTrigger}
				sx={{
					py: 2,
					position: 'absolute',
					cursor: 'pointer',
					bottom: 0,
					left: 0,
					right: 0,
					borderTop: '1px solid #2d3748',
				}}
			>
				<IconButton size="small">
					<KeyboardDoubleArrowRightRoundedIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
				</IconButton>
			</Box>
		);
	};

	const content = (
		<>
			<Box
				sx={{
					display: 'flex',
					overflow: 'hidden',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box>
					<Box
						sx={{
							p: open ? 2 : '6px',
							transition: 'padding .3s',
							display: 'flex',
							alignItems: 'center',
							gap: 2,
						}}
					>
						<Link to={logoClickRedirectPath} style={{ display: 'flex' }}>
							<Logo
								sx={{
									height: open ? 64 : 48,
									width: open ? 64 : 48,
									transition: 'width .2s, height .2s',
								}}
							/>
						</Link>
						<Typography
							component="div"
							sx={{
								fontSize: 14,
								fontWeight: 'bold',
								whiteSpace: 'nowrap',
								opacity: Number(open),
								transform: open ? 'scale(1)' : 'scale(0)',
								transition: 'transform .4s, opacity .4s',
								display: 'flex',
								gap: 1,
							}}
						>
							<Box>
								The #1 provider <br /> of CTV Games and <br /> Original TV Channels
							</Box>
						</Typography>
					</Box>
					<Version
						sx={{ position: 'static', backgroundColor: 'transparent', textAlign: 'right', opacity: open ? 0.9 : 0, transition: 'opacity .4s' }}
						customRender={(version) => (
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>Version: {version}</Box>
						)}
					/>
				</Box>
				<Divider
					sx={{
						borderColor: '#2D3748',
						mb: 3,
						display: 'block',
					}}
				/>
				<Box
					sx={{
						position: 'absolute',
						top: open ? 150 : 100,
						transition: 'top .3s',
						overflowY: open ? 'scroll' : 'hidden',
						overflowX: 'hidden',
						bottom: lgUp ? 65 : 0,
						left: 0,
						right: 0,
					}}
				>
					{sections.map((section, idx) => (
						<SidebarSection
							key={section.title}
							divide={!!idx}
							sx={{
								mt: open ? 2 : 0,
								p: 0,
								transition: 'margin-top .3s',
							}}
							{...section}
						/>
					))}
				</Box>
				{renderCloseButton()}
			</Box>
		</>
	);

	if (lgUp) {
		return (
			<Drawer
				id="sidebar"
				anchor="left"
				sx={{ zIndex: 2000 }}
				PaperProps={{
					sx: {
						zIndex: 2000,
						backgroundColor: 'neutral.900',
						borderRightColor: (theme) => (theme.palette.mode === 'dark' ? 'divider' : 'neutral.900'),
						borderRightStyle: 'solid',
						borderRightWidth: 1,
						color: '#FFFFFF',
						width: open ? 280 : 60,
						transition: 'width .3s',
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor="left"
			onClose={handleSidebarClose}
			open={open}
			PaperProps={{
				sx: {
					backgroundColor: 'neutral.900',
					color: '#FFFFFF',
					width: 280,
				},
			}}
			sx={{ zIndex: 2000 }}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};
