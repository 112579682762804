// types
import { Access } from 'types/security';

export const COLORS: Record<string, string> = {
	'at&t': 'rgb(77, 174, 231)',
	airconsole: 'rgb(187, 234, 74)',
	att_osprey: 'rgb(77, 174, 231)',
	bt: 'rgb(77, 34, 173)',
	cogeco: 'rgb(123, 180, 255)',
	comcast: 'rgb(237, 107, 45)',
	cox: 'rgb(0, 180, 255)',
	crazygames: 'rgb(99, 63, 179)',
	dishtv: 'rgb(220, 85, 52)',
	fauricia: 'rgb(49, 54, 116)',
	firetv: 'rgb(226, 96, 68)',
	hilton: 'rgb(168,177,184)',
	honda: 'rgb(218, 62, 63)',
	lightning: 'rgb(81, 122, 246)',
	mercedes: 'rgb(195, 195, 195)',
	netgem: 'rgb(217, 105, 59)',
	other: 'rgba(200, 200, 200, 0.8)',
	philips: 'rgb(36,  39, 77)',
	retail: 'rgb(50, 200, 50)',
	rogers: 'rgb(200, 60, 43)',
	roku: 'rgb(100, 200, 100)',
	samsung: 'rgb(23, 42, 146)',
	shaw: 'rgb(75, 167, 200)',
	sony_youview: 'rgb(89, 148, 184)',
	sony: 'rgb(4, 2, 126)',
	talktalk: 'rgb(121, 232, 107)',
	verizon: 'rgb(200, 46, 34)',
	vewd: 'rgb(61, 95, 120)',
	vidaa: 'rgb(150, 90, 223)',
	videotron: 'rgb(248, 212, 72)',
	xumo: 'rgb(188, 67, 132)',
	zeasn: 'rgb(223, 131, 56)',

	// webos, lg
	lg: 'rgb(179, 40, 80)',
	webos: 'rgb(189, 70, 99)',
	webos_motor: '',

	// sky
	sky: 'rgb(77, 20, 101)',
	skyde: 'rgb(124,84,138)',
	sky_de: 'rgb(124,84,138)',
	skyit: 'rgb(148,117,156)',
	sky_it: 'rgb(148,117,156)',
	skyuk: 'rgb(101,52,119)',
	sky_uk: 'rgb(101,52,119)',

	// sling
	sling: 'rgb(72, 161, 219)',
	// sling_airtvplayer: 'rgb(72, 161, 219)',
	sling_androidtv: 'rgb(130, 183, 99)',
	// sling_comcast: 'rgb(105,150,173)',
	// sling_firetv: '',
	// sling_lg: '',
	// sling_samsung: '',
	// sling_vizio: '',

	// vizio
	vizio: 'rgb(180, 102, 53)',
	viziovia: 'rgb(140, 102, 53)',

	// tvos
	appletv: 'rgb(244, 244, 244)',
	tvos: 'rgb(244, 244, 244)',

	// telly TV
	tellytv: 'rgb(58, 58, 58)',

	// average
	average: 'rgb(46, 115, 232)',
	averagetop: 'rgb(80, 179, 200)',

	// access
	[Access.read]: '#81c784',
	[Access.create]: '#ffb74d',
	[Access.update]: '#ce93d8',
	[Access.delete]: '#e57373',
	fullAccess: '#ec4b4b',
	forbidden: '#E5E7EB',
};

export const CHART_COLORS: string[] = ['rgb(46, 115, 232)', 'rgb(80, 179, 200)', 'rgb(236,75,75)', 'rgb(255,203,2)', 'rgb(7,214,7)'];
