// modules
import get from 'lodash/get';

/**
 * @description Get error message from any error format
 * @example
 * getErrorMessage(false);
 * // ""
 *
 * getErrorMessage(true);
 * // "Unknown error"
 *
 * getErrorMessage({ message: "canceled" });
 * // "canceled"
 *
 * getErrorMessage({ status: { message: { en: "Invalid data" } } });
 * // "Invalid data"
 *
 * getErrorMessage({ data: ["Error on line 46", "Syntax Error on line 215"] });
 * // "Error on line 46, Syntax Error on line 215"
 *
 * getErrorMessage({ data: { server: "Internal server error", db: "DB could not process the request" } });
 * // "Internal server error, DB could not process the request"
 *
 * getErrorMessage({ error: { status: 500, message: "internal server error" } });
 * // "{ status: 500, message: "internal server error" }"
 */
export const getErrorMessage = (error?: unknown): string => {
	/** no error */
	if (!error) return '';

	/** boolean */
	const defaultMessage = 'Unknown error';
	if (typeof error === 'boolean') return defaultMessage;

	/** `error.response.data.message | error.data.message */
	const serverMessage = get(error, 'response.data.message') || get(error, 'data.message') || get(error, 'response.request.responseText');
	if (serverMessage === 'canceled') return '';
	if (serverMessage) return serverMessage;

	/** `error.message` */
	const axiosMessage = get(error, 'message');
	if (axiosMessage === 'canceled') return '';
	if (axiosMessage) return axiosMessage;

	/** BaaS format - `error.status.message.en` */
	const baasMessage = get(error, 'status.message.en') || get(error, 'status.message');
	if (baasMessage) return baasMessage;

	/** Error data - `error.data` */
	const errorData = get(error, 'data');
	if (errorData) {
		if (typeof errorData === 'string') return errorData;
		if (Array.isArray(errorData)) return (errorData as unknown[]).join(',');
		if (typeof errorData === 'object') return Object.values(errorData).join(',');
	}

	/** Server error - `error.error` */
	const serverError = get(error, 'error');
	if (serverError) return JSON.stringify(serverError);

	/** Error code */
	const code = JSON.stringify(get(error, 'code'));
	if (code) return code;

	return defaultMessage;
};
