// modules
import { QueryFunction, QueryKey, UseQueryResult, useQuery as useTanstackQuery } from '@tanstack/react-query';
import { useDebounceFn } from 'ahooks';
import axios from 'axios';
import omit from 'lodash/omit';
import { useCallback, useEffect, useMemo, useState } from 'react';
// types
import { IBaaSError } from 'service/types';
import { AnalyticsMetrics, IAnalyticsMetricsData, IAnalyticsParams } from 'types/analytics';
// utils
import { useAnalyticsConfigContext } from 'components/Analytics/AnalyticsConfigContext';
import { ANALYTICS_SECTIONS } from 'consts';
import { useErrorToast } from 'hooks/useErrorToast';
import { ValueOf } from 'types/general';
import { unpack } from './unpack';
import { useAnalyticsProxy } from './useAnalyticsProxy';

const analyticsInstance = axios.create({
	transformResponse: function (data, _, status?: number) {
		try {
			const isErr = !status || status > 300;
			const parsed = JSON.parse(data);
			if (isErr) throw parsed;
			return unpack(parsed);
		} catch (e) {
			return undefined;
		}
	},
});

export const useAnalyticsFiltersQuery = (
	section: ValueOf<typeof ANALYTICS_SECTIONS> | null,
	params?: IAnalyticsParams
): [(params?: IAnalyticsParams) => void, UseQueryResult<{ data?: IAnalyticsMetricsData }, Error>, () => void] => {
	const [enabled, setEnabled] = useState(false);
	const { baseURL } = useAnalyticsConfigContext();
	const [searchParams, setSearchParams] = useState<IAnalyticsParams>(() => params || {});
	const { normalizeData, normalizeParams } = useAnalyticsProxy();
	const queryKey = 'analytics-filters';
	const id = JSON.stringify(searchParams);
	const queryId = `${queryKey}_${id}`;

	const controller = useMemo(() => new AbortController(), []);

	const handleCancel = useCallback(() => controller.abort(), [controller]);

	const trigger = useCallback(
		(params?: IAnalyticsParams) => {
			if (params) setSearchParams(params);
			setEnabled(true);
		},
		[params]
	);

	const queryFn: QueryFunction<{ data: IAnalyticsMetricsData }, QueryKey> = useCallback(() => {
		const normalizedParams = normalizeParams(searchParams, {}, section);
		normalizedParams.metrics = searchParams.metrics || [
			AnalyticsMetrics.appname,
			AnalyticsMetrics.apptype,
			AnalyticsMetrics.platform,
			AnalyticsMetrics.country,
		];
		return analyticsInstance.post(`${baseURL}/data/metrics`, { ...normalizedParams }, { signal: controller.signal }).then((res) => {
			const normalized = normalizeData(res.data, 'in');
			return { ...res, data: normalized } as { data: IAnalyticsMetricsData };
		});
	}, [searchParams, controller, section, normalizeParams]);

	const response = useTanstackQuery<{ data: IAnalyticsMetricsData }, Error>([queryId], {
		queryFn,
		enabled: false,
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: 0,
		cacheTime: 1,
	});

	useEffect(() => {
		if (!enabled) return;
		response.refetch().then(() => setEnabled(false));
	}, [params, enabled]);

	useErrorToast(response.error as unknown as IBaaSError);

	return [trigger, response, handleCancel];
};

export function useAnalyticsQuery<TResponseData>(
	queryKey: string,
	section: ValueOf<typeof ANALYTICS_SECTIONS> | null,
	params?: IAnalyticsParams & { localCache?: boolean }
): [(props: { params?: IAnalyticsParams; metrics?: Record<string, string[]> }) => void, UseQueryResult<TResponseData, Error>, () => void] {
	const { baseURL } = useAnalyticsConfigContext();
	const [enabled, setEnabled] = useState(false);
	const [searchParams, setSearchParams] = useState<IAnalyticsParams>(() => omit(params || {}, 'localCache'));
	const [metrics, setMetrics] = useState<Record<string, string[]>>({});
	const { normalizeData, normalizeParams } = useAnalyticsProxy();
	const id = JSON.stringify(searchParams);
	const queryId = `${queryKey}_${id}`;

	const controller = useMemo(() => new AbortController(), []);

	const handleCancel = useCallback(() => {
		controller.abort();
	}, [controller]);

	const { run: trigger } = useDebounceFn(
		({ params, metrics }: { params?: IAnalyticsParams; metrics?: Record<string, string[]> }) => {
			if (params) setSearchParams(params);
			if (metrics) setMetrics(metrics);
			setEnabled(true);
		},
		{ leading: true, wait: 1000 }
	);

	const queryFn: QueryFunction<TResponseData, QueryKey> = useCallback(() => {
		const normalizedParams = normalizeParams(searchParams, metrics, section, true);
		const res = analyticsInstance.post(`${baseURL}/data/get`, normalizedParams, { signal: controller.signal }).then((res) => {
			const normalized = normalizeData(res.data, 'in');
			return { ...res, data: normalized } as TResponseData;
		});

		return res as TResponseData;
	}, [searchParams, metrics, normalizeParams, normalizeData, controller, section]);

	const response = useTanstackQuery<TResponseData, Error>([queryId], {
		queryFn,
		enabled,
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: params?.localCache ? Infinity : 0,
		cacheTime: params?.localCache ? Infinity : 0,
	});

	useErrorToast(response.error as unknown as IBaaSError);

	return [trigger, response, handleCancel];
}

export function useAnalyticsMultiQuery<TResponseData>(
	queryKey: string,
	section: ValueOf<typeof ANALYTICS_SECTIONS> | null,
	params?: Array<IAnalyticsParams & { localCache?: boolean }>
): [(props: { params?: IAnalyticsParams[]; metrics?: Record<string, string[]> }) => void, UseQueryResult<TResponseData[], Error>, () => void] {
	const { baseURL } = useAnalyticsConfigContext();
	const [enabled, setEnabled] = useState(false);
	const [searchParams, setSearchParams] = useState<IAnalyticsParams[]>(() => params || []);
	const [metrics, setMetrics] = useState<Record<string, string[]>>({});
	const { normalizeData, normalizeParams } = useAnalyticsProxy();
	const id = JSON.stringify(searchParams);
	const queryId = `${queryKey}_${id}`;

	const controller = useMemo(() => new AbortController(), []);

	const handleCancel = useCallback(() => controller.abort(), [controller]);

	const { run: trigger } = useDebounceFn(
		({ params, metrics }: { params?: IAnalyticsParams[]; metrics?: Record<string, string[]> }) => {
			if (params) setSearchParams(params);
			if (metrics) setMetrics(metrics);

			setEnabled(true);
		},
		{ leading: true, wait: 1000 }
	);

	const queryFn: QueryFunction<TResponseData[], QueryKey> = useCallback(() => {
		const requests = searchParams.map((params) => {
			const normalizedParams = normalizeParams(params, metrics, section, true);
			return analyticsInstance.post(`${baseURL}/data/get`, normalizedParams, { signal: controller.signal }).then((res) => {
				const normalized = normalizeData(res.data, 'in');
				return { ...res, data: normalized };
			});
		});

		return Promise.all(requests) as Promise<TResponseData[]>;
	}, [searchParams, metrics, controller.signal, normalizeParams, section]);

	const response = useTanstackQuery<TResponseData[], Error>([queryId], {
		queryFn,
		enabled: false,
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: 0,
		cacheTime: 0,
	});

	useEffect(() => {
		if (!enabled) return;
		response.refetch().then(() => setEnabled(false));
	}, [params, enabled, metrics]);

	useErrorToast(response.error as unknown as IBaaSError);

	return [trigger, response, handleCancel];
}

export function useAnalyticsCohortQuery<TResponseData>(
	queryKey: string,
	section: ValueOf<typeof ANALYTICS_SECTIONS> | null,
	params?: IAnalyticsParams
): [(props: { params?: IAnalyticsParams; metrics?: Record<string, string[]> }) => void, UseQueryResult<TResponseData, Error>, () => void] {
	const { baseURL } = useAnalyticsConfigContext();
	const [enabled, setEnabled] = useState(false);
	const [searchParams, setSearchParams] = useState<IAnalyticsParams>(() => params || {});
	const [metrics, setMetrics] = useState<Record<string, string[]>>({});
	const { normalizeParams } = useAnalyticsProxy();
	const id = JSON.stringify(searchParams);
	const queryId = `${queryKey}_${id}`;

	const controller = useMemo(() => new AbortController(), []);

	const handleCancel = useCallback(() => controller.abort(), [controller]);

	const { run: trigger } = useDebounceFn(
		({ params, metrics }: { params?: IAnalyticsParams; metrics?: Record<string, string[]> }) => {
			if (params) setSearchParams(params);
			if (metrics) setMetrics(metrics);
			setEnabled(true);
		},
		{ leading: true, wait: 1000 }
	);

	const queryFn: QueryFunction<TResponseData, QueryKey> = useCallback(() => {
		const normalizedParams = normalizeParams(searchParams, metrics, section, true);
		return analyticsInstance.post(`${baseURL}/data/cohort`, normalizedParams, { signal: controller.signal });
	}, [searchParams, metrics, normalizeParams, controller, section]);

	const response = useTanstackQuery<TResponseData, Error>([queryId], {
		queryFn,
		enabled: false,
		refetchOnWindowFocus: false,
		retry: false,
		staleTime: 0,
		cacheTime: 0,
	});

	useEffect(() => {
		if (!enabled) return;
		response.refetch().then(() => setEnabled(false));
	}, [params, enabled, metrics]);

	useErrorToast(response.error as unknown as IBaaSError);

	return [trigger, response, handleCancel];
}
