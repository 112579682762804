import { IEntityConfig } from 'types/entity';
import { languages } from './languages';

export const demoConfig: IEntityConfig = {
	key: 'demo',
	form: {
		submitOptions: {
			endpoint: {
				create: '',
				edit: '',
			},
		},
		fields: [
			{
				key: 'id',
				disabled: true,
				style: {
					fullRow: true,
					width: '50%',
				},
				label: 'Disabled (Text Input)',
				type: 'text',
			},
			{
				key: 'uid',
				type: 'text',
				label: 'Text Input',
				style: {
					fullRow: true,
					width: '50%',
				},
				validation: {
					min: 2,
				},
			},
			{
				key: 'textarea',
				validation: {
					required: true,
				},
				style: {
					fullRow: true,
					width: '50%',
				},
				label: 'Textarea',
				type: 'textarea',
			},
			{
				key: 'preview',
				type: 'image-preview',
				label: 'Image Preview',
				referenceKey: 'image_upload',
				style: {
					fullRow: true,
					width: 200,
					height: 200,
				},
			},
			{
				key: 'image_upload',
				type: 'image-upload',
				label: 'Image Upload',
			},
			{
				key: 'switch',
				type: 'switch',
				label: 'Switch Input',
				style: {
					fullRow: true,
					width: '50%',
				},
			},
			{
				key: 'checkbox',
				type: 'checkbox',
				label: 'Checkbox Input',
				style: {
					fullRow: true,
					width: '50%',
				},
			},
			{
				key: 'radio',
				type: 'radio',
				label: 'Radio Input',
				style: {
					fullRow: true,
					width: '50%',
				},
				direction: 'row',
				validation: { required: true },
				items: [
					{ label: 'One', value: 'one' },
					{ label: 'Two', value: 'two' },
					{ label: 'Three', value: 3 },
				],
			},
			{
				key: 'multi-dropdown',
				type: 'multi-dropdown',
				label: 'Multi Dropdown',
				style: {
					fullRow: true,
					width: '50%',
				},
				validation: {
					max: 3,
				},
				options: languages.map((value) => ({
					label: value.toUpperCase(),
					value,
				})),
			},
			{
				type: 'section',
				label: 'Date Inputs',
				style: {
					fullRow: true,
				},
				children: [
					{
						type: 'date',
						key: 'date',
						label: 'Date Input',
						validation: { required: true },
					},
					{
						type: 'time',
						key: 'time',
						label: 'Time Input',
						validation: { required: true },
					},
					{
						type: 'date-time',
						key: 'date_time',
						label: 'Date-Time Input',
						validation: { required: true },
					},
					{
						type: 'date-range',
						key: 'date_range',
						label: 'Date Range Input',
						validation: { required: true },
					},
					{
						type: 'date-time-range',
						key: 'date_time_range',
						label: 'Date-Time Range Input',
						validation: { required: true },
					},
				],
			},
			{
				type: 'section',
				label: 'Section',
				style: {
					fullRow: true,
				},
				children: [
					{
						type: 'array',
						key: 'name',
						label: 'Array Input',
						child: {
							type: 'multi',
							label: '',
							children: [
								{
									key: 'key',
									type: 'dropdown',
									options: languages.map((value) => ({
										label: value.toUpperCase(),
										value,
									})),
									label: 'Dropdown',
								},
								{
									key: 'value',
									type: 'text',
									label: 'Text Input',
								},
							],
						},
					},
				],
			},
			{
				type: 'tab',
				label: 'JSON',
				children: [
					{
						type: 'section',
						style: {
							fullRow: true,
						},
						children: [
							{
								type: 'json',
								label: '',
								key: 'config',
								isRestOfContent: true,
							},
						],
					},
				],
			},
		],
	},
};
