// utils
import { getAnalyticsNow } from 'utils/getAnalyticsNow';
// types
import { IDatePreset } from 'types/general';

export const defaultComparisonPreset = 'Last 30 Days';

export const comparisonPresets = [
	{
		label: 'Yesterday',
		value: [getAnalyticsNow().subtract(1, 'day').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(2, 'day').startOf('day'), getAnalyticsNow().subtract(2, 'day').startOf('day')],
	},
	{
		label: 'Last 7 Days',
		value: [getAnalyticsNow().subtract(7, 'day').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(14, 'day').startOf('day'), getAnalyticsNow().subtract(8, 'day').startOf('day')],
	},
	{
		label: 'Last 14 Days',
		value: [getAnalyticsNow().subtract(14, 'day').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(28, 'day').startOf('day'), getAnalyticsNow().subtract(15, 'day').startOf('day')],
	},
	{
		label: 'Last 30 Days',
		value: [getAnalyticsNow().subtract(30, 'day').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(60, 'day').startOf('day'), getAnalyticsNow().subtract(31, 'day').startOf('day')],
	},
	{
		label: 'This Week',
		value: [getAnalyticsNow().subtract(1, 'day').startOf('week').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [
			getAnalyticsNow().startOf('week').startOf('day').subtract(1, 'week').startOf('day'),
			getAnalyticsNow().subtract(8, 'day').startOf('day'),
		],
	},
	{
		label: 'Last Week',
		value: [getAnalyticsNow().subtract(8, 'day').startOf('week'), getAnalyticsNow().subtract(8, 'day').startOf('week').add(6, 'day').startOf('day')],
		previousPeriod: [
			getAnalyticsNow().subtract(15, 'day').startOf('week'),
			getAnalyticsNow().subtract(15, 'day').startOf('week').add(6, 'day').startOf('day'),
		],
	},
	{
		label: 'This Month',
		value: [getAnalyticsNow().startOf('month').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [
			getAnalyticsNow().startOf('month').startOf('day').subtract(1, 'month'),
			getAnalyticsNow().startOf('month').startOf('day').subtract(1, 'month').endOf('month').startOf('day'),
		],
	},
	{
		label: 'Last Month',
		value: [getAnalyticsNow().subtract(1, 'month').startOf('month'), getAnalyticsNow().subtract(1, 'month').endOf('month').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(2, 'month').startOf('month'), getAnalyticsNow().subtract(2, 'month').endOf('month').startOf('day')],
	},
	{
		label: 'Last 3 Months to Date',
		value: [getAnalyticsNow().subtract(3, 'month').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(6, 'month').startOf('day'), getAnalyticsNow().subtract(1, 'day').subtract(3, 'month').startOf('day')],
	},
	{
		label: 'This Quarter',
		value: [getAnalyticsNow().startOf('quarter').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [
			getAnalyticsNow().subtract(1, 'quarter').startOf('quarter'),
			getAnalyticsNow().subtract(1, 'quarter').endOf('quarter').startOf('day'),
		],
	},
	{
		label: 'Last Quarter',
		value: [getAnalyticsNow().subtract(1, 'quarter').startOf('quarter'), getAnalyticsNow().subtract(1, 'quarter').endOf('quarter').startOf('day')],
		previousPeriod: [
			getAnalyticsNow().subtract(2, 'quarter').startOf('quarter'),
			getAnalyticsNow().subtract(2, 'quarter').endOf('quarter').startOf('day'),
		],
	},
	{
		label: 'Last 6 Months',
		value: [
			getAnalyticsNow().startOf('month').subtract(1, 'day').startOf('month').subtract(5, 'month'),
			getAnalyticsNow().startOf('month').subtract(1, 'day'),
		],
		previousPeriod: [
			getAnalyticsNow().startOf('month').subtract(1, 'day').startOf('month').subtract(11, 'month'),
			getAnalyticsNow().startOf('month').subtract(1, 'day').subtract(6, 'months'),
		],
	},
	{
		label: 'Last 6 Months to Date',
		value: [getAnalyticsNow().subtract(6, 'month').startOf('day'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [
			getAnalyticsNow().subtract(12, 'month').startOf('day'),
			getAnalyticsNow().subtract(1, 'day').subtract(6, 'month').startOf('day'),
		],
	},
	{
		label: 'Last Year',
		value: [getAnalyticsNow().subtract(1, 'year').startOf('year'), getAnalyticsNow().subtract(1, 'year').endOf('year').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(2, 'year').startOf('year'), getAnalyticsNow().subtract(2, 'year').endOf('year').startOf('day')],
	},
	{
		label: 'This Year',
		value: [getAnalyticsNow().subtract(1, 'day').startOf('year'), getAnalyticsNow().subtract(1, 'day').startOf('day')],
		previousPeriod: [getAnalyticsNow().subtract(1, 'year').startOf('year'), getAnalyticsNow().subtract(1, 'year').endOf('year').startOf('day')],
	},
].filter(Boolean) as IDatePreset[];
