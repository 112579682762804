// modules
import { CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ConfigProvider, ThemeConfig, theme as antdTheme } from 'antd';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// components
import { PerformanceWindow } from 'components/_PerformanceWindow';
import { ErrorHandlerProvider } from './context/ErrorContext';
// constants
import { router } from 'router';
// utils
import { useUserConfig } from 'hooks/useUserConfig';
import { actions } from 'store/actions';
import { useConfig } from 'store/slices/config';
import { createTheme } from 'theme';
import { updateSession } from 'utils/checkSession';
import 'utils/logger';
// styles
import 'react-toastify/dist/ReactToastify.css';

/** @description prevents double call of `updateSession` */
let isUpdating = false;

function App() {
	const { config } = useUserConfig();
	const { sessionStartDate } = useConfig();
	const dispatch = useDispatch();
	const isIframe = window.location.search.includes('iframe=true');
	const theme = createTheme(config?.theme);
	const isDark = config?.theme?.mode === 'dark';

	const watchSessionValidity = useCallback(() => {
		if (isUpdating) return;
		isUpdating = true;
		updateSession(sessionStartDate);
		setTimeout(() => (isUpdating = false), 30000);
	}, [sessionStartDate]);

	useEffect(() => {
		if (!isIframe) return;
		dispatch(actions.config.setIframe(true));
	}, [isIframe]);

	useEffect(() => {
		const interval = setInterval(watchSessionValidity, 1000 * 60);
		window.addEventListener('focus', watchSessionValidity);
		return () => {
			window.removeEventListener('focus', watchSessionValidity);
			clearInterval(interval);
		};
	}, [watchSessionValidity]);

	const antDesignTheme: ThemeConfig = useMemo(
		() =>
			isDark
				? {
						algorithm: antdTheme.darkAlgorithm,
						components: {
							DatePicker: {
								colorPrimaryHover: '#D1D5DB',
								controlOutlineWidth: 0,
								colorPrimary: theme.palette.primary.main,
							},
						},
						token: {
							colorPrimary: theme.palette.primary.dark,
						},
				  }
				: {
						components: {
							DatePicker: {
								colorPrimary: theme.palette.primary.main,
								controlOutlineWidth: 0,
								colorPrimaryHover: '#374151',
							},
						},
						token: {
							colorPrimary: theme.palette.primary.light,
						},
				  },
		[isDark]
	);

	const loader = <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />;

	return (
		<ThemeProvider theme={theme}>
			<ErrorHandlerProvider>
				<Suspense fallback={loader}>
					<ConfigProvider theme={antDesignTheme}>
						<CssBaseline enableColorScheme />
						<RouterProvider router={router} hydrateFallback={loader} />
						<PerformanceWindow limit={30} />
						<ToastContainer rtl={config?.theme?.direction === 'rtl'} theme={config?.theme?.mode} closeOnClick={false} draggable={false} />
					</ConfigProvider>
				</Suspense>
			</ErrorHandlerProvider>
		</ThemeProvider>
	);
}

export default App;
