// modules
import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
// utils
import { useAnalyticsProxy } from 'service/analytics/useAnalyticsProxy';
import { getUserSecurityGroupsData } from 'service/security';
import { useSecurity } from './useSecurity';
// types
import { AnalyticsConfig } from 'store/types';
import { IAnalyticsParams } from 'types/analytics';
import { ISecurityPermissions } from 'types/security';

export const useMergedPermissions = () => {
	const { parseConfig } = useAnalyticsProxy();
	const { mergePermissions } = useSecurity();
	const [loading, setLoading] = useState(false);

	/**
	 * @description
	 * 1. Request user's security groups data by ids
	 * 2. Parse user's and groups' permissions
	 * 3. Merge and return user's and groups' permissions
	 */
	const getMergedPermissionsAsync = useCallback(
		async (certificate: string, userPermissions: ISecurityPermissions | undefined) => {
			setLoading(true);
			const securityGroups = await getUserSecurityGroupsData(certificate);

			const parsedGroupPermissions = securityGroups.reduce<Array<ISecurityPermissions<IAnalyticsParams>>>((acc, group) => {
				if (isEmpty(group)) return acc;
				const result = group.permissions || ({} as ISecurityPermissions<IAnalyticsParams>);
				if (group.permissions?.analytics?.tabsOrder && userPermissions?.analytics?.tabsOrder?.length) {
					result.analytics = { ...group.permissions.analytics, tabsOrder: userPermissions?.analytics?.tabsOrder };
				}

				const { analytics, charts, tables } = parseConfig({
					analytics: result.analytics as AnalyticsConfig,
					charts: group.permissions?.charts,
					tables: group.permissions?.tables,
				});
				acc.push({ ...result, analytics, charts, tables });
				return acc;
			}, []);
			const { analytics, charts, tables } = parseConfig({
				analytics: userPermissions?.analytics,
				charts: userPermissions?.charts,
				tables: userPermissions?.tables,
			});
			const parsedUserPermissions = { ...userPermissions, analytics, charts, tables } as ISecurityPermissions<IAnalyticsParams>;
			const groupsMergedPermissions = mergePermissions('extend', ...parsedGroupPermissions);
			const mergedPermissions = mergePermissions('limit', groupsMergedPermissions, parsedUserPermissions);
			setLoading(false);
			return mergedPermissions;
		},
		[parseConfig, mergePermissions]
	);

	return { loading, getMergedPermissionsAsync };
};
