// modules
import { SvgIconComponent } from '@mui/icons-material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded';
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import GroupIcon from '@mui/icons-material/Group';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import LiveTvRoundedIcon from '@mui/icons-material/LiveTvRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import ViewComfyRoundedIcon from '@mui/icons-material/ViewComfyRounded';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import AttachEmailRoundedIcon from '@mui/icons-material/AttachEmailRounded';
import { Chip, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
// icons
import { ReactComponent as ActiTime } from 'assets/svg/actitime.svg';
import { ReactComponent as Bitbucket } from 'assets/svg/bitbucket.svg';
import { ReactComponent as Jira } from 'assets/svg/jira.svg';
import { ReactComponent as Timetastic } from 'assets/svg/timetastic.svg';
// types
import { ANALYTICS_SECTIONS, DEPARTMENTS, path, PROJECT_NAME, TEAM_STATUS_TYPES } from 'consts';
import { ISidebarConfig } from 'types/general';

const ActiTimeIcon = (props: SvgIconProps) => <SvgIcon {...props} component={ActiTime} inheritViewBox />;
const BitbucketIcon = (props: SvgIconProps) => <SvgIcon {...props} component={Bitbucket} inheritViewBox />;
const JiraIcon = (props: SvgIconProps) => <SvgIcon {...props} component={Jira} inheritViewBox />;
const TimeTastic = (props: SvgIconProps) => <SvgIcon {...props} component={Timetastic} sx={{ color: 'neutral.400' }} inheritViewBox />;

const chip = (
	<Chip
		color="info"
		sx={{ height: 15, borderRadius: '4px' }}
		label={
			<Typography
				sx={{
					fontSize: 10,
					fontWeight: '700',
					color: 'neutral.100',
				}}
			>
				SOON
			</Typography>
		}
		size="small"
	/>
);

const getAnalyticsPressChildren = (pathPrefix: string, id: string) =>
	Object.keys(omit(ANALYTICS_SECTIONS, 'custom')).map((key) => {
		const label = ANALYTICS_SECTIONS[key as keyof typeof ANALYTICS_SECTIONS];
		return {
			title: startCase(label),
			path: `${pathPrefix}#${label}`,
			pageId: id,
		};
	});

export const sidebar: ISidebarConfig = {
	sections: [
		{
			title: 'Service',
			items: [
				{
					title: 'Platforms',
					path: path.platformOverview,
					Icon: LiveTvRoundedIcon,
					pageId: 'platforms',
				},
				{
					title: 'Applications',
					path: path.applications,
					Icon: ExtensionRoundedIcon,
					pageId: 'applications',
				},
				{
					title: 'Products',
					path: path.productOverview,
					Icon: CategoryRoundedIcon,
					pageId: 'products',
				},
				{
					title: 'Countries',
					path: '',
					/** @TODO - double-check when countries implemented */
					pageId: 'countries',
					Icon: PublicRoundedIcon,
					chip,
				},
				{
					title: 'PW Analytics',
					path: '',
					Icon: AnalyticsIcon,
					children: [
						{
							title: 'Reports',
							path: path.analyticsReports,
							Icon: ViewComfyRoundedIcon,
							pageId: 'analytics-reports',
							pressChildren: getAnalyticsPressChildren(path.analyticsReports, 'analytics-reports'),
						},
						{
							title: 'Reports (SANDBOX)',
							path: path.analyticsSandboxReports,
							Icon: ViewComfyRoundedIcon,
							pageId: 'analytics-sandbox-reports',
							pressChildren: getAnalyticsPressChildren(path.analyticsSandboxReports, 'analytics-sandbox-reports'),
						},
						{
							title: 'Custom Reports',
							path: path.analyticsCustomReports,
							Icon: WidgetsRoundedIcon,
							pageId: 'analytics-custom-reports',
						},
						{
							title: 'Scheduled Reports',
							path: path.analyticsScheduledReports,
							Icon: ScheduleSendRoundedIcon,
							pageId: 'analytics-scheduled-reports',
						},
						{
							title: 'Settings',
							path: path.analyticsSettings,
							Icon: SettingsIcon,
							pageId: 'analytics-settings',
						},
					],
				},
			],
		},
		{
			title: 'Tools',
			items: [
				{
					title: 'CDN Invalidator',
					path: '',
					Icon: LanguageRoundedIcon,
					children: [
						{
							title: 'Monitoring',
							path: path.cdnMonitoring,
							Icon: DataUsageRoundedIcon,
							pageId: 'cdn-monitoring',
						},
						{
							title: 'Settings',
							path: '',
							chip,
							/** @TODO - change to cdn-monitoring when implemented */
							pageId: 'cdn-monitoring-settings',
							Icon: SettingsIcon,
						},
					],
				},
				{
					title: 'Tools',
					path: path.toolsOverview,
					Icon: SettingsIcon,
					pageId: 'cms-tools',
				},
			],
		},
		{
			title: 'Organization',
			items: [
				{
					title: 'Teams',
					path: '',
					Icon: TodayRoundedIcon,
					children: [
						{
							title: 'My Reports',
							path: `${path.teamStatus}#${TEAM_STATUS_TYPES.own}`,
							pageId: 'team-status',
						},
						{
							title: 'Team Reports',
							path: `${path.teamStatus}#${TEAM_STATUS_TYPES.team}`,
							pageId: 'team-status',
						},
					],
				},
				{
					title: DEPARTMENTS,
					path: '',
					Icon: BusinessRoundedIcon,
					/** @description children are generated based on offices user has access to (SidebarSection.tsx) */
					children: [],
				},
				{
					title: 'Database',
					path: '',
					Icon: StorageRoundedIcon,
					children: [
						{
							title: 'Agreements',
							path: path.contracts,
							pageId: 'contracts',
							Icon: FileCopyRoundedIcon,
						},
						{
							title: 'Settings',
							path: path.databaseSettings,
							Icon: SettingsIcon,
							pageId: 'database',
						},
					],
				},
			],
		},
		{
			title: 'Important Links',
			items: [
				{
					title: 'Jira',
					path: 'https://transgaming.atlassian.net/jira/projects',
					isExternal: true,
					Icon: JiraIcon as SvgIconComponent,
				},
				{
					title: 'Bitbucket',
					path: 'https://bitbucket.org/transgaminginc/workspace/overview',
					isExternal: true,
					Icon: BitbucketIcon as SvgIconComponent,
				},
				{
					title: 'PlayWorks WIKI',
					Icon: AutoStoriesRoundedIcon,
					path: 'https://transgaming.atlassian.net/wiki/spaces/HTML5/overview?homepageId=1081347',
					isExternal: true,
				},
				{
					title: 'PW & GA Design',
					Icon: BorderAllRoundedIcon,
					path: 'https://docs.google.com/spreadsheets/d/1Z_-h6a3h7UawOhvW3htSQ5OMG6ycixStc5OnkZOoAqE/edit?usp=sharing',
					isExternal: true,
				},
				{
					title: 'TimeTastic',
					path: 'https://app.timetastic.co.uk/wallchart',
					isExternal: true,
					Icon: TimeTastic as SvgIconComponent,
				},
				{
					title: 'ActiTime',
					path: 'http://reznik.asuscomm.com:2023/tasks/otasklist.do',
					isExternal: true,
					Icon: ActiTimeIcon as SvgIconComponent,
				},
			],
		},
		{
			title: 'Admin',
			items: [
				{
					title: 'Users',
					path: path.users,
					Icon: GroupIcon,
					pageId: 'users',
				},
				{
					title: 'Security Groups',
					path: path.securityGroups,
					Icon: SecurityIcon,
					pageId: 'security-groups',
				},
				{
					title: 'Email Templates',
					path: path.emailTemplate,
					Icon: AttachEmailRoundedIcon,
					pageId: 'template',
				},
				{
					title: 'BaaS',
					path: '',
					Icon: DnsRoundedIcon,
					children: [
						{
							title: 'Monitoring',
							path: path.statistics,
							Icon: DataUsageRoundedIcon,
							pageId: 'baas-monitoring',
						},
						{
							title: 'Reboot',
							path: path.baasReboot,
							Icon: SettingsIcon,
							pageId: 'baas-reboot',
						},
					],
				},
				{
					title: PROJECT_NAME,
					path: '',
					Icon: ComputerRoundedIcon,
					children: [
						{
							title: 'Settings',
							path: path.cmsConfig,
							Icon: SettingsIcon,
							children: [
								{
									title: 'Hosting',
									path: path.hosting,
									Icon: SettingsIcon,
									pageId: 'hosting',
								},
								{
									title: 'Assets',
									path: path.assets,
									Icon: SettingsIcon,
									pageId: 'assets',
								},
								{
									title: 'Configurations',
									path: path.configurations,
									Icon: SettingsIcon,
									pageId: 'cms-configurations',
								},
							],
						},
					],
				},
			],
		},
	],
};
