/**
 * @example
 * _getExistingValue('key1', { key1: 1 });
 * // Returns: { exists: true, value: 1 }
 * _getExistingValue('key1', { key1: false });
 * // Returns: { exists: true, value: false }
 * _getExistingValue('key1', { key1: undefined });
 * // Returns: { exists: true, value: undefined }
 * _getExistingValue('key2', { key1: "John Doe" });
 * // Returns: { exists: false, value: undefined }
 */
const _getExistingValue = (key: string | number, source: Record<string | number, unknown>) => {
	let value;
	const exists = Object.hasOwnProperty.call(source, key);
	if (exists) value = source[key];
	return { exists, value };
};

/**
 * @description
 * - paste a source value to an existing key value in target object
 * - the last provided source is most prioritized
 * - will skip a source if object structure is different
 * @example
 * mergeObjectsDeep({ user: { name: "John", age: 18 } }, { user: { name: "John", age: 24, sex: "male" } })
 * // Result : { user: { name: "John", age: 24 } }
 *
 * mergeObjectsDeep({ user: { name: "John", age: 18 }, siblings: [{ name: "Jack", age: 14 }] }, { user: { name: "John", age: 24, sex: "male" }, siblings: [{ name: "Jane", age: 18, sex: "female" }] })
 * // Result : { user: { name: "John", age: 24 }, siblings: [{ name: "Jane", age: 18 }] }
 *
 * mergeObjectsDeep({ user: { name: "John", age: 18 } }, { user: null })
 * // Result : { user: { name: "John", age: 18 } }
 *
 * mergeObjectsDeep({ user: { name: "John", age: 18 } }, { user: { name: "John", age: null } })
 * // Result : { user: { name: "John", age: null } }
 *
 * mergeObjectsDeep({ user: { name: "John", age: 18 } }, undefined)
 * // Result : { user: { name: "John", age: 18 } }
 *
 * mergeObjectsDeep({ users: ["John", "Jack"] }, { users: ["Jane", "July"] })
 * // Result : { users: ["Jane", "July"] }
 *
 * mergeObjectsDeep({ users: [{ name: "John", age: 12 }] }, { users: [{ name: "John", age: 14 }] })
 * // Result : { users: [{ name: "John", age: 14 }] }
 */
export const mergeObjectsDeep = (target: Record<string | number, unknown>, ...sources: Array<Record<string | number, unknown> | undefined>) => {
	const sourcesReversed = sources.reverse().filter(Boolean) as Array<Record<string | number, unknown>>;

	return Object.keys(target).reduce<Record<string | number, unknown>>((acc, key) => {
		const initialValue = target[key];
		let result: unknown = initialValue;

		if (typeof initialValue === 'object' && initialValue !== null) {
			switch (true) {
				case Array.isArray(initialValue):
					{
						const isArrayOfObjects = typeof (initialValue as unknown[]).find((i) => i) === 'object';
						if (isArrayOfObjects) {
							const initArr = initialValue as Array<Record<string | number, unknown>>;
							const sourcesArrays = sources.reduce<Record<string | number, unknown>[][]>((acc, source) => {
								const sourceValue = (source && _getExistingValue(key, source).value) || [];
								if (Array.isArray(sourceValue) && sourceValue.length === initArr.length) acc.push(sourceValue);
								return acc;
							}, []);
							result = [];
							result = initArr.map((obj: Record<string | number, unknown>, idx: number) =>
								mergeObjectsDeep(obj, ...sourcesArrays.map((sourceArr) => sourceArr?.[idx] || {}))
							);
						} else {
							const sourceResult = sourcesReversed.reduce((acc, source) => (acc.exists ? acc : _getExistingValue(key, source)), {
								exists: false,
								value: undefined,
							});
							if (sourceResult.exists) result = sourceResult.value;
							else result = initialValue;
						}
					}
					break;
				default: {
					const nextSourceObjects = sourcesReversed.reduce<Record<string | number, unknown>[]>((acc, sourceObj) => {
						const sourceValue = sourceObj[key];
						if (typeof sourceValue === 'object' && typeof sourceValue !== null) acc.push(sourceValue as Record<string | number, unknown>);
						return acc;
					}, []);
					result = mergeObjectsDeep(initialValue as Record<string | number, unknown>, ...nextSourceObjects);
				}
			}
		} else {
			const sourceResult = sourcesReversed.reduce((acc, source) => (acc.exists ? acc : _getExistingValue(key, source)), {
				exists: false,
				value: undefined,
			});
			if (sourceResult.exists) result = sourceResult.value;
			else result = initialValue;
		}

		acc[key] = result;
		return acc;
	}, {});
};
