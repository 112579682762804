// modules
import { Box, CircularProgress, Container, Paper } from '@mui/material';
import { FC } from 'react';
// components
import { Overlay } from './Overlay';

export const LoadingModal: FC = () => {
	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 5000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Overlay />
			<Container sx={{ width: 700 }}>
				<Paper
					elevation={12}
					sx={{
						position: 'relative',
						height: 260,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress />
				</Paper>
			</Container>
		</Box>
	);
};
