import { FC, useCallback, useState } from 'react';
import { Box, Collapse, Typography } from '@mui/material';
// components
import { IPromptModalProps, PromptModal } from './PromptModal';
import { LoadingModal } from './LoadingModal';
import { ISuccessModalProps, SuccessModal } from './SuccessModal';
import { ErrorModal, IErrorModalProps } from './ErrorModal';
// types
import { IBaaSError } from 'service/types';
// utils
import { getErrorMessage } from 'utils/getErrorMessage';
import { useIntervalLoading } from 'hooks/useIntervalLoading';

interface IActionModalProps extends IPromptModalProps {
	errorProps: Omit<IErrorModalProps, 'onClose' | 'closeButtonLabel'>;
	onConfirm: () => Promise<{ data?: boolean; error?: IBaaSError }>;
	successProps: Omit<ISuccessModalProps, 'onClose' | 'closeButtonLabel'>;
}

enum Step {
	'prompt',
	'loading',
	'success',
	'error',
}

export const ActionModal: FC<IActionModalProps> = (props) => {
	const { errorProps, onConfirm, successProps, ...rest } = props;
	const [openErrDetails, setOpenErr] = useState(false);
	const [step, setStep] = useState(Step.prompt);
	const [error, setError] = useState<string | null>(null);
	const [fullError, setFullError] = useState<IBaaSError | undefined>();
	const isLoading = useIntervalLoading(step === Step.loading, { wait: 800, initial: false });

	const toggleErrOpen = useCallback(() => setOpenErr(!openErrDetails), [openErrDetails]);

	const renderErrorDetails = () => {
		if (!fullError) return null;
		return (
			<Box sx={{ border: 1, borderRadius: 1, mt: 2, overflow: 'hidden' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						px: 1,
						py: 0.5,
						cursor: 'pointer',
						'&:hover': { backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.700' : 'neutral.100') },
					}}
					onClick={toggleErrOpen}
				>
					<Typography fontSize={14} color="text.secondary">
						Error Details
					</Typography>
					<Typography variant="caption">{openErrDetails ? 'Hide' : 'Show'}</Typography>
				</Box>
				<Collapse in={openErrDetails} unmountOnExit>
					<Box sx={{ p: 1, width: '100%', display: 'block', backgroundColor: 'neutral.900', color: 'neutral.100' }} component="code">
						{JSON.stringify(fullError)}
					</Box>
				</Collapse>
			</Box>
		);
	};

	const errorBody = (
		<>
			{/** default provided error body */}
			<Typography component="div">{errorProps.body}</Typography>
			{error && (
				<Typography component="div" sx={{ mt: 1, fontWeight: 600, color: 'text.primary' }}>
					{error}
				</Typography>
			)}
			{renderErrorDetails()}
		</>
	);

	const handlePromptConfirm = useCallback(() => {
		setStep(Step.loading);
		onConfirm()
			.then(({ error }) => {
				if (error) {
					const msg = getErrorMessage(error);
					setError(msg);
					setFullError(error);
					setStep(Step.error);
				} else {
					setStep(Step.success);
				}
			})
			.catch((err: IBaaSError) => {
				const msg = getErrorMessage(err);
				setError(msg);
				setFullError(err);
				setStep(Step.error);
			});
	}, []);

	switch (true) {
		case isLoading || step === Step.loading:
			return <LoadingModal />;
		case step === Step.prompt:
			return <PromptModal {...rest} onConfirm={handlePromptConfirm} />;
		case step === Step.success:
			return <SuccessModal {...successProps} onClose={rest.onClose} closeButtonLabel={rest.closeButtonLabel} />;
		case step === Step.error:
			return (
				<ErrorModal {...errorProps} onClose={rest.onClose} closeButtonLabel={rest.closeButtonLabel} body={errorBody} onRepeat={handlePromptConfirm} />
			);
		default:
			return null;
	}
};
