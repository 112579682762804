// modules
import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import pick from 'lodash/pick';
// utils
import { useUserConfig } from 'hooks/useUserConfig';
import { useQueryOne } from 'service';
import { useAuth } from 'store/slices/auth';
import { useIntervalLoading } from './useIntervalLoading';
import { useMergedPermissions } from './useMergedPermissions';
// types
import { IAnalyticsHash, IAnalyticsParams } from 'types/analytics';
import { IUser } from 'types/login';
import { ISecurityPermissions } from 'types/security';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'consts';

export const useSharedConfig = () => {
	const { search } = useLocation();
	const { isAuthorized } = useAuth();
	const { save, setUserProxyRules, changeDateTimeFormat, loading } = useUserConfig();
	const { getMergedPermissionsAsync, loading: permissionsLoading } = useMergedPermissions();
	const queryParams = search ? Object.fromEntries(new URLSearchParams(search)) : {};
	const hash = queryParams?.hash;
	const [getHashData, hashResponse] = useQueryOne<{ id: string }, IAnalyticsHash>({ key: 'hash', url: '/hash/get' });
	const [getUserData, userResponse] = useQueryOne<{ ids: string[] }, IUser>({ key: 'get-shared-user', url: '/cms/users/get' });
	/**
	 * inheriting user config requires to request user's merged permissions
	 * - undefined means it is not requested.
	 * - null means it is requested but failed.
	 */
	const [mergedPermissions, setMergedPermissions] = useState<ISecurityPermissions<IAnalyticsParams> | null | undefined>(undefined);
	const intervalLoading = useIntervalLoading(userResponse.isFetching || hashResponse.isFetching || permissionsLoading, { initial: false, wait: 500 });
	const user = get(userResponse, 'data.data.0') as IUser | undefined;
	const inheritedMetricsProxy = user?.data?.metricsProxy;
	const { config: sharedConfig, dateFormat = DEFAULT_DATE_FORMAT, timeFormat = DEFAULT_TIME_FORMAT } = hashResponse.data?.data || {};
	const createdBy = hashResponse.data?.data.createdBy;

	/** request hash data */
	useEffect(() => {
		if (!isAuthorized || loading || !hash) return;
		getHashData({ id: hash });
	}, [isAuthorized, loading, hash]);

	/** set shared config */
	useEffect(() => {
		if (!sharedConfig || !isAuthorized) return;
		if (createdBy) getUserData({ ids: [createdBy] });
		save(sharedConfig, true);
		changeDateTimeFormat({ dateFormat, timeFormat });
	}, [isAuthorized, sharedConfig, createdBy, save, dateFormat, timeFormat, changeDateTimeFormat]);

	/** request user's mergedPermissions */
	useEffect(() => {
		if (!user?.certificate) return;
		getMergedPermissionsAsync(user.certificate, user.data?.permissions)
			.then((result) => setMergedPermissions(result || null))
			.catch(() => setMergedPermissions(null));
	}, [user]);

	/** set inherited proxy rules and permissions */
	useEffect(() => {
		if (!sharedConfig || !isAuthorized || !inheritedMetricsProxy || mergedPermissions === undefined) return;
		const onlyAnalytics = pick(mergedPermissions, ['analytics', 'charts', 'tables']) as ISecurityPermissions<IAnalyticsParams>;
		setUserProxyRules(inheritedMetricsProxy, onlyAnalytics, true);
	}, [isAuthorized, inheritedMetricsProxy, mergedPermissions, setUserProxyRules]);

	return intervalLoading;
};
