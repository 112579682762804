// modules
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
// utils
import { ANALYTICS_SECTIONS, DEFAULT_DATE_FORMAT } from 'consts';
import { actions } from 'store/actions';
import { useAuth } from 'store/slices/auth';
import { useConfig } from 'store/slices/config';
// types
import { AnalyticsTabConfig, ChartConfig, Config, TableConfig } from 'store/types';
import { IAnalyticsParams, IAnalyticsRequestParams } from 'types/analytics';
import { ITheme, ValueOf } from 'types/general';
import { IMetricsProxyRule } from 'types/login';
import { ISecurityPermissions } from 'types/security';
import { ICustomVariable } from './../components/Analytics/types';

export const useUserConfig = () => {
	const dispatch = useDispatch();
	const localConfig = useConfig();
	const { isAuthorized, user } = useAuth();
	const dateFormat = user?.data?.dateFormat || DEFAULT_DATE_FORMAT;

	const userConfig = useMemo(() => {
		if (!isAuthorized) return localConfig;
		return user?.data?.config || localConfig;
	}, [isAuthorized, user?.data?.config, localConfig]);

	const save = useCallback(
		(newConfig: Partial<Config>, isConfigShared?: boolean) => {
			const { analytics, charts, defaultChartSize, tables, theme } = newConfig;
			/** save exact keys only */
			const config = { analytics, charts, defaultChartSize, tables, theme };
			dispatch(actions.auth.setUserConfig({ config, isConfigShared }));
		},
		[isAuthorized]
	);

	const checkBirthday = useCallback((date: Moment | number | undefined | null, compareWith?: Moment | number | undefined | null) => {
		const comparingDate = compareWith ? (typeof compareWith === 'number' ? moment(compareWith * 1000) : compareWith) : moment();
		let normalizedDate = typeof date === 'number' ? moment(date * 1000) : date;
		const result = { today: false, thisWeek: false, thisMonth: false, date: normalizedDate ? normalizedDate.format(dateFormat) : null };
		if (!normalizedDate || !normalizedDate.isValid()) return result;
		normalizedDate = moment.utc(`${normalizedDate.format('DD')} ${normalizedDate.format('MMM')} ${moment().format('YYYY')}`).endOf('day');
		if (comparingDate.isAfter(normalizedDate)) return result;
		result.today = normalizedDate.isSame(comparingDate, 'day');
		result.thisWeek = normalizedDate.isSame(comparingDate, 'week');
		result.thisMonth = normalizedDate.isSame(comparingDate, 'month');
		return result;
	}, []);

	const changeDateTimeFormat = useCallback(
		(data: { dateFormat?: string; timeFormat?: '12h' | '24h' }) => {
			dispatch(actions.auth.changeUserData(data));
		},
		[dispatch]
	);

	const changeTable = useCallback(
		({ tableId, config }: { tableId: string; config: Partial<TableConfig> }) => {
			dispatch(actions.auth.changeTableConfig({ tableId, config }));
		},
		[dispatch]
	);

	const changeChart = useCallback(
		({ chartId, config }: { chartId: string; config: Partial<ChartConfig> }) => {
			dispatch(actions.auth.changeChartConfig({ chartId, config }));
		},
		[dispatch]
	);

	const changeAnalyticsTab = useCallback(
		({ tab, config }: { tab: string; config: AnalyticsTabConfig }) => {
			dispatch(actions.auth.setAnalyticsTabConfig({ tab, config }));
		},
		[dispatch]
	);

	const addCustomVariable = useCallback(
		({ variable }: { variable: ICustomVariable }) => {
			dispatch(actions.auth.setAnalyticsCustomVariable({ variable }));
		},
		[dispatch]
	);

	const updateCustomVariable = useCallback(
		({ variable }: { variable: ICustomVariable }) => {
			dispatch(actions.auth.changeCustomVariable({ variable }));
		},
		[dispatch]
	);

	const deleteCustomVariables = useCallback(
		({ variables }: { variables: ICustomVariable[] }) => {
			dispatch(actions.auth.deleteCustomVariables({ variables }));
		},
		[dispatch]
	);

	const setAnalyticsTabsOrder = useCallback(
		(order: ValueOf<typeof ANALYTICS_SECTIONS>[]) => {
			dispatch(actions.auth.setAnalyticsTabsOrder(order));
		},
		[dispatch]
	);

	const changeAnalyticsCustomTab = useCallback(
		({ tab, config }: { tab: string; config: AnalyticsTabConfig }) => {
			dispatch(actions.auth.changeAnalyticsCustomTab({ tab, config }));
		},
		[dispatch]
	);

	const deleteAnalyticsCustomTab = useCallback(
		({ tab }: { tab: string }) => {
			dispatch(actions.auth.deleteAnalyticsCustomTab({ tab }));
		},
		[dispatch]
	);

	const deleteAnalyticsItemsByIds = useCallback(
		({ ids }: { ids: string[] }) => {
			dispatch(actions.auth.deleteAnalyticsItemById({ ids }));
		},
		[dispatch]
	);

	const setAnalyticsCustomTabsOrder = useCallback(
		(order: string[]) => {
			dispatch(actions.auth.setAnalyticsCustomTabsOrder(order));
		},
		[dispatch]
	);

	const setUserProxyRules = useCallback(
		(metricsProxy: IMetricsProxyRule[], mergedPermissions?: ISecurityPermissions<IAnalyticsParams> | null, isConfigShared?: boolean) => {
			dispatch(actions.auth.setUserProxyRules({ metricsProxy, isConfigShared, mergedPermissions }));
		},
		[dispatch]
	);

	const changeUserConfig = useCallback(
		({ config }: { config: Partial<Config> }) => {
			dispatch(actions.auth.changeUserConfig({ config }));
		},
		[dispatch]
	);

	const changeAnalyticsFilters = useCallback(
		({ tab, filters }: { tab: string; filters: IAnalyticsRequestParams }) => {
			dispatch(actions.auth.setAnalyticsTabFilters({ tab, filters }));
		},
		[dispatch]
	);

	const changeAnalyticsCustomFilters = useCallback(
		({ tab, filters }: { tab: string; filters: IAnalyticsRequestParams }) => {
			dispatch(actions.auth.setAnalyticsCustomTabFilters({ tab, filters }));
		},
		[dispatch]
	);
	const changeTheme = useCallback(
		(theme: Partial<ITheme>) => {
			dispatch(actions.auth.changeTheme(theme));
		},
		[dispatch]
	);

	const variables = useMemo(() => user?.data?.config?.variables || [], [user?.data?.config?.variables]);
	const managingTeams = useMemo(() => user?.data?.managingTeams || [], [user?.data?.managingTeams]);
	const securityGroups = useMemo(() => user?.data?.securityGroups || [], [user?.data?.securityGroups]);
	const teams = useMemo(() => user?.data?.teams || [], [user?.data?.teams]);
	const viewedTeams = useMemo(() => user?.data?.viewedTeams || [], [user?.data?.viewedTeams]);
	const viewedOffice = useMemo(() => user?.data?.viewedOffice || [], [user?.data?.viewedOffice]);
	const managingOffice = useMemo(() => user?.data?.managingOffice || [], [user?.data?.managingOffice]);
	const office = useMemo(() => user?.data?.office || [], [user?.data?.office]);
	const isBirthday = useMemo(() => checkBirthday(user?.birthday), [checkBirthday, user?.birthday]);

	return {
		addCustomVariable,
		allowDownloading: Boolean(user?.data?.allowDownloading !== false),
		allowSharing: Boolean(user?.data?.allowSharing !== false),
		baas: user?.data?.config?.baas,
		changeAnalyticsCustomFilters,
		changeAnalyticsCustomTab,
		changeAnalyticsFilters,
		changeAnalyticsTab,
		changeChart,
		changeDateTimeFormat,
		changeTable,
		changeTheme,
		changeUserConfig,
		checkBirthday,
		config: userConfig,
		dateFormat,
		deleteAnalyticsCustomTab,
		deleteAnalyticsItemsByIds,
		deleteCustomVariables,
		id: user?.id,
		isBirthday,
		isSharedConfig: Boolean(user?.data?.isConfigShared),
		loading: !isAuthorized,
		managingOffice,
		managingTeams,
		mergedPermissions: user?.data?.mergedPermissions,
		office,
		permissions: user?.data?.permissions,
		save,
		securityGroups,
		setAnalyticsCustomTabsOrder,
		setAnalyticsTabsOrder,
		setUserProxyRules,
		showSidebarExternalLinks: Boolean(user?.data?.showSidebarExternalLinks),
		teams,
		timeFormat: user?.data?.timeFormat,
		updateCustomVariable,
		variables,
		viewedOffice,
		viewedTeams,
	};
};
