// modules
import { Box, Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { matchPath, Navigate, Outlet, useLocation } from 'react-router-dom';
// components
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import { ConfigContext } from 'config/config';
// utils
import { path, PROJECT_NAME } from 'consts';
import { useSecurity } from 'hooks/useSecurity';
import { useUserConfig } from 'hooks/useUserConfig';
import { ROUTES } from 'router';
import { useConfig } from 'store/slices/config';
// types
import { RuleType } from 'types/security';

const DashboardLayoutRoot = styled('div')(() => ({
	display: 'flex',
	maxWidth: '100%',
	height: '100%',
	position: 'relative',
}));

export const Layout: FC = () => {
	const { sidebarOpen, isIframe } = useConfig();
	const { isSharedConfig } = useUserConfig();
	const location = useLocation();
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
	const { isItemAccessible } = useSecurity();

	const currentPage = useMemo(() => ROUTES.find((i) => matchPath(i.path || '', location.pathname)), [location.pathname]);

	const pageTitle = useMemo(() => {
		let projectPrefix = `PlayWorks ${PROJECT_NAME}`;
		const isStage = window.location.host.includes('cmsstage.play.works') || window.location.host.includes('emsstage.play.works');
		if (isStage) projectPrefix = `PlayWorks ${PROJECT_NAME} - STAGE`;
		else {
			const isDev =
				window.location.host.includes('localhost') ||
				window.location.host.includes('cmsdev.play.works') ||
				window.location.host.includes('emsdev.play.works');
			if (isDev) projectPrefix = `PlayWorks ${PROJECT_NAME} - DEV`;
		}
		if (!currentPage?.title) return projectPrefix;
		return `${currentPage?.title} | ${projectPrefix}`;
	}, [currentPage?.title]);

	const sidebarWidth = useMemo(() => {
		switch (true) {
			case isSharedConfig:
			case isIframe:
			case !lgUp:
				return 0;
			default:
				return sidebarOpen ? '279px' : '59px';
		}
	}, [lgUp, sidebarOpen, isSharedConfig, isIframe]);

	if (!currentPage) return <Navigate to={path.getStarted} />;

	const isPageAllowed =
		!currentPage.protected ||
		!currentPage.securityId ||
		!currentPage.requiredAccess ||
		isItemAccessible(currentPage.securityId, RuleType.page, currentPage.requiredAccess || []);

	if (!isPageAllowed) {
		/** exception for dashboard page */
		if (currentPage.path === path.dashboard) return <Navigate to={path.getStarted} />;
		return <Navigate to={path.forbidden} />;
	}

	const renderSidebar = () => {
		if (isSharedConfig || isIframe) return null;
		return <Sidebar />;
	};

	const renderHeader = () => {
		if (isIframe) return null;
		return <Header useBreadcrumbs={currentPage.useBreadcrumbs} breadcrumbItems={currentPage.breadcrumbs} />;
	};

	return (
		<ConfigContext.Provider value={currentPage.config}>
			<HelmetProvider>
				<Helmet title={pageTitle} />
				<DashboardLayoutRoot
					sx={{
						marginLeft: sidebarWidth,
						transition: 'margin-left .3s',
					}}
				>
					{renderHeader()}
					<Box
						component="main"
						id="portal-id"
						sx={{
							margin: '0 auto',
							marginTop: '56px',
							boxSizing: 'border-box',
							position: 'relative',
							display: 'flex',
							flex: '1 1 auto',
							flexDirection: 'column',
							width: '100%',
							maxHeight: '100%',
							overflow: 'auto',
						}}
					>
						<Outlet />
					</Box>
				</DashboardLayoutRoot>
				{renderSidebar()}
			</HelmetProvider>
		</ConfigContext.Provider>
	);
};
