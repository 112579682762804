import React, { PropsWithChildren, useContext, useState } from 'react';
import { ErrorModal } from '../components/Modal';
import { useLocation } from 'react-router-dom';
import { createMessagesForActionModal } from '../components/Modal/createMessagesForActionModal';
import { Entity, RequestType } from '../types/general';
import startCase from 'lodash/startCase';

interface ErrorNullInfo {
	error: null;
}

interface ErrorFullInfo {
	error: unknown;
	entity: Entity;
	requestType: RequestType;
}

type ErrorInfo = ErrorNullInfo | ErrorFullInfo;

const entityNameMap = (entity: Entity) => {
	const UpperCaseEntity = startCase(entity);
	return UpperCaseEntity === 'App' ? 'Application' : UpperCaseEntity;
};

function isErrorFullInfo(errorInfo: ErrorInfo | null): errorInfo is ErrorFullInfo {
	return errorInfo !== null && errorInfo.error !== null;
}

type ErrorContext = {
	errorInfo: null | ErrorInfo;
	errorObserver: (error: ErrorInfo) => void;
};

const ErrorContext = React.createContext<ErrorContext | undefined>(undefined);

export const ErrorHandlerProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [errorInfo, setErrorInfo] = useState<ErrorInfo>({ error: null });
	const errorObserver = (error: ErrorInfo) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setErrorInfo(error);
	};

	const value = { errorInfo, errorObserver };

	return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

export const useHandlerErrorModal = () => {
	const context = useContext(ErrorContext);
	const location = useLocation();
	const onBackFn = () => {
		if (context) {
			context.errorObserver({ error: null });
		}
	};

	if (!context) {
		throw new Error('useHandlerErrorModal must be used within a ErrorHandlerProvider');
	} else {
		const { errorInfo, errorObserver } = context;
		if (isErrorFullInfo(errorInfo)) {
			const { title, failedScenarioDescription } = createMessagesForActionModal(location.search, errorInfo.entity, errorInfo.requestType);
			const modal = context.errorInfo?.error ? (
				<ErrorModal
					title={`Failed to ${errorInfo.requestType} ${title || ''} ${entityNameMap(errorInfo.entity)}`}
					onClose={onBackFn}
					body={failedScenarioDescription}
				/>
			) : null;
			return { modal, errorObserver };
		} else {
			const modal = context.errorInfo?.error ? (
				<ErrorModal
					title={'Unknown Title isErrorFullInfo(errorInfo) === false'}
					onClose={onBackFn}
					body={errorInfo?.error || 'Empty Body isErrorFullInfo(errorInfo) === false'}
				/>
			) : null;
			return { modal, errorObserver };
		}
	}
};
