import { Entity, RequestType } from '../../types/general';

export const createMessagesForActionModal = (searchParams: string, entity: Entity | 'default', requestType: RequestType = 'delete') => {
	const queryParams = new URLSearchParams(searchParams);
	const platformName = queryParams.get('platformName');
	const groupName = queryParams.get('groupName');
	const deploymentName = queryParams.get('deploymentName');
	const applicationName = queryParams.get('name');
	const modeName = queryParams.get('mode');
	const eventName = queryParams.get('event');
	const msgStart = 'Are you sure you want to delete the data below: ';
	const msgStartFailedScenario = `Failed to ${requestType}: `;
	const note = 'All related data will be deleted!';
	let title: string | null = null;
	let body: string | null = null;
	let failedScenarioDescription: string | null = null;
	let successScenarioDescription: string | null = null;
	switch (entity) {
		case 'platform': {
			title = platformName;
			failedScenarioDescription = `${msgStartFailedScenario} \nPlatform "${title}" `;
			successScenarioDescription = `Below data was successfully deleted: \nPlatform "${title}"`;
			body = `${msgStart} \nPlatform "${title}"`;
			break;
		}
		case 'deployment': {
			title = deploymentName;
			failedScenarioDescription = `${msgStartFailedScenario} \nDeployment "${deploymentName}" \nPlatform "${platformName}" `;
			successScenarioDescription = `Below data was successfully deleted: \nDeployment "${deploymentName}" \nPlatform "${platformName}"`;
			body = `${msgStart} \nDeployment "${deploymentName}" \nPlatform "${platformName}"`;
			break;
		}
		case 'app': {
			title = applicationName;
			failedScenarioDescription = `${msgStartFailedScenario} \nApplication "${title}" \nDeployment "${deploymentName}" \nPlatform "${platformName}" `;
			successScenarioDescription = `Below data was successfully deleted: \nApplication "${title}"  \nDeployment "${deploymentName}" \nPlatform "${platformName}"`;
			body = `${msgStart} \nApplication "${title}" \nDeployment "${deploymentName}"  \nPlatform "${platformName}"`;
			if (title === null || deploymentName === null || platformName === null) {
				body = null;
				break;
			}
			break;
		}
		case 'app-mode': {
			title = modeName;
			failedScenarioDescription = `${msgStartFailedScenario} \nMode "${title}" \nApplication "${applicationName}" \nDeployment "${deploymentName}" \nPlatform "${platformName}" `;
			successScenarioDescription = `Below data was successfully deleted: \nMode "${title}"  \nApplication "${applicationName}"  \nDeployment "${deploymentName}" \nPlatform "${platformName}"`;
			body = `${msgStart} \nMode "${title}" \nApplication "${applicationName}"  \nDeployment "${deploymentName}"  \nPlatform "${platformName}"`;
			if (title === null || applicationName === null || deploymentName === null || platformName === null) {
				body = null;
				break;
			}
			break;
		}
		case 'event': {
			title = eventName;
			failedScenarioDescription = `${msgStartFailedScenario} \nEvent "${title}" \nMode "${modeName}" \nApplication "${applicationName}" \nDeployment "${deploymentName}" \nPlatform "${platformName}" `;
			successScenarioDescription = `Below data was successfully deleted: \nEvent "${title}" \nMode "${modeName}" \nApplication "${applicationName}" \nDeployment "${deploymentName}" \nPlatform "${platformName}"`;
			body = `${msgStart} \nEvent "${title}" \nMode "${modeName}" \nApplication "${applicationName}" \nDeployment "${deploymentName}" \nPlatform "${platformName}"`;
			if (title === null || modeName === null || applicationName === null || deploymentName === null || platformName === null) {
				body = null;
				break;
			}
			break;
		}
		case 'security-group': {
			if (!groupName) break;
			title = groupName;
			failedScenarioDescription = `${msgStartFailedScenario} \nSecurity Group "${title}" `;
			successScenarioDescription = `Below data was successfully deleted: \nSecurity Group "${title}"`;
			body = `${msgStart} \nSecurity Group "${title}"`;
			break;
		}
		default: {
			title = null;
			body = null;
			failedScenarioDescription = null;
			successScenarioDescription = null;
		}
	}

	return {
		title,
		body,
		failedScenarioDescription,
		successScenarioDescription,
		note,
	};
};
