// modules
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import { config } from './slices/config';
import { auth } from './slices/auth';
import { shareApplications } from './slices/shareApplications';
import { hosting } from './slices/hosting';
import { appAssets } from './slices/appAssets';
import { assetsConfigure } from './slices/assetsSettings';
import { tableDropdownOptions } from './slices/tableDropdownOptions';
// listeners
import { userConfigListener } from './listeners/userConfigListener';

const configPersistSettings = {
	key: 'config',
	storage,
	blacklist: ['analytics', 'version', 'sessionStartDate', 'isIframe'],
};

const persistedConfig = persistReducer(configPersistSettings, config.reducer);

const rootReducer = combineReducers({
	config: persistedConfig,
	auth: auth.reducer,
	shareApplication: shareApplications.reducer,
	pathAssets: hosting.reducer,
	assets: appAssets.reducer,
	assetsConfigure: assetsConfigure.reducer,
	tableDropdownOptions: tableDropdownOptions.reducer,
});

export const store = configureStore({
	reducer: rootReducer,
	devTools: true,
	middleware: (gDM) => gDM({ serializableCheck: false }).prepend(userConfigListener.middleware),
});

export const persistor = persistStore(store);
