// modules
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
// types
import { ITheme } from 'types/general';
// theme
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

export const createTheme = (config?: Partial<ITheme>) => {
	let theme = createMuiTheme(baseThemeOptions, config?.mode === 'dark' ? darkThemeOptions : lightThemeOptions, {
		direction: config?.direction || 'ltr',
	});

	if (config?.responsiveFontSizes) {
		theme = responsiveFontSizes(theme);
	}

	return theme;
};
