import moment, { Moment } from 'moment';

/** @description checks if the session lives in one day. If provided date is before today, returns false */
const checkSession = (date: string | number | Moment) => {
	return moment(date).local().startOf('day').isSame(moment().local().startOf('day'));
};

/**
 * @description
 * - if session lives too long, then force user to update change to avoid date errors (inputs, calendars etc.)
 * */
export const updateSession = (date: string | number | Moment) => {
	const isValid = checkSession(date);
	if (!isValid) {
		alert('The page has not been updated for a long time. Please reload the page to avoid errors.');
		location.reload();
	}
};
