// types
import { IAnalyticsResponse, IAnalyticsResponsePacked, IAnalyticsSessionData } from 'types/analytics';

export function unpack<TData = IAnalyticsSessionData>(data?: IAnalyticsResponse | IAnalyticsResponsePacked) {
	if (!data) return [];
	if (!data.isPacked) return data.data as TData[];

	return data.data.map((record: number[]) => {
		const unpackedRecord = record.reduce((acc: Record<string, string | number>, item: number, index: number) => {
			const useDict = data.useDict[index];
			const key = data.propertyNames[index];
			const value = useDict ? data.dict[item] : item;
			acc[key] = value;
			return acc;
		}, {});
		return unpackedRecord as unknown as TData;
	});
}
