// modules
import { useEffect, useState } from 'react';

/**
 * @description Instantly enables loading but uses timeout for loading disabling
 */
export const useIntervalLoading = (loading: boolean, options: { initial: boolean; wait: number }): boolean => {
	const { initial, wait } = options;
	const [isLoading, setLoading] = useState(() => initial);

	useEffect(() => {
		if (loading) {
			setLoading(true);
			return;
		}
		const timeout = setTimeout(() => setLoading(loading), wait);
		return () => clearTimeout(timeout);
	}, [loading, wait]);

	return isLoading;
};
