// modules
import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

const DEFAULT_MESSAGE = 'Are you sure you want to leave the page? You will loose all unsaved data.';

/** @description prevents navigation if true */
export const useSafeNavigate = (prevent?: boolean) => {
	const navigate = useNavigate();

	const safeNavigate = useCallback(
		(path: string, options?: NavigateOptions & { message?: string; force?: boolean }) => {
			const { message = DEFAULT_MESSAGE, force, ...navigateOptions } = options || {};

			if (force || prevent) {
				const isConfirmed = window.confirm(message);
				if (!isConfirmed) return;
			}

			navigate(path, navigateOptions);
		},
		[prevent]
	);

	return { navigate, safeNavigate };
};
