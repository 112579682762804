// modules
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, SxProps, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
// config
import { pages } from 'config/pages';
//  types
import { IPage } from 'types/general';
import { RuleType } from 'types/security';
// utils
import { useClickOutside } from 'hooks/useClickOutside';
import { useSecurity } from 'hooks/useSecurity';

interface ISearchProps {
	sx?: SxProps;
}

const SearchField = styled(TextField)({
	'& label.Mui-focused': {
		color: 'green',
	},
	'& .MuiInput-root': {
		color: 'white',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'white',
	},
	'& .MuiInput-input::placeholder': {
		color: 'gainsboro',
	},
});

const SearchInternal: FC<ISearchProps> = ({ sx }) => {
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState('');
	const inputRef = useRef<HTMLInputElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const { isItemAccessible } = useSecurity();

	const suggested = useMemo(() => {
		if (!value) return [];
		const normalizedValue = value.trim().toLowerCase();
		return pages.filter((page) => {
			if (!page.useInSearch) return false;
			const isAllowed = !page.securityId || !page.requiredAccess || isItemAccessible(page.securityId, RuleType.page, page.requiredAccess);
			if (!isAllowed) return false;
			return page.title.toLowerCase().includes(normalizedValue) || page.path?.toLowerCase().includes(normalizedValue);
		});
	}, [value, isItemAccessible]);

	const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
		setValue(ev.target.value);
	}, []);

	const handleOpen = useCallback(() => {
		setOpen(true);
		inputRef.current?.focus();
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
		setValue('');
	}, []);

	useClickOutside(containerRef, handleClose, !open);

	const renderMenuItem = (page: IPage) => (
		<ListItem key={page.path + page.title}>
			<ListItemButton
				component={Link}
				onClick={handleClose}
				to={page.path || ''}
				sx={{
					color: (theme) => (theme.palette.mode === 'dark' ? 'neutral.100' : 'neutral.900'),
				}}
			>
				<ListItemText primary={page.title} />
			</ListItemButton>
		</ListItem>
	);

	const renderMenu = () => {
		if (!suggested?.length) return null;
		return (
			<Paper
				elevation={20}
				sx={{
					overflow: 'scroll',
					position: 'fixed',
					top: 64,
					right: 48,
					width: 250,
					maxHeight: 400,
				}}
			>
				<List>{suggested.map(renderMenuItem)}</List>
			</Paper>
		);
	};

	return (
		<Box
			ref={containerRef}
			sx={{
				position: 'relative',
				display: 'flex',
				alignItems: 'center',
				mx: 1,
				...sx,
			}}
		>
			<IconButton onClick={handleOpen} sx={{ '&:hover': { backgroundColor: 'primary.dark' } }}>
				<SearchIcon sx={{ color: 'neutral.100' }} />
			</IconButton>
			<SearchField
				inputRef={inputRef}
				variant="standard"
				placeholder="Search..."
				sx={{
					transition: 'width .3s',
					width: open ? 200 : 0,
					color: 'neutral.100',
				}}
				value={value}
				onChange={handleChange}
			/>
			{renderMenu()}
		</Box>
	);
};

export const Search = memo(SearchInternal);
