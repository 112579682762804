// modules
import { Navigate } from 'react-router-dom';
// constants
import { CHECK_CUSTOM_REPORT_EVENT_RECEIVER, CHECK_CUSTOM_REPORT_EVENT_REQUEST, path, PROJECT_NAME } from 'consts';
// types
import { IPage } from 'types/general';
import { Access } from 'types/security';
import { appModeConfig } from './app-mode';
import { applicationsConfig } from './applications';
import { baasConfig } from './baas-config';
import { cmsConfig } from './cms-config';
import { demoConfig } from './demo';
import { deploymentConfig } from './deployment';
import { eventConfig } from './event';

const Contracts = () => import('pages/Contracts').then((module) => ({ Component: module.Contracts }));
const DatabaseSettings = () => import('pages/DatabaseSettings').then((module) => ({ Component: module.DatabaseSettings }));
const ContractForm = () => import('pages/ContractForm').then((module) => ({ Component: module.ContractForm }));
const TeamStatus = () => import('pages/TeamStatus').then((module) => ({ Component: module.TeamStatus }));
const TeamStatusForm = () => import('pages/TeamStatusForm').then((module) => ({ Component: module.TeamStatusForm }));
const TeamMemberDetails = () => import('pages/TeamMemberDetails').then((module) => ({ Component: module.TeamMemberDetails }));
const Office = () => import('pages/Office').then((module) => ({ Component: module.Office }));
const OfficeManager = () => import('pages/OfficeManager').then((module) => ({ Component: module.OfficeManager }));
const SecurityGroups = () => import('pages/SecurityGroups').then((module) => ({ Component: module.SecurityGroups }));
const SecurityGroupForm = () => import('pages/SecurityGroupForm').then((module) => ({ Component: module.SecurityGroupForm }));
const Users = () => import('pages/Users').then((module) => ({ Component: module.Users }));
const UserForm = () => import('pages/UserForm').then((module) => ({ Component: module.UserForm }));
const Analytics = () => import('pages/Analytics').then((module) => ({ Component: module.Analytics }));
const AnalyticsCustomReportsEditor = () =>
	import('pages/AnalyticsCustomReportsEditor').then((module) => ({ Component: module.AnalyticsCustomReportsEditor }));
const AnalyticsCustomReports = () => import('pages/AnalyticsCustomReports').then((module) => ({ Component: module.AnalyticsCustomReports }));
const AnalyticsSandbox = () => import('pages/AnalyticsSandbox').then((module) => ({ Component: module.AnalyticsSandbox }));
const AnalyticsSettings = () => import('pages/AnalyticsSettings').then((module) => ({ Component: module.AnalyticsSettings }));
const AnalyticsScheduledReports = () => import('pages/AnalyticsScheduledReports').then((module) => ({ Component: module.AnalyticsScheduledReports }));
const AnalyticsScheduledReportForm = () =>
	import('pages/AnalyticsScheduledReportForm').then((module) => ({ Component: module.AnalyticsScheduledReportForm }));
const CDN = () => import('pages/CDN').then((module) => ({ Component: module.CDN }));
const Demo = () => import('pages/Demo').then((module) => ({ Component: module.Demo }));
const Debug = () => import('pages/Debug').then((module) => ({ Component: module.Debug }));
const DemoCharts = () => import('pages/DemoCharts').then((module) => ({ Component: module.DemoCharts }));
const DemoTable = () => import('pages/DemoTable').then((module) => ({ Component: module.DemoTable }));
const DemoTree = () => import('pages/DemoTree').then((module) => ({ Component: module.DemoTree }));
const DemoAppsTree = () => import('pages/DemoAppsTree').then((module) => ({ Component: module.DemoAppsTree }));
const DemoCustomReports = () => import('pages/DemoCustomReports').then((module) => ({ Component: module.DemoCustomReports }));
const Statistics = () => import('pages/Statistics').then((module) => ({ Component: module.Statistics }));
const Dashboard = async () => import('pages/Dashboard').then((module) => ({ Component: module.Dashboard }));
const GetStarted = () => import('pages/GetStarted').then((module) => ({ Component: module.GetStarted }));
const NotFound = () => import('pages/NotFound').then((module) => ({ Component: module.NotFound }));
const Unauthorized = () => import('pages/Unauthorized').then((module) => ({ Component: module.Unauthorized }));
const Login = () => import('pages/Login').then((module) => ({ Component: module.Login }));
const ForgotPassword = () => import('pages/ForgotPassword').then((module) => ({ Component: module.ForgotPassword }));
const UndefinedError = () => import('pages/UndefinedError').then((module) => ({ Component: module.UndefinedError }));
const Forbidden = () => import('pages/Forbidden').then((module) => ({ Component: module.Forbidden }));
const BaaSReboot = () => import('pages/BaaSReboot').then((module) => ({ Component: module.BaaSReboot }));

const PlatformList = () => import('components/Platforms/items/PlatformList').then((module) => ({ Component: module.PlatformList }));
const ToolOverview = () => import('../components/ToolOverview/ToolOverview').then((module) => ({ Component: module.ToolOverview }));
const ToolDataHandler = () => import('../components/ToolOverview/items/ToolDataHandler').then((module) => ({ Component: module.ToolDataHandler }));
const PlatformDataHandler = () =>
	import('../components/Platforms/items/PlatformDataHandler').then((module) => ({ Component: module.PlatformDataHandler }));
const ProductList = () => import('../components/Products/ProductList').then((module) => ({ Component: module.ProductList }));
const ProductDataHandler = () =>
	import('../components/Products/items/ProductDataHandler').then((module) => ({ Component: module.ProductDataHandler }));
const DeploymentDataHandler = () =>
	import('../components/Deployments/items/DeploymentDataHandler').then((module) => ({ Component: module.DeploymentDataHandler }));
const ApplicationsList = () =>
	import('../components/ApplicationsManager/items/ApplicationsList').then((module) => ({ Component: module.ApplicationsList }));
const ApplicationDataHandler = () =>
	import('../components/ApplicationsManager/items/ApplicationDataHandler').then((module) => ({ Component: module.ApplicationDataHandler }));
const ApplicationShareDataHandler = () =>
	import('../components/ApplicationsManager/sharing/ApplicationShareDataHandler').then((module) => ({
		Component: module.ApplicationShareDataHandler,
	}));
const CmsConfigurationDataHandler = () =>
	import('../components/CmsConfiguration/CmsConfigurationDataHandler').then((module) => ({ Component: module.CmsConfigurationDataHandler }));
const ModeDataHandler = () => import('../components/Modes/items/ModeDataHandler').then((module) => ({ Component: module.ModeDataHandler }));
const EventDataHandler = () => import('../components/Events/items/EventDataHandler').then((module) => ({ Component: module.EventDataHandler }));
const HostingOverview = () => import('../components/HostingSettings/items/HostingOverview').then((module) => ({ Component: module.HostingOverview }));
const HostingSettings = () => import('../components/HostingSettings/items/HostingSettings').then((module) => ({ Component: module.HostingSettings }));
const AssetOverview = () => import('../components/AssetsSettings/items/AssetsOverview').then((module) => ({ Component: module.AssetsOverview }));
const AssetSettings = () => import('../components/AssetsSettings/items/AssetSettings').then((module) => ({ Component: module.AssetSettings }));
const TemplateOverview = () => import('../components/EmailTemplates/TemplateOverview').then((module) => ({ Component: module.TemplateOverview }));
const TemplateDataHandler = () =>
	import('../components/EmailTemplates/items/TemplateDataHandler').then((module) => ({ Component: module.TemplateDataHandler }));

export const pages: IPage[] = [
	{
		path: path.services,
		title: '',
		pageType: 'custom',
		customElement: <Navigate to={path.dashboard} />,
		protected: true,
		useInSearch: false,
		useLayout: true,
	},
	{
		path: path.dashboard,
		title: 'Dashboard',
		securityId: 'dashboard',
		pageType: 'custom',
		useInSearch: true,
		useLayout: true,
		protected: true,
		requiredAccess: [Access.read],
		lazy: Dashboard,
	},
	{
		path: path.getStarted,
		title: 'Get Started',
		securityId: 'get-started',
		pageType: 'custom',
		useInSearch: true,
		useLayout: true,
		protected: true,
		requiredAccess: [Access.read],
		lazy: GetStarted,
	},
	{
		path: path.service,
		pageType: 'list',
		title: 'Services',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.users,
		title: 'Users',
		securityId: 'users',
		pageType: 'custom',
		lazy: Users,
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
		requiredAccess: [Access.read],
	},
	{
		path: path.userDelete,
		title: 'Delete User',
		securityId: 'users',
		pageType: 'custom',
		lazy: Users,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
		requiredAccess: [Access.delete],
	},
	{
		path: path.userCreate,
		pageType: 'custom',
		lazy: UserForm,
		title: 'Create User',
		securityId: 'users',
		requiredAccess: [Access.create],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.userEdit,
		pageType: 'custom',
		lazy: UserForm,
		title: 'Edit User',
		securityId: 'users',
		requiredAccess: [Access.update],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.databaseSettings,
		title: 'Database Settings',
		securityId: 'database',
		pageType: 'custom',
		lazy: DatabaseSettings,
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
		breadcrumbs: [{ label: 'Database' }, { label: 'Settings' }],
		requiredAccess: [Access.read, Access.create, Access.update, Access.delete],
	},
	{
		path: path.contracts,
		title: 'Agreements',
		securityId: 'contracts',
		pageType: 'custom',
		lazy: Contracts,
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
		breadcrumbs: [{ label: 'Database' }, { label: 'Agreements' }],
		requiredAccess: [Access.read],
	},
	{
		path: path.contractDelete,
		title: 'Delete Agreement',
		securityId: 'contracts',
		pageType: 'custom',
		lazy: Contracts,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
		breadcrumbs: [{ label: 'Database' }, { label: 'Agreements' }],
		requiredAccess: [Access.delete],
	},
	{
		path: path.contractCreate,
		pageType: 'custom',
		lazy: ContractForm,
		title: 'Create Agreement',
		securityId: 'contracts',
		breadcrumbs: [{ label: 'Database' }, { label: 'Agreements', path: path.contracts }],
		requiredAccess: [Access.create],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.contractEdit,
		pageType: 'custom',
		lazy: ContractForm,
		title: 'Edit Agreement',
		securityId: 'contracts',
		breadcrumbs: [{ label: 'Database' }, { label: 'Agreements', path: path.contracts }],
		requiredAccess: [Access.update],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},

	{
		path: path.analyticsReports,
		lazy: Analytics,
		pageType: 'custom',
		title: 'Reports | Analytics',
		securityId: 'analytics-reports',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: false,
		protected: true,
	},
	{
		path: path.analyticsCustomReports,
		lazy: AnalyticsCustomReports,
		pageType: 'custom',
		title: 'Custom Reports | Analytics',
		securityId: 'analytics-custom-reports',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: false,
		protected: true,
	},
	{
		path: path.analyticsEditCustomReports,
		lazy: AnalyticsCustomReportsEditor,
		pageType: 'custom',
		title: 'Edit Custom Reports | Analytics',
		securityId: 'analytics-custom-reports',
		requiredAccess: [Access.read, Access.create, Access.update, Access.delete],
		useInSearch: true,
		useLayout: true,
		protected: true,
		useBreadcrumbs: true,
		breadcrumbs: [
			{
				label: 'Custom Reports',
				path: path.analyticsCustomReports,
				eventRequestId: CHECK_CUSTOM_REPORT_EVENT_REQUEST,
				eventReceiverId: CHECK_CUSTOM_REPORT_EVENT_RECEIVER,
			},
			{ label: 'Edit Custom Reports', path: path.analyticsEditCustomReports },
		],
	},
	{
		path: path.analyticsSandboxReports,
		lazy: AnalyticsSandbox,
		pageType: 'custom',
		title: 'Reports | Analytics Sandbox',
		securityId: 'analytics-sandbox-reports',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: false,
		protected: true,
	},
	{
		path: path.analyticsScheduledReports,
		lazy: AnalyticsScheduledReports,
		pageType: 'custom',
		title: 'Scheduled Reports | Analytics',
		securityId: 'analytics-scheduled-reports',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: false,
		protected: true,
	},
	{
		path: path.analyticsDeleteScheduledReports,
		lazy: AnalyticsScheduledReports,
		pageType: 'custom',
		title: 'Delete Scheduled Reports | Analytics',
		securityId: 'analytics-scheduled-reports',
		requiredAccess: [Access.delete],
		useLayout: true,
		useBreadcrumbs: false,
		protected: true,
	},
	{
		path: path.analyticsCreateScheduledReports,
		lazy: AnalyticsScheduledReportForm,
		pageType: 'custom',
		title: 'Create Scheduled Reports | Analytics',
		securityId: 'analytics-scheduled-reports',
		requiredAccess: [Access.create],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		breadcrumbs: [
			{ label: 'Scheduled Reports', path: path.analyticsScheduledReports },
			{ label: 'Create Report', path: path.analyticsCreateScheduledReports },
		],
		protected: true,
	},
	{
		path: path.analyticsEditScheduledReports,
		lazy: AnalyticsScheduledReportForm,
		pageType: 'custom',
		title: 'Edit Scheduled Reports | Analytics',
		securityId: 'analytics-scheduled-reports',
		requiredAccess: [Access.update],
		useLayout: true,
		useBreadcrumbs: true,
		breadcrumbs: [
			{ label: 'Scheduled Reports', path: path.analyticsScheduledReports },
			{ label: 'Edit Report', path: path.analyticsEditScheduledReports },
		],
		protected: true,
	},
	{
		path: path.analyticsSettings,
		lazy: AnalyticsSettings,
		pageType: 'custom',
		title: 'Settings | Analytics',
		securityId: 'analytics-settings',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: false,
		protected: true,
	},
	{
		path: path.assets,
		lazy: AssetOverview,
		pageType: 'custom',
		title: `Assets | ${PROJECT_NAME} Settings`,
		securityId: 'assets',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.hosting,
		lazy: HostingOverview,
		pageType: 'custom',
		title: `Hosting | ${PROJECT_NAME} Settings`,
		securityId: 'hosting',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.configurations,
		lazy: CmsConfigurationDataHandler,
		pageType: 'custom',
		title: `${PROJECT_NAME} Configurations | ${PROJECT_NAME} Settings`,
		securityId: 'cms-configurations',
		requiredAccess: [Access.update],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.toolsOverview,
		lazy: ToolOverview,
		pageType: 'custom',
		title: `${PROJECT_NAME} Tools`,
		securityId: 'cms-tools',
		requiredAccess: [Access.read],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.platformOverview,
		pageType: 'custom',
		title: 'Platforms | BaaS',
		securityId: 'platforms',
		requiredAccess: [Access.read],
		useInSearch: true,
		lazy: PlatformList,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.platformMultiDelete,
		pageType: 'custom',
		title: 'Delete Platforms | BaaS',
		securityId: 'platforms',
		lazy: PlatformList,
		requiredAccess: [Access.delete],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.productOverview,
		pageType: 'custom',
		lazy: ProductList,
		title: 'Products | BaaS',
		securityId: 'products',
		requiredAccess: [Access.read],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.deploymentMultiDelete,
		pageType: 'list.modal',
		title: 'Delete Deployment | BaaS',
		securityId: 'platforms',
		requiredAccess: [Access.update],
		config: deploymentConfig,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applicationShare,
		pageType: 'custom',
		lazy: ApplicationShareDataHandler,
		title: 'Application Share Overview | BaaS',
		securityId: 'applications',
		requiredAccess: [Access.create],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applications,
		securityId: 'applications',
		requiredAccess: [Access.read],
		pageType: 'custom',
		lazy: ApplicationsList,
		config: applicationsConfig,
		title: 'Applications | BaaS',
		useInSearch: true,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applicationsMultiDelete,
		pageType: 'custom',
		lazy: ApplicationsList,
		title: 'Delete Application | BaaS',
		securityId: 'applications',
		requiredAccess: [Access.delete],
		config: applicationsConfig,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.modesMultiDelete,
		pageType: 'list.modal',
		title: 'Delete App Modes | BaaS',
		securityId: 'applications',
		requiredAccess: [Access.delete],
		config: appModeConfig,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.eventMultiDelete,
		pageType: 'list.modal',
		title: 'Delete Events | BaaS',
		securityId: 'applications',
		requiredAccess: [Access.delete],
		config: eventConfig,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.baasConfig,
		pageType: 'form',
		title: 'Settings | BaaS',
		useLayout: true,
		config: baasConfig,
		useInSearch: false,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.cmsConfig,
		pageType: 'form',
		title: `Settings | ${PROJECT_NAME}`,
		useLayout: true,
		config: cmsConfig,
		useInSearch: false,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: '/demo',
		lazy: Demo,
		useLayout: true,
		useInSearch: false,
		title: 'Demo',
		pageType: 'custom',
		protected: true,
	},
	{
		path: '/demo/form',
		pageType: 'form',
		title: 'Form | Demo',
		useLayout: true,
		useInSearch: false,
		config: demoConfig,
		protected: true,
	},
	{
		path: '/demo/charts',
		pageType: 'custom',
		title: 'Charts | Demo',
		lazy: DemoCharts,
		useInSearch: false,
		useLayout: true,
		protected: true,
	},
	{
		path: '/demo/table',
		pageType: 'custom',
		title: 'Table | Demo',
		lazy: DemoTable,
		useInSearch: false,
		useLayout: true,
		protected: true,
	},
	{
		path: '/demo/custom-reports',
		pageType: 'custom',
		title: 'Table | Demo',
		lazy: DemoCustomReports,
		useInSearch: false,
		useLayout: true,
		protected: true,
	},
	{
		path: '/demo/organizer',
		pageType: 'custom',
		title: 'Organizer | Demo',
		lazy: DemoTree,
		useInSearch: false,
		useLayout: true,
		protected: true,
	},
	{
		path: '/demo/apps',
		pageType: 'custom',
		title: 'Apps Organizer | Demo',
		lazy: DemoAppsTree,
		useInSearch: false,
		useLayout: true,
		protected: true,
	},
	{
		path: '/debug',
		pageType: 'custom',
		title: 'Debug | Demo',
		lazy: Debug,
		useInSearch: false,
		useLayout: true,
		protected: true,
	},
	{
		path: path.statistics,
		pageType: 'custom',
		title: 'Monitoring | BaaS',
		lazy: Statistics,
		useBreadcrumbs: true,
		useInSearch: true,
		useLayout: true,
		protected: true,
		securityId: 'baas-monitoring',
		requiredAccess: [Access.read],
	},
	{
		path: path.notFound,
		pageType: 'custom',
		title: 'Page Not Found',
		lazy: NotFound,
		useLayout: false,
		protected: false,
	},
	{
		path: path.unauthorized,
		pageType: 'custom',
		title: 'Unauthorized',
		lazy: Unauthorized,
		useLayout: false,
		protected: false,
	},
	{
		path: path.forbidden,
		pageType: 'custom',
		title: 'Forbidden',
		lazy: Forbidden,
		useLayout: false,
		protected: false,
	},
	{
		path: path.cdnMonitoring,
		pageType: 'custom',
		title: 'Monitoring | CDN Invalidator',
		lazy: CDN,
		securityId: 'cdn-monitoring',
		requiredAccess: [Access.read],
		useInSearch: true,
		useLayout: true,
		protected: true,
	},
	{
		path: path.login,
		pageType: 'custom',
		title: 'Login',
		lazy: Login,
		protected: true,
	},
	{
		path: path.forgot,
		pageType: 'custom',
		title: 'Forgot Password',
		lazy: ForgotPassword,
		protected: false,
	},
	{
		path: path.undefinedError,
		pageType: 'custom',
		title: 'Unknown Error',
		lazy: UndefinedError,
		protected: false,
	},
	{
		path: path.baasReboot,
		pageType: 'custom',
		title: 'Reboot | BaaS',
		securityId: 'baas-reboot',
		requiredAccess: [Access.read, Access.create, Access.update, Access.delete],
		lazy: BaaSReboot,
		protected: true,
		useLayout: true,
	},
	{
		path: path.securityGroups,
		pageType: 'custom',
		lazy: SecurityGroups,
		title: 'Security Groups',
		securityId: 'security-groups',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
	},
	{
		path: path.securityGroupsEdit,
		pageType: 'custom',
		title: 'Edit Security Groups',
		securityId: 'security-groups',
		requiredAccess: [Access.update],
		lazy: SecurityGroupForm,
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.securityGroupsCreate,
		pageType: 'custom',
		title: 'Create Security Groups',
		securityId: 'security-groups',
		requiredAccess: [Access.create],
		lazy: SecurityGroupForm,
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.securityGroupsDelete,
		pageType: 'custom',
		lazy: SecurityGroups,
		title: 'Delete Security Groups',
		securityId: 'security-groups',
		requiredAccess: [Access.delete],
		protected: true,
		useLayout: true,
	},
	{
		path: path.teamStatus,
		pageType: 'custom',
		lazy: TeamStatus,
		title: 'Team Status',
		securityId: 'team-status',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
	},
	{
		path: path.teamStatusCreate,
		pageType: 'custom',
		lazy: TeamStatusForm,
		title: 'Create Team Status',
		securityId: 'team-status',
		requiredAccess: [Access.create],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.teamStatusEdit,
		pageType: 'custom',
		lazy: TeamStatusForm,
		title: 'Edit Team Status',
		securityId: 'team-status',
		requiredAccess: [Access.update],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.teamStatusDetails,
		pageType: 'custom',
		lazy: TeamMemberDetails,
		title: 'Team Member Details',
		securityId: 'team-status',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.teamStatusDetailsEdit,
		pageType: 'custom',
		lazy: TeamStatusForm,
		title: 'Edit Team Status',
		securityId: 'team-status',
		requiredAccess: [Access.update],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.teamStatusDetailsDelete,
		pageType: 'custom',
		lazy: TeamMemberDetails,
		title: 'Delete Status Report',
		securityId: 'team-status',
		requiredAccess: [Access.delete],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.teamStatusReportDetails,
		pageType: 'custom',
		lazy: TeamStatusForm,
		title: 'Team Report Details',
		securityId: 'team-status',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.teamStatusDelete,
		pageType: 'custom',
		lazy: TeamStatus,
		title: 'Delete Status Report',
		securityId: 'team-status',
		requiredAccess: [Access.delete],
		protected: true,
		useLayout: true,
	},
	{
		path: path.office,
		pageType: 'custom',
		lazy: Office,
		title: 'Department',
		securityId: 'office',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
	},
	{
		path: path.manageOffice,
		pageType: 'custom',
		lazy: OfficeManager,
		title: 'Manage | Department',
		securityId: 'office',
		requiredAccess: [Access.read, Access.create, Access.update, Access.delete],
		protected: true,
		useLayout: true,
	},
	{
		path: path.emailTemplate,
		pageType: 'custom',
		lazy: TemplateOverview,
		title: 'Templates',
		securityId: 'template',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.emailTemplateCreate,
		pageType: 'custom',
		lazy: () => TemplateDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		title: 'Create Template',
		securityId: 'template',
		requiredAccess: [Access.read, Access.create],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.emailTemplateEdit,
		pageType: 'custom',
		lazy: () => TemplateDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		requiredAccess: [Access.read, Access.update],
		title: 'Edit Template',
		securityId: 'template',
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.emailTemplateDetails,
		pageType: 'custom',
		lazy: () => TemplateDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		title: 'Template Details',
		securityId: 'template',
		requiredAccess: [Access.read],
		protected: true,
		useLayout: true,
		useBreadcrumbs: true,
	},
	{
		path: path.eventSettings,
		lazy: () => EventDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		securityId: 'applications',
		requiredAccess: [Access.update],
		pageType: 'custom',
		title: 'Edit Event | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.eventDetails,
		lazy: () => EventDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		securityId: 'applications',
		requiredAccess: [Access.read],
		pageType: 'custom',
		title: 'Event Details | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.eventCreate,
		lazy: () => EventDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		securityId: 'applications',
		requiredAccess: [Access.create],
		pageType: 'custom',
		title: 'Create Event | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.eventShareEdit,
		lazy: () => EventDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'share'} /> })),
		securityId: 'applications',
		requiredAccess: [Access.update],
		pageType: 'custom',
		title: 'Share Event | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.modeSettings,
		lazy: () => ModeDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		securityId: 'applications',
		requiredAccess: [Access.update],
		pageType: 'custom',
		title: 'Edit App Mode | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.modeDetails,
		lazy: () => ModeDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		securityId: 'applications',
		requiredAccess: [Access.read],
		pageType: 'custom',
		title: 'App Mode Details | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.modeCreate,
		lazy: () => ModeDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		securityId: 'applications',
		requiredAccess: [Access.create],
		pageType: 'custom',
		title: 'Create App Mode | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.modeShareEdit,
		lazy: () => ModeDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'share'} /> })),
		securityId: 'applications',
		requiredAccess: [Access.update],
		pageType: 'custom',
		title: 'Share App Mode | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applicationSettings,
		lazy: () => ApplicationDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		pageType: 'custom',
		securityId: 'applications',
		requiredAccess: [Access.update],
		title: 'Edit Application | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applicationDetails,
		lazy: () => ApplicationDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		pageType: 'custom',
		securityId: 'applications',
		requiredAccess: [Access.read],
		title: 'Application Details | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applicationCreate,
		pageType: 'custom',
		lazy: () => ApplicationDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		title: 'Create Application | BaaS',
		securityId: 'applications',
		requiredAccess: [Access.create],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.applicationShareEdit,
		pageType: 'custom',
		lazy: () => ApplicationDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'share'} /> })),
		title: 'Edit Share Application | BaaS',
		securityId: 'applications',
		requiredAccess: [Access.update],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.assetsCreate,
		lazy: () => AssetSettings().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		pageType: 'custom',
		title: `Create Asset | ${PROJECT_NAME} Settings`,
		securityId: 'assets',
		requiredAccess: [Access.create],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.assetsEdit,
		lazy: () => AssetSettings().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		pageType: 'custom',
		title: `Edit Asset | ${PROJECT_NAME} Settings`,
		securityId: 'assets',
		requiredAccess: [Access.update],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.hostingCreate,
		lazy: () => HostingSettings().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		pageType: 'custom',
		title: `Create Hosting | ${PROJECT_NAME} Settings`,
		securityId: 'hosting',
		requiredAccess: [Access.create],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.hostingEdit,
		lazy: () => HostingSettings().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		pageType: 'custom',
		title: `Edit Hosting | ${PROJECT_NAME} Settings`,
		securityId: 'hosting',
		requiredAccess: [Access.update],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.productEdit,
		pageType: 'custom',
		lazy: () => ProductDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		title: 'Edit Product | BaaS',
		securityId: 'products',
		requiredAccess: [Access.update],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.productDetails,
		pageType: 'custom',
		lazy: () => ProductDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		title: 'Product Details | BaaS',
		securityId: 'products',
		requiredAccess: [Access.read],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.productCreate,
		pageType: 'custom',
		lazy: () => ProductDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		title: 'Create Product | BaaS',
		securityId: 'products',
		requiredAccess: [Access.create],
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.deploymentSettings,
		lazy: () => DeploymentDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		pageType: 'custom',
		securityId: 'platforms',
		requiredAccess: [Access.update],
		title: 'Edit Deployment | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.deploymentDetails,
		lazy: () => DeploymentDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		pageType: 'custom',
		securityId: 'platforms',
		requiredAccess: [Access.read],
		title: 'Deployment Details | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.deploymentCreate,
		lazy: () => DeploymentDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		pageType: 'custom',
		securityId: 'platforms',
		requiredAccess: [Access.create],
		title: 'Create Deployment | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.platformSettings,
		lazy: () => PlatformDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		pageType: 'custom',
		securityId: 'platforms',
		requiredAccess: [Access.update],
		title: 'Edit Platform | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.platformDetails,
		lazy: () => PlatformDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode="read" /> })),
		pageType: 'custom',
		securityId: 'platforms',
		requiredAccess: [Access.read],
		title: 'Platform Details | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.platformCreate,
		lazy: () => PlatformDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		pageType: 'custom',
		securityId: 'platforms',
		requiredAccess: [Access.create],
		title: 'Create Platform | BaaS',
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.toolCreate,
		lazy: () => ToolDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'create'} /> })),
		pageType: 'custom',
		title: `Create ${PROJECT_NAME} Tool`,
		securityId: 'cms-tools',
		requiredAccess: [Access.create],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.toolDetails,
		lazy: () => ToolDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'read'} /> })),
		pageType: 'custom',
		title: `${PROJECT_NAME} Tool Details`,
		securityId: 'cms-tools',
		requiredAccess: [Access.read],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
	{
		path: path.toolEdit,
		lazy: () => ToolDataHandler().then((c) => ({ ...c, Component: () => <c.Component mode={'edit'} /> })),
		pageType: 'custom',
		title: `Edit ${PROJECT_NAME} Tool`,
		securityId: 'cms-tools',
		requiredAccess: [Access.update],
		useInSearch: false,
		useLayout: true,
		useBreadcrumbs: true,
		protected: true,
	},
];
