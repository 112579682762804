import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../types';
import { IDropdownSimpleOption } from '../../types/form';
import { HostingItem } from './hosting';

export interface AssetConfigureItem {
	uid: string;
	resolution: string;
	name: string;
	description: string;
	assetType: string;
	extension: string;
	hostingType: string;
	usage: string[];
}

export type AssetConfigureItemIncludingHostingItem = Record<string, Omit<AssetConfigureItem, 'hostingType'> & { hostingType: HostingItem }>;
export interface AssetConfigureFormItem {
	uid: string;
	name: string;
	description: string;
	resolution: IDropdownSimpleOption;
	assetType: IDropdownSimpleOption;
	extension: IDropdownSimpleOption;
	hostingType: IDropdownSimpleOption;
	usage: IDropdownSimpleOption[];
}

export interface IAssetsState {
	items: AssetConfigureItem[];
	id: string;
	uid: string;
	hostingMapping: Record<string, string>;
}

const initialState: IAssetsState = {
	items: [],
	id: '',
	uid: '',
	hostingMapping: {},
};

export const assetsConfigure = createSlice({
	name: 'assetsConfigure',
	initialState,
	reducers: {
		clearState: () => initialState,
		setState: (state, action: PayloadAction<IAssetsState>) => {
			return {
				...action.payload,
			};
		},
		setHosting: (state, action: PayloadAction<HostingItem[]>) => {
			state.hostingMapping = action.payload.reduce((acc, item) => ({ ...acc, [item.uid]: item.name }), {});
		},
		addItem: (state, action: PayloadAction<AssetConfigureItem>) => {
			state.items.push(action.payload);
		},
	},
});

export const useAssetsConfigure = () => useSelector((state: RootState) => state.assetsConfigure);
