// types
import { AnalyticsConfig, ChartConfig, IDashboardConfig, TableConfig } from 'store/types';
import { IAnalyticsRequestParams } from './analytics';

export enum Access {
	read = 'read',
	create = 'create',
	update = 'update',
	delete = 'delete',
}

export enum RuleType {
	api = 'api',
	filter = 'filter',
	page = 'page',
}

export interface ISecurityRule {
	access: Access[];
	/** @description for RuleType.api */
	apiIds?: string[];
	/** @descriptions includes any identifier */
	key: string;
	type: RuleType;
}

export interface ISecurityPermissions<AnalyticsFiltersType = IAnalyticsRequestParams> {
	analytics?: AnalyticsConfig<AnalyticsFiltersType>;
	charts?: Record<string, ChartConfig>;
	rules: ISecurityRule[];
	tables?: Record<string, TableConfig>;
	dashboard?: IDashboardConfig;
}

export interface ISecurityGroup<AnalyticsFiltersType = IAnalyticsRequestParams> {
	description?: string;
	id: string;
	name: string;
	permissions?: ISecurityPermissions<AnalyticsFiltersType>;
	/** @description api rules */
	rules?: Array<{ apiIds?: string[] }>;
}

export interface IApiGroup {
	apiUids: string[];
	id: string;
	name: string;
}

export interface ICertificate {
	id: string;
	name: string;
	certificate: string;
	publicKey?: string;
	/** @description security groups ids */
	accessGroupsIds?: string[];
}
