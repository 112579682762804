// modules
import { useMutation as useTanstackMutation } from '@tanstack/react-query';
// types
import { AnyObject } from 'types/general';
import { ILoginRequest, ILoginResponse } from 'types/login';
// utils
import { instance } from './general';
import { Endpoints } from 'consts/endpoints';

export const useLoginMutation = () =>
	useTanstackMutation({
		mutationFn: (data: ILoginRequest) => instance({ url: Endpoints.cms_authorize, method: 'POST', data }).then((res) => res.data as ILoginResponse),
	});

export const useLogoutMutation = () =>
	useTanstackMutation({
		mutationFn: (data: AnyObject) => instance({ url: Endpoints.cms_logout, method: 'POST', data }),
	});
