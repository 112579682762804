export enum Endpoints {
	application_create = '/app/create',
	application_delete = '/app/delete',
	application_update = '/app/update',
	applications_get = '/apps/get',
	applications_get_all = '/apps/get/all',
	applications_get_urls = '/apps/get/urls',
	aws_s3_buckets_get = 'https://be.play.works:8100/buckets/get',
	aws_s3_file_upload = '/upload',
	aws_s3_config_upload = '/upload/config',
	aws_s3_files_from_directory_get = '/files/get',
	cms_authorize = '/cms/authorize',
	cms_logout = '/cms/logout',
	cms_user_create = '/cms/user/create',
	cms_user_delete = '/cms/user/delete',
	cms_user_update = '/cms/user/update',
	cms_user_update_by_id = '/cms/user/updatebyid',
	cms_users_get = 'cms/users/get',
	configuration_create = '/configuration/create',
	configuration_delete = '/configuration/delete',
	configuration_get = '/configurations/get',
	configuration_update = '/configuration/update',
	items_get = '/items/get',
	items_get_all = '/items/get/all',
	item_create = '/item/create',
	item_update = '/item/update',
	item_delete = '/item/delete',
	deployment_create = '/deployment/create',
	deployment_delete = '/deployment/delete',
	deployment_update = '/deployment/update',
	deployments_get = '/deployments/get',
	event_create = '/event/create',
	event_delete = '/event/delete',
	event_update = '/event/update',
	events_get = '/events/get',
	entities_get = '/entities/get',
	entity_create = '/entity/create',
	entity_update = '/entity/update',
	entity_delete = '/entity/delete',
	mode_create = '/appmode/create',
	mode_delete = '/appmode/delete',
	mode_update = '/appmode/update',
	modes_get = '/appmodes/get',
	platform_create = '/platform/create',
	platform_delete = '/platform/delete',
	platform_update = '/platform/update',
	platforms_get = '/platforms/get',
	security_accessGroup_delete = '/security/accessgroup/delete',
	security_accessGroup_get = '/security/accessgroup/get',
	security_apis_get = '/security/apis/get',
	security_certificate_create = '/security/certificate/create',
	security_certificate_delete = '/security/certificate/delete',
	security_certificate_update = '/security/certificate/update',
	security_certificates_get = '/security/certificates/get',
	shop_create = '/shop/create',
	shop_get = '/shops/get',
	cms_entity_get = '/cms/entity/get',
	cms_entity_create = '/cms/entity/create',
	cms_entity_update = '/cms/entity/update',
	cms_entity_delete = '/cms/entity/delete',
	tasks_get = '/tasks/get',
	task_get = '/task/get',
	tasks_type_get = '/tasks/type/get',
	task_get_log = '/tasks/get/log',
	task_create = '/task/create',
	task_update = '/task/update',
	task_set_auto_restart = '/task/set/auto/restart',
	task_delete = '/task/delete',
	run_task = '/task/run',
	interrupt_task = '/task/interrupt',
}

type KeysOfEndpoint = keyof typeof Endpoints;
type ValuesOfEndpoint = (typeof Endpoints)[KeysOfEndpoint];
const endpointsKeys = Object.keys(Endpoints) as KeysOfEndpoint[];
export const endpointsName = endpointsKeys.reduce(
	(result, key) => ({
		...result,
		[Endpoints[key]]: key,
	}),
	{}
) as Record<ValuesOfEndpoint, KeysOfEndpoint>;
