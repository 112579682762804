// modules
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
// types
import { IEntityConfig } from 'types/entity';
// constants
import { path } from 'consts';

export const eventConfig: IEntityConfig = {
	key: 'event',
	list: {
		title: 'App Events',
		searchable: true,
		clickable: true,
		path: path.eventSettings,
		columns: [
			{ key: 'name.en', label: 'Name (EN)', copyable: true },
			{ key: 'uid', label: 'Event UID', copyable: true },
			{ key: 'id', label: 'Event ID', copyable: true },
		],
		creatable: true,
		actions: [
			{
				label: 'Create Event',
				path: path.eventCreate,
				Icon: AddBoxIcon,
			},
		],
		rowActions: [
			{
				label: 'Edit',
				path: path.eventSettings,
				Icon: EditIcon,
			},
			{
				label: 'Delete',
				path: path.eventMultiDelete,
				Icon: DeleteIcon,
				variant: 'error',
			},
		],
		fetchOptions: {
			endpoint: '/appmodes/get',
			params: {
				appId: {
					type: 'single',
					routeVariable: 'appId',
				},
			},
		},
		deleteOptions: {
			endpoint: '/event/delete',
			params: {
				id: {
					type: 'multiple',
					routeVariable: 'eventIds',
				},
			},
		},
	},
	form: {
		submitOptions: {
			endpoint: {
				create: '/event/create',
				edit: '/event/update',
			},
		},
		fetchOptions: {
			endpoint: '/events/get',
			params: {
				ids: {
					type: 'multiple',
					routeVariable: 'eventIds',
				},
			},
		},
		fields: [],
	},
};
