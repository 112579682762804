// modules
import { Avatar, Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React, { ChangeEvent, ClipboardEvent, FC, ReactNode, useCallback, useState } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
// types
import { PaletteColor } from 'types/general';
// components
import { Overlay } from './Overlay';
// utils
import { anim } from 'theme/animations';

export interface IPromptModalProps {
	body: ReactNode;
	checkWord?: string;
	closeButtonLabel?: string;
	confirmButtonLabel: string;
	isPasteDisabled?: boolean;
	note?: ReactNode;
	onClose: () => void;
	onConfirm: () => void;
	title: ReactNode;
	type: PaletteColor;
}

const icons: Record<PaletteColor, SvgIconComponent> = {
	error: WarningIcon,
	info: HelpOutlineIcon,
	primary: HelpOutlineIcon,
	secondary: HelpOutlineIcon,
	success: CheckCircleOutlineIcon,
	warning: WarningIcon,
};

const ICON_CONTAINER_WIDTH = 64;

export const PromptModal: FC<IPromptModalProps> = (props) => {
	const { body, checkWord, closeButtonLabel = 'Close', confirmButtonLabel, isPasteDisabled = true, note, onClose, onConfirm, title, type } = props;
	const Icon = icons[type];
	const [inputValue, setInputValue] = useState('');
	const pulseOut = anim('pulseOut', 3, undefined, 0, true);

	const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value), []);

	const handlePaste = useCallback(
		(event: ClipboardEvent<HTMLInputElement>) => {
			if (isPasteDisabled) event.preventDefault();
		},
		[isPasteDisabled]
	);

	const renderNote = () => {
		if (!note) return null;
		return (
			<Grid item>
				<Typography color="error.main" sx={{ pt: '15px' }} component="span" fontWeight={700} variant="body2">
					{note}
				</Typography>
			</Grid>
		);
	};

	const renderAdditionalCheck = () => {
		if (!checkWord) return null;
		return (
			<Grid container direction="column">
				<Grid item>
					<Typography color="textSecondary" variant="body2">
						Please write
						<Typography color="text.primary" sx={{ px: '2px' }} variant="body2" component="span" fontWeight={700}>
							&quot;
							{checkWord}
							&quot;
						</Typography>
						to confirm the action
					</Typography>
				</Grid>
				<Grid item>
					<TextField
						fullWidth
						sx={{ maxWidth: 400 }}
						placeholder={`Enter "${checkWord}"`}
						onPaste={handlePaste}
						size="small"
						value={inputValue}
						onChange={handleInputChange}
					/>
				</Grid>
				{renderNote()}
			</Grid>
		);
	};

	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 5000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Overlay />
			<Container sx={{ width: 700 }}>
				<Paper elevation={12} sx={{ p: 3, position: 'relative' }}>
					<Grid container>
						<Grid item width={ICON_CONTAINER_WIDTH}>
							<Avatar
								sx={{
									backgroundColor: (theme) => alpha(theme.palette[type].main, 0.08),
									color: `${type}.main`,
									mt: -1,
									width: 48,
									height: 48,
									...pulseOut,
								}}
							>
								<Icon fontSize="small" />
							</Avatar>
						</Grid>
						<Grid item width={`calc(100% - ${ICON_CONTAINER_WIDTH}px)`}>
							<Typography variant="h5">{title}</Typography>
							<Typography component="div" color="textSecondary" sx={{ py: 2, maxHeight: 400, overflowY: 'scroll' }} variant="body2">
								{body}
							</Typography>
							{renderAdditionalCheck()}
							<Grid container alignItems="end" justifyContent="end" mt={2}>
								<Button sx={{ mr: 2 }} onClick={onClose} variant="outlined">
									{closeButtonLabel || 'Close'}
								</Button>
								<Button
									onClick={onConfirm}
									disabled={Boolean(checkWord && checkWord !== inputValue)}
									sx={{
										backgroundColor: `${type}.main`,
										'&:hover': {
											backgroundColor: `${type}.dark`,
										},
									}}
									variant="contained"
								>
									{confirmButtonLabel}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Box>
	);
};
