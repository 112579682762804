// modules
import round from 'lodash/round';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
// types
import { IAnalyticsUserData } from 'types/analytics';
// utils
import { sumObjectsByKey } from 'utils/sumObjectsByKey';
import { getAnalyticsObjectId } from 'utils/getAnalyticsObjectId';

export const sumAnalyticObjects = (items: IAnalyticsUserData[], isPayingUsers?: boolean) => {
	const res = items.reduce<Record<string, IAnalyticsUserData>>((acc, c) => {
		const { activetime, inactivetime, ...rest } = omit(c, '_count');

		/** Don't forget to add new metric - it will summarize values wrong otherwise */
		const combinedID = getAnalyticsObjectId(isPayingUsers ? omit(c, 'd3') : c);
		const result = sumObjectsByKey(acc[combinedID] as unknown as Record<string, unknown>, rest);
		const sessions = rest.sessions || 1;
		if (activetime) result.activetime = (acc[combinedID]?.activetime || 0) + activetime * sessions;
		if (inactivetime) result.inactivetime = (acc[combinedID]?.inactivetime || 0) + inactivetime * sessions;
		acc[combinedID] = result as unknown as IAnalyticsUserData;

		acc[combinedID]._count = (acc[combinedID]._count || 0) + 1;
		if (isPayingUsers && c.d3) {
			acc[combinedID]._data = [...(acc[combinedID]._data || []), c.d3];
		}
		return acc;
	}, {});

	const a = Object.values(res).map((i) => {
		if (i.activetime) i.activetime = round(i.activetime / i.sessions);
		if (i.inactivetime) i.inactivetime = round(i.inactivetime / i.sessions);
		if (i.avgeventvalue) i.avgeventvalue = round(i.avgeventvalue / Number(i._count || 1));
		if (isPayingUsers) i['paying-users'] = uniq(i._data || []).length || 0;
		return i;
	});

	return a;
};
