// modules
import { Moment } from 'moment';
// types
import { AnalyticsMetrics, AnalyticsSyntheticMetrics, IAnalyticsRequestParams } from 'types/analytics';
import { Entity, ITheme, OrderType, ValueOf } from 'types/general';
import { IUser } from 'types/login';
// utils
import { ANALYTICS_SECTIONS } from 'consts';
import { store } from './store';
// types
import { AGTableColumn } from 'components/AGTable';
import { ICustomVariable } from 'components/Analytics/types';

export enum ChartSize {
	day = 'day',
	week = 'week',
	month = 'month',
}

export interface ITableFilterModelItem {
	/** @description search string or number */
	filter?: string | number;
	/** @description search string or number for `inRange` type */
	filterTo?: number;
	/** @description text | number etc */
	filterType: string;
	/** @description contains | equals etc.*/
	type?: string;
	operator?: 'AND' | 'OR';
	conditions?: Array<{
		/** @description search string or number */
		filter: string | number;
		/** @description search string or number for `inRange` type */
		filterTo?: string | number;
		/** @description text | number etc */
		filterType: string;
		/** @description contains | equals etc.*/
		type: string;
	}>;
}

export type TableFilterModel = Record<string, ITableFilterModelItem>;

export type TableSortingModel = Record<string, 'asc' | 'desc' | null | undefined>;

export enum TablePresentation {
	table = 'table',
	tree = 'tree',
}

export interface TableConfig {
	/** @description columns || variables */
	columns?: AGTableColumn[];
	/** @description columns but for custom reports */
	variables?: ICustomVariable[];
	columnsWidth?: Array<{
		key: string;
		width?: number;
		maxWidth?: number | null;
	}>;
	columnsOrder?: string[];
	hiddenColumns?: string[];
	filterModel?: TableFilterModel;
	sortingModel?: TableSortingModel;
	limit?: number;
	order?: OrderType;
	orderBy?: AnalyticsMetrics | AnalyticsSyntheticMetrics;
	presentation?: TablePresentation;
}

export interface ChartDatasetConfig {
	visible?: boolean;
}

export interface ChartConfig {
	defaultSize?: ChartSize;
	size?: ChartSize;
	limit?: number;
	/** @description average of all elements in chart */
	showAverage?: boolean;
	/** @description average of all elements in chart with '(ALL)' label */
	showAverageAll?: boolean;
	/** @description average of n-limit elements. depends on limit */
	showLimitedAverage?: boolean;
	/** @description base datasets */
	showBaseDatasets?: boolean;
	/** @description hardcoded datasets */
	datasets?: {
		[key: string]: ChartDatasetConfig;
	};
	/** @description for custom reports */
	variablesAxisX?: ICustomVariable[];
	/** @description for custom reports */
	variablesAxisY?: ICustomVariable[];
	/** @description for custom reports */
	variablesAxisZ?: ICustomVariable[];
}

export type AnalyticsContentItemType =
	| 'table'
	| 'line-chart'
	| 'bar-chart'
	| 'pie-chart'
	| 'group-element'
	| 'stats'
	| 'stat-item'
	| 'map'
	| 'divider'
	| 'custom';

export interface AnalyticsContentItem {
	children?: string[];
	content?: AnalyticsContentItem[];
	id: string;
	title?: string;
	type: AnalyticsContentItemType;
	variable?: ICustomVariable;
	visible: boolean;
	width?: '50%' | '100%';
}

/** @WARNING - if something is changed, please check `mergePermissions` function */
export interface AnalyticsTabConfig<FiltersType = IAnalyticsRequestParams> {
	content?: AnalyticsContentItem[];
	defaultFilters?: FiltersType;
	filterLimit?: number;
	filters?: FiltersType;
	hiddenFilters?: string[];
	visible?: boolean;
	autoload?: boolean;
}

export interface AnalyticsConfig<FiltersType = IAnalyticsRequestParams> {
	tabs: { [key: string]: AnalyticsTabConfig<FiltersType> };
	tabsOrder?: ValueOf<typeof ANALYTICS_SECTIONS>[];
	customTabs?: { [key: string]: AnalyticsTabConfig<FiltersType> };
	customTabsOrder?: string[];
}

export enum DashboardItemType {
	list = 'list',
	'line-chart' = 'line-chart',
}

export enum DashboardDatePreset {
	yesterday = 'yesterday',
	last7Days = 'last7Days',
	last30Days = 'last30Days',
	thisMonth = 'thisMonth',
	lastMonth = 'lastMonth',
	monthToDate = 'monthToDate',
	last3Month = 'last3Month',
	threeMonthsToDate = 'threeMonthsToDate',
}

export interface IDashboardItem {
	id: string;
	title: string;
	type: DashboardItemType;
	visible: boolean;
}

export interface IDashboardConfig {
	items: Array<IDashboardItem>;
	locations?: string[];
	defaultDatePreset?: DashboardDatePreset;
	currentDatePreset?: DashboardDatePreset;
}

export interface IBaasConfig {
	applications?: {
		filters?: {
			platform?: string[];
			appname?: string[];
			apptype?: string[];
			deployment?: string[];
		};
	};
	products?: {
		filters?: {
			purchaseType?: string[];
			sharingType?: string[];
			type?: string[];
		};
	};
}

export interface Config<AnalyticsFiltersType = IAnalyticsRequestParams> {
	sessionStartDate: Moment | number | string;
	analytics: AnalyticsConfig<AnalyticsFiltersType>;
	dashboard?: IDashboardConfig;
	charts: { [key: string]: ChartConfig };
	debug?: boolean;
	defaultChartSize: ChartSize;
	sidebarOpen: boolean;
	tables: { [key: string]: TableConfig };
	/** @description custom variable a user created before */
	variables?: ICustomVariable[];
	isIframe?: boolean;
	theme: Partial<ITheme>;
	baas?: IBaasConfig;
}

export interface Auth {
	isAuthorized: boolean;
	loading: boolean;
	user: IUser | null;
}

export interface SelectedApplication {
	deploymentName: string;
	id: string;
	name: string;
	platformName: string;
}

export enum TaskType {
	analyticsReport = 'analytics_report',
	contract = 'contract',
}

export interface ITask {
	id: string;
	name: string;
	description?: string;
	active: boolean | number;
	type: TaskType;
	executionTime: number;
	recipients: string[];
}

export type ScheduleRecurrence = 'day' | 'week' | 'month' | 'quarter' | 'half-year' | 'year';

export type ScheduleFormat = 'pdf' | 'xlsx';

export interface ScheduledReport<AnalyticsFiltersType = IAnalyticsRequestParams> extends ITask {
	config: Partial<Config<AnalyticsFiltersType>>;
	recurrence: ScheduleRecurrence;
	format: ScheduleFormat;
	hash: string;
	/** @description array of analytics items IDs for XLSX (+ other sheets) export format */
	items: string[];
	/** @description array of analytics sections IDs for PDF export format */
	sections: ValueOf<typeof ANALYTICS_SECTIONS>[];
}

export interface ScheduledReports {
	reports: ScheduledReport[];
}

export interface IEmptyRow {
	uid: string;
	name: string;
	id: string;
	url: string;
}
export interface ITableData {
	applicationUrl: IEmptyRow[];
	configurations: IEmptyRow[];
	launchParameters: IEmptyRow[];
}

export type Column = Record<Entity, string[]>;

export type RootState = ReturnType<typeof store.getState>;
