import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../types';

export interface HostingItemForm extends Omit<HostingItem, 'bucket'> {
	bucket: { label: string; value: string };
}

export interface HostingItem {
	uid: string;
	name: string;
	bucket: string;
	path?: string;
	description?: string;
	url: string;
	cdnSpecify: boolean;
	cdnPath?: string;
}

export interface HostingState {
	items: HostingItem[];
	id: string;
	uid: string;
}

const initialState: HostingState = {
	items: [],
	id: '',
	uid: '',
};

export const hosting = createSlice({
	name: 'assetsPath',
	initialState,
	reducers: {
		clearState: () => ({ ...initialState }),
		setState: (state, action: PayloadAction<Omit<HostingState, 'unusedHostingType'>>) => {
			return {
				...action.payload,
			};
		},
		editPathItem: (state, action: PayloadAction<HostingItem>) => {
			const index = state.items.findIndex((item) => item.uid === action.payload.uid);
			if (index !== -1) {
				state.items[index] = action.payload;
			}
		},
	},
});

export const usePathAssets = () => useSelector((state: RootState) => state.pathAssets);
