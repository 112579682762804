// modules
import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
// types
import { TableConfig, ChartSize, Config, RootState, AnalyticsTabConfig } from '../types';
import { IAnalyticsRequestParams } from 'types/analytics';
import { ITheme } from 'types/general';
// utils
import { charts, defaultTabsOrder, tables, tabs } from 'store/defaultState';

const initialState: Config = {
	sidebarOpen: true,
	isIframe: false,
	sessionStartDate: moment().local(),
	defaultChartSize: Object.values(ChartSize)[0],
	theme: {
		direction: 'ltr',
		mode: 'light',
		responsiveFontSizes: true,
	},
	analytics: {
		tabs,
		tabsOrder: defaultTabsOrder,
	},
	tables,
	charts,
};

export const config = createSlice({
	name: 'config',
	initialState,
	reducers: {
		setVersion: (state, action: PayloadAction<string>) => ({
			...state,
			version: action.payload,
		}),
		reset: () => ({ ...initialState }),
		openSidebar: (state) => ({ ...state, sidebarOpen: true }),
		closeSidebar: (state) => ({ ...state, sidebarOpen: false }),
		triggerSidebar: (state, action: PayloadAction<boolean | undefined>) => ({
			...state,
			sidebarOpen: action.payload ?? !state.sidebarOpen,
		}),
		setDebugMode: (state, action: PayloadAction<boolean>) => ({
			...state,
			debug: action.payload,
		}),
		setIframe: (state, action: PayloadAction<boolean>) => ({ ...state, isIframe: action.payload }),
		changeTheme: (state, action: PayloadAction<Partial<ITheme>>) => ({
			...state,
			theme: { ...state.theme, ...action.payload },
		}),
		changeTable: (state, action: PayloadAction<{ tableId: string; config: Partial<TableConfig> }>) => ({
			...state,
			tables: {
				...state.tables,
				[action.payload.tableId]: {
					...state.tables[action.payload.tableId],
					...action.payload.config,
				},
			},
		}),
		changeAnalyticsFilters: (state, action: PayloadAction<{ section: string; filters: IAnalyticsRequestParams }>) => ({
			...state,
			analytics: {
				...state.analytics,
				tabs: {
					...state.analytics.tabs,
					[action.payload.section]: {
						...state.analytics.tabs[action.payload.section],
						filters: action.payload.filters,
					},
				},
			},
		}),
		changeAnalyticsTabConfig: (state, action: PayloadAction<{ tab: string; config: AnalyticsTabConfig }>) => ({
			...state,
			analytics: {
				...state.analytics,
				tabs: {
					...state.analytics.tabs,
					[action.payload.tab]: action.payload.config,
				},
			},
		}),
		changeAnalyticsChartSize: (state, action: PayloadAction<{ chartId?: string; size: ChartSize }>) => {
			/** if id is not provided will change defaultChartSize */
			if (!action.payload.chartId) return { ...state, defaultChartSize: action.payload.size };
			/** else set exact chart size */
			return {
				...state,
				charts: {
					...state.charts,
					[action.payload.chartId]: {
						...state.charts[action.payload.chartId],
						size: action.payload.size,
					},
				},
			};
		},
	},
});

export const useConfig = () => useSelector((state: RootState) => state.config);

export const initialConfig = initialState;
