// modules
import React, { useContext } from 'react';
// types
import { IEntityConfig } from 'types/entity';
import { IConfig } from 'types/general';
// constants
import { languages } from './languages';
import { pages } from './pages';
import { sidebar } from './sidebar';

export const config: IConfig = {
	languages,
	pages,
	dateFormat: 'DD/MM/yyyy',
	timeFormat: '24h',
	scheduleReportRunTime: 4 * 3600, // 4 AM
	sidebar,
};

export const ConfigContext = React.createContext<IEntityConfig | undefined>(undefined);
export const usePageConfig = () => useContext(ConfigContext);
