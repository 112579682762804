import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDropdownSimpleOption } from '../../types/form';
import { ITableData, RootState } from '../types';
import { useSelector } from 'react-redux';
import { stringToDropdownOption } from '../../utils/stringToDropdownOptions';
interface DropdownState {
	originalOptions: IDropdownSimpleOption[];
	availableOptions: IDropdownSimpleOption[];
	pickedOptions: IDropdownSimpleOption[];
}

interface ITableDropdownOptions {
	applicationUrl: DropdownState;
	configurations: DropdownState;
	launchParameters: DropdownState;
}

const initialState: ITableDropdownOptions = {
	applicationUrl: {
		originalOptions: [],
		availableOptions: [],
		pickedOptions: [],
	},
	configurations: {
		originalOptions: [],
		availableOptions: [],
		pickedOptions: [],
	},
	launchParameters: {
		originalOptions: [],
		availableOptions: [],
		pickedOptions: [],
	},
};

export const tableDropdownOptions = createSlice({
	name: 'tableDropdownOptions',
	initialState,
	reducers: {
		init: (state, action: PayloadAction<{ table: keyof ITableDropdownOptions; options: IDropdownSimpleOption[] }>) => {
			const { table, options } = action.payload;
			state[table].originalOptions = options;
			state[table].availableOptions = options;
		},
		selectOption: (
			state,
			action: PayloadAction<{ table: keyof ITableDropdownOptions; option: IDropdownSimpleOption | null; filterable?: boolean }>
		) => {
			const { table, option } = action.payload;
			if (!option) return;
			state[table].pickedOptions.push(option);
			if (action.payload.filterable) {
				state[table].availableOptions = state[table].availableOptions.filter((opt) => opt.value !== option.value);
			}
		},
		resetOptions: (state, action: PayloadAction<{ table: keyof ITableDropdownOptions }>) => {
			const { table } = action.payload;
			state[table].availableOptions = [...state[table].originalOptions];
		},
		releaseOption: (state, action: PayloadAction<{ table: keyof ITableDropdownOptions; optionValue: string }>) => {
			const { table, optionValue } = action.payload;
			state[table].pickedOptions = state[table].pickedOptions.filter((opt) => opt.value !== optionValue);
			state[table].availableOptions.push(stringToDropdownOption(optionValue));
		},
	},
});

export const useTableDropdownOptions = (table: keyof ITableData) => useSelector((state: RootState) => state.tableDropdownOptions[table]);
