import { path } from 'consts';
import { IEntityConfig } from 'types/entity';

export const deploymentConfig: IEntityConfig = {
	key: 'deployment',
	list: {
		title: 'Deployments',
		searchable: true,
		clickable: true,
		path: path.deploymentSettings,
		columns: [],
		actions: [],
		rowActions: [],
		fetchOptions: {
			endpoint: '/deployments/get',
			params: {
				platformId: {
					type: 'single',
					routeVariable: 'platformId',
				},
			},
		},
		deleteOptions: {
			endpoint: '/deployment/delete',
			params: {
				id: {
					type: 'multiple',
					routeVariable: 'deploymentIds',
				},
			},
		},
	},
	form: {
		submitOptions: {
			endpoint: {
				create: '/deployment/create',
				edit: '/deployment/update',
			},
		},
		fetchOptions: {
			endpoint: '/deployments/get',
			params: {
				ids: {
					type: 'multiple',
					routeVariable: 'deploymentId',
				},
			},
		},
		fields: [],
	},
};
