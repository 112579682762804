// modules
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import unset from 'lodash/unset';
import moment, { Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
// utils
import { getCustomAnalyticsItems } from 'components/Analytics/utils/getCustomAnalyticsItems';
import { ANALYTICS_SECTIONS, MAX_TABLE_LIMIT } from 'consts';
import { tabs as defaultAnalyticsTabsConfig, charts as defaultCharts, tables as defaultTables, defaultTabsOrder } from 'store/defaultState';
import { useAuth } from 'store/slices/auth';
import { dateToSeconds } from 'utils/dateToSeconds';
import { dropdownOptionsToString } from 'utils/dropdownOptionsToString';
import { getDeepHiddenColumns } from 'utils/getDeepHiddenColumns';
import { mergeObjectsDeep } from 'utils/mergeObjectsDeep';
import { stringToDropdownOption } from 'utils/stringToDropdownOptions';
import { useConfigRestrictions } from 'utils/useConfigRestrictions';
import { sumAnalyticObjects } from './sumAnalyticObjects';
// types
import { COUNTRIES } from 'consts/countries';
import { AnalyticsContentItem, AnalyticsTabConfig, ChartConfig, ChartSize, Config, DashboardDatePreset, TableConfig } from 'store/types';
import { AnalyticsMetrics, IAnalyticsParams, IAnalyticsRequestParams, IAnalyticsUserData } from 'types/analytics';
import { IDropdownOption } from 'types/form';
import { ValueOf } from 'types/general';
import { IMetricsProxyRule, IUser } from 'types/login';

const RULES_AFFECTED_PARAMS = [
	AnalyticsMetrics.platform,
	AnalyticsMetrics.appname,
	AnalyticsMetrics.eventcategory,
	AnalyticsMetrics.eventaction,
	AnalyticsMetrics.apptype,
	AnalyticsMetrics.country,
	AnalyticsMetrics.adtype,
	AnalyticsMetrics.source,
	AnalyticsMetrics.mode,
	AnalyticsMetrics.genre,
];

/** @description add { included: [...] } or { excluded: [...] } as a filter */
const addFilter = (key: string, included: string[], metrics: Record<string, string[]>, filtersArr: unknown[], hasRestrictions?: boolean) => {
	const itemMetric = metrics[key] || [];
	const excluded = itemMetric.filter((i) => !included.includes(i));

	switch (true) {
		case hasRestrictions:
			filtersArr.push({ metric: key, included });
			break;
		case itemMetric?.length && itemMetric?.length === included?.length:
			return;
		case Boolean(itemMetric?.length && excluded?.length && included?.length > excluded?.length):
			filtersArr.push({ metric: key, excluded });
			break;
		case Boolean(included?.length):
			filtersArr.push({ metric: key, included });
			break;
	}
};

/** @description returns highest-priority available orderBy item as a single-element array */
const filterOrderBy = (orderBy?: AnalyticsMetrics[], metrics?: AnalyticsMetrics[]) => {
	if (!orderBy?.length || !metrics?.length) return [];
	return orderBy.reduce<AnalyticsMetrics[]>((acc, orderByItem) => {
		if (acc.length) return acc;
		return metrics.includes(orderByItem) ? [orderByItem] : acc;
	}, []);
};

/** @description normalize data based on a single user rule */
const normalizeDataByRule = (data: unknown[] | Record<string, string[]>, type: 'in' | 'out', rule: IMetricsProxyRule | null) => {
	if (!rule) return data;
	const isStringArr = Array.isArray(data) && typeof data[0] === 'string';
	if (Array.isArray(data)) {
		switch (type) {
			case 'in':
				{
					const [filtered, itemsToCombine] = data.reduce<unknown[][]>(
						(acc, c) => {
							const condition = isStringArr ? rule.input.includes(c as string) : rule.input.includes((c as Record<string, string>)[rule.name]);
							if (condition) acc[1].push(isStringArr ? c : { ...(c as object), [rule.name]: rule.out[0] });
							else acc[0].push(c);
							return acc;
						},
						[[], []]
					);

					if (filtered?.length === data?.length) break;

					let combined = [];
					if (isStringArr) combined = [rule.out[0]];
					else combined = sumAnalyticObjects(itemsToCombine as IAnalyticsUserData[]);
					data = [...filtered, ...combined];

					/** sort data A-Z */
					if (isStringArr) data = sortBy(data);
					else data = sortBy(data, ['country', 'adtype', 'apptype', 'appname', 'platform']);
				}
				break;
			case 'out': {
				if (isStringArr) {
					const filtered = data.filter((c) => !rule.out.includes(c as string));
					const itemIsInArr = data?.length !== filtered?.length;
					if (itemIsInArr) data = [...filtered, ...rule.input];
				}
				break;
			}
		}
	} else if (data && typeof data === 'object' && Array.isArray(data[rule.name])) {
		data[rule.name] = (normalizeDataByRule(data[rule.name], type, rule) as string[]).sort((a, b) => {
			if (a.toLowerCase() < b.toLowerCase()) return -1;
			if (a.toLowerCase() > b.toLowerCase()) return 1;
			return 0;
		});
	}

	return data;
};

export const useAnalyticsProxy = (user?: IUser) => {
	const authCtx = useAuth();
	const { hasPermissions, getAllowedSectionFilters } = useConfigRestrictions();
	const [contextUser, setContextUser] = useState(() => user || authCtx.user);
	const proxyRules = useMemo(() => contextUser?.data?.metricsProxy || [], [contextUser?.data?.metricsProxy]);

	/** @description normalize data based on user rules (one iteration per one rule) */
	const normalizeData = useCallback(
		(data: unknown[] | Record<string, string[]>, type: 'in' | 'out', dataKey?: string) => {
			let result: null | unknown[] | Record<string, string[]> = null;
			if (!proxyRules?.length || !data) return data;

			proxyRules.forEach((rule) => {
				const isRuleValid = rule.input?.length && rule.out?.length === 1 && rule.name && (!dataKey || rule.name === dataKey);
				result = normalizeDataByRule(result || data, type, isRuleValid ? rule : null);
				if (!isRuleValid) return;
				if (rule.name === AnalyticsMetrics.appname) {
					/**
					 * @description
					 * In some cases eventlabel contains appname that we need to normalize as well as appname.
					 * So we need to use appname rules for eventlabel
					 *
					 * Dependency - CrossPromotionTable.tsx
					 * */
					const eventLabelRule = { ...rule, name: AnalyticsMetrics.eventlabel };
					result = normalizeDataByRule(result || data, type, eventLabelRule);
				}
			});

			return result;
		},
		[proxyRules]
	);

	/** @description IDropdownOption[] -> string[] */
	const parseParamsToFilters = useCallback((params: IAnalyticsParams | IAnalyticsRequestParams): IAnalyticsRequestParams => {
		return Object.keys(params).reduce<Record<string, unknown>>((acc, filterKey) => {
			const filterValue = get(params, filterKey);
			if (!Array.isArray(filterValue)) {
				acc[filterKey] = filterValue;
				return acc;
			}
			if (RULES_AFFECTED_PARAMS.includes(filterKey as AnalyticsMetrics)) {
				acc[filterKey] = (filterValue as IDropdownOption[]).map(dropdownOptionsToString);
			} else if (filterKey === AnalyticsMetrics.date) {
				acc[filterKey] = filterValue.map((i: Moment, idx) => dateToSeconds(i, idx ? 'end' : 'start'));
			} else {
				acc[filterKey] = filterValue;
			}
			return acc;
		}, {});
	}, []);

	/** @description string[] -> IDropdownOption[] */
	const parseFiltersToParams = useCallback((params: IAnalyticsRequestParams | IAnalyticsParams): IAnalyticsParams => {
		const res = Object.keys(params).reduce<Record<string, unknown>>((acc, filterKey) => {
			const filterValue = get(params, filterKey);
			if (!Array.isArray(filterValue)) {
				acc[filterKey] = filterValue;
				return acc;
			}
			if (RULES_AFFECTED_PARAMS.includes(filterKey as AnalyticsMetrics)) {
				acc[filterKey] = filterValue.reduce((acc, c) => {
					if (typeof c === 'object') acc.push(c);
					else acc.push(stringToDropdownOption(c));
					return acc;
				}, []);
			} else if (filterKey === AnalyticsMetrics.date) {
				acc[filterKey] = filterValue.map((i: number) => moment(i * 1000));
			} else {
				acc[filterKey] = filterValue;
			}
			return acc;
		}, {});
		return res;
	}, []);

	/** @description add params rules ignoring fields without rules support */
	const addParamsRules = useCallback(
		(params: IAnalyticsParams) => {
			const paramsWithRules = cloneDeep(params);

			RULES_AFFECTED_PARAMS.forEach((key) => {
				const filterValue = get(paramsWithRules, key) as IDropdownOption[];
				if (!filterValue?.length) return;
				const stringArrWithRules = uniq((normalizeData(filterValue.map(dropdownOptionsToString), 'in', key) || []) as string[]);
				if (key === AnalyticsMetrics.country) {
					const dropdownOptionsWithRules = stringArrWithRules.map((item) => ({
						label: COUNTRIES.find(({ code }) => code.toLowerCase() === item.toLowerCase())?.name || item,
						value: item,
					}));
					set(paramsWithRules, key, dropdownOptionsWithRules);
				} else {
					const dropdownOptionsWithRules = stringArrWithRules.map(stringToDropdownOption);
					set(paramsWithRules, key, dropdownOptionsWithRules);
				}
			});

			return paramsWithRules as IAnalyticsParams;
		},
		[normalizeData]
	);

	/** @description remove params rules ignoring fields without rules support */
	const removeParamsRules = useCallback(
		(params: IAnalyticsParams | IAnalyticsRequestParams): IAnalyticsRequestParams => {
			const paramsWithoutRules = parseParamsToFilters(params);
			RULES_AFFECTED_PARAMS.forEach((key) => {
				const filterValue = get(paramsWithoutRules, key, []);
				if (!filterValue?.length) return;
				const valueWithoutRules = normalizeData(filterValue, 'out', key);
				const uniqValueWithoutRules = Array.isArray(valueWithoutRules)
					? uniq(valueWithoutRules)
					: Object.keys(valueWithoutRules || {}).reduce((acc, key) => ({ ...acc, [key]: uniq(valueWithoutRules?.[key] || []) }), {});
				set(paramsWithoutRules, key, uniqValueWithoutRules);
			});

			return paramsWithoutRules;
		},
		[normalizeData, parseParamsToFilters]
	);

	const normalizeParamsBeforeRequest = useCallback(
		(
			params: IAnalyticsRequestParams,
			metrics: Record<string, string[]>,
			hasRestrictions?: boolean,
			useUserFlag?: boolean
		): IAnalyticsRequestParams => {
			const normalized: IAnalyticsRequestParams = { filters: [], postProcessors: params.postProcessors };
			const period = get(params, 'cohortsize') || get(params, 'period');
			const isRetention = !params.metrics && Boolean(period);
			const hasNewUsers = params.metrics?.includes(AnalyticsMetrics.newusers);
			const orderby = filterOrderBy(params.orderby, params.metrics);
			const orderbyasc = filterOrderBy(params.orderbyasc, params.metrics);

			if (params.startdate && moment.isMoment(params.startdate)) normalized.startdate = dateToSeconds(params.startdate);
			if (params.enddate && moment.isMoment(params.enddate)) normalized.enddate = dateToSeconds(params.enddate, 'end');

			RULES_AFFECTED_PARAMS.forEach((key) => {
				const value = get(params, key);
				if (!value || !value?.length || moment.isMoment(value[0])) return;
				addFilter(key, value, metrics, normalized.filters || [], hasRestrictions);
			});

			if (params.filters) {
				normalized.filters = [...(normalized.filters || []), ...params.filters];
			}

			if (period) {
				normalized.period = dropdownOptionsToString(period);
			}

			if ((isRetention || hasNewUsers) && useUserFlag) {
				normalized.userperapp = 1;
			}

			/** @description hardcode preload type */
			const adTypeIdx = (normalized.filters || []).findIndex((i) => i.metric === 'adtype' && i.included?.length);
			if (normalized.filters && adTypeIdx !== -1) {
				normalized.filters[adTypeIdx].included = uniq([...(normalized.filters[adTypeIdx].included || []), 'preload']);
			}

			if (params.limit) normalized.limit = params.limit;
			if (orderby.length) normalized.orderby = orderby;
			if (orderbyasc.length) normalized.orderbyasc = orderbyasc;
			if (!normalized.filters?.length) unset(normalized, 'filters');

			normalized.pack = 1;
			normalized.cache = 1;
			normalized.metrics = params.metrics;
			return normalized;
		},
		[]
	);

	/**
	 * @description
	 * 1. Remove rules
	 * 2. Use restrictions (if exist)
	 * 3. Normalize params into request body format
	 *
	 * - skipInaccessible - if metric key is not provided in params than it will skip it
	 * */
	const normalizeParams = useCallback(
		(
			params: IAnalyticsParams,
			metrics: Record<string, string[]>,
			section?: ValueOf<typeof ANALYTICS_SECTIONS> | null,
			skipInaccessible?: boolean
		) => {
			const filters = parseParamsToFilters(params);
			const paramsWithoutRules = removeParamsRules(filters);
			const metricsWithoutRules = removeParamsRules(metrics);

			const allowedParams = section && hasPermissions ? removeParamsRules(getAllowedSectionFilters(section)) : null;
			if (allowedParams) {
				RULES_AFFECTED_PARAMS.forEach((key) => {
					const hasMetric = Object.hasOwnProperty.call(paramsWithoutRules, key);
					if (skipInaccessible && !hasMetric) return;
					const allowedValue = (get(allowedParams, key, []) || []) as string[];
					const currentValue = get(paramsWithoutRules, key, []) || [];
					let value: string[] = currentValue.filter((i) => !allowedValue.length || allowedValue.includes(i));
					if (!value.length) {
						value = allowedValue;
					}
					set(paramsWithoutRules, key, value);
				});
			}

			const res = normalizeParamsBeforeRequest(
				paramsWithoutRules,
				metricsWithoutRules as Record<string, string[]>,
				Boolean(allowedParams),
				params[AnalyticsMetrics.appname]?.length === 1
			);
			return res;
		},
		[getAllowedSectionFilters, normalizeParamsBeforeRequest, parseParamsToFilters, removeParamsRules, hasPermissions]
	);

	/** @description normalize Config before request */
	const normalizeConfig = useCallback(
		(config: Partial<Config<IAnalyticsParams>> | Partial<Config<IAnalyticsRequestParams>>): Partial<Config<IAnalyticsRequestParams>> => {
			const cloned = cloneDeep(config) as Partial<Config<IAnalyticsRequestParams>>;

			Object.keys(cloned.tables || {}).forEach((tableId) => {
				if (!cloned.tables) return;
				const hiddenColumns = getDeepHiddenColumns(cloned.tables[tableId]?.columns || []);
				cloned.tables[tableId].hiddenColumns = hiddenColumns;
				cloned.tables[tableId].limit = Math.min(
					Number(get(cloned.tables[tableId].limit, 'value', cloned.tables[tableId].limit)) || MAX_TABLE_LIMIT,
					MAX_TABLE_LIMIT
				);
				cloned.tables[tableId].order = get(cloned.tables[tableId].order, 'value', cloned.tables[tableId].order) || undefined;
				cloned.tables[tableId].orderBy = get(cloned.tables[tableId].orderBy, 'value', cloned.tables[tableId].orderBy) || undefined;
			});

			Object.keys(cloned.charts || {}).forEach((chartId) => {
				if (!cloned.charts) return;
				const chartConfig = cloned.charts[chartId];
				if (chartConfig.limit) cloned.charts[chartId].limit = Number(dropdownOptionsToString(chartConfig.limit));
				if (chartConfig.defaultSize) {
					const chartSize = dropdownOptionsToString(chartConfig.defaultSize) as ChartSize;
					cloned.charts[chartId].defaultSize = chartSize;
					cloned.charts[chartId].size = chartSize;
				}
			});

			Object.keys(cloned.analytics?.tabs || {}).forEach((tabKey) => {
				if (!cloned.analytics?.tabs) return;
				const tabConfig = cloned.analytics?.tabs?.[tabKey] || {};
				if (!tabConfig.visible) return;

				const defaultFiltersWithoutRules = removeParamsRules(config.analytics?.tabs?.[tabKey]?.defaultFilters || {});
				const filtersWithoutRules = removeParamsRules(config.analytics?.tabs?.[tabKey]?.filters || {});
				const parsedConfig: AnalyticsTabConfig<IAnalyticsRequestParams> = {
					...tabConfig,
					defaultFilters: defaultFiltersWithoutRules,
					filters: filtersWithoutRules,
				};
				cloned.analytics.tabs[tabKey] = parsedConfig;
			});

			if (cloned.dashboard) {
				let defaultDatePreset: DashboardDatePreset = dropdownOptionsToString(cloned.dashboard.defaultDatePreset as string) as DashboardDatePreset;
				let currentDatePreset: DashboardDatePreset = dropdownOptionsToString(cloned.dashboard.currentDatePreset as string) as DashboardDatePreset;
				defaultDatePreset = DashboardDatePreset[defaultDatePreset as DashboardDatePreset] || DashboardDatePreset.last7Days;
				currentDatePreset = DashboardDatePreset[currentDatePreset as DashboardDatePreset] || DashboardDatePreset.last7Days;
				cloned.dashboard = {
					items: cloned.dashboard.items || [],
					locations: cloned.dashboard.locations?.map(dropdownOptionsToString) || [],
					defaultDatePreset,
					currentDatePreset,
				};
			}

			return cloned;
		},
		[removeParamsRules]
	);

	const normalizeContent = useCallback((groupContent: AnalyticsContentItem[], defaultContent: AnalyticsContentItem[]): AnalyticsContentItem[] => {
		return defaultContent.map((contentItem) => {
			const groupContentItem = groupContent.find((i) => i.id === contentItem.id);
			if (!groupContentItem) return { ...contentItem, visible: false };
			const hasContent = contentItem.content;
			const childContent = hasContent ? normalizeContent((groupContentItem.content || []) as AnalyticsContentItem[], contentItem.content || []) : [];
			if (!childContent) return groupContentItem;
			return { ...groupContentItem, content: childContent };
		});
	}, []);

	/** @description parse Config received from backend */
	const parseConfig = useCallback(
		(
			config: Partial<Config<IAnalyticsRequestParams>> | Partial<Config<IAnalyticsParams>>,
			useDefaultTables?: boolean,
			useDefaultCharts?: boolean
		): Partial<Config<IAnalyticsParams>> => {
			const cloned = cloneDeep(config) as Partial<Config<IAnalyticsParams>>;
			const defaultClonedTables = cloneDeep(defaultTables);
			const defaultClonedCharts = cloneDeep(defaultCharts);

			if (useDefaultTables) {
				const customTables = getCustomAnalyticsItems<TableConfig>(cloned.tables || {});
				cloned.tables = isEmpty(cloned.tables)
					? defaultClonedTables
					: (mergeObjectsDeep({ ...defaultClonedTables, ...customTables }, cloned.tables) as Record<string, TableConfig>);
			} else {
				cloned.tables = isEmpty(cloned.tables) ? defaultClonedTables : cloned.tables;
			}

			Object.keys(cloned.tables).forEach((tableId) => {
				if (cloned.tables) {
					cloned.tables[tableId].filterModel = config.tables?.[tableId]?.filterModel || {};
					cloned.tables[tableId].sortingModel = config.tables?.[tableId]?.sortingModel || {};
				}
			});

			if (useDefaultCharts) {
				const customCharts = getCustomAnalyticsItems<ChartConfig>(config.charts || {});
				cloned.charts = isEmpty(config.charts)
					? defaultClonedCharts
					: (mergeObjectsDeep({ ...defaultClonedCharts, ...customCharts }, config.charts) as Record<string, ChartConfig>);
			} else {
				cloned.charts = isEmpty(config.charts) ? defaultClonedCharts : config.charts;
			}

			Object.keys(cloned.analytics?.tabs || {}).forEach((tabKey) => {
				if (!cloned.analytics?.tabs) return;
				const tabConfig = cloned.analytics?.tabs?.[tabKey] || {};
				const defaultTabConfig = defaultAnalyticsTabsConfig[tabKey] || {};
				const defaultFilters = parseFiltersToParams(config.analytics?.tabs?.[tabKey]?.defaultFilters || {});
				const filters = parseFiltersToParams(config.analytics?.tabs?.[tabKey]?.filters || {});
				const defaultFiltersWithRules = addParamsRules(defaultFilters);
				const filtersWithRules = addParamsRules(filters);
				const content = normalizeContent(tabConfig.content || [], defaultTabConfig.content || []);

				const parsedConfig: AnalyticsTabConfig<IAnalyticsParams> = {
					...tabConfig,
					defaultFilters: defaultFiltersWithRules,
					filters: filtersWithRules,
					content,
				};
				cloned.analytics.tabs[tabKey] = parsedConfig;
			});

			if (cloned.analytics) {
				cloned.analytics.tabsOrder = (cloned.analytics.tabsOrder || []).filter((i) => defaultTabsOrder.includes(i));
			}

			if (cloned.dashboard) {
				let defaultDatePreset: DashboardDatePreset = dropdownOptionsToString(cloned.dashboard.defaultDatePreset as string) as DashboardDatePreset;
				let currentDatePreset: DashboardDatePreset = dropdownOptionsToString(cloned.dashboard.currentDatePreset as string) as DashboardDatePreset;
				defaultDatePreset = DashboardDatePreset[defaultDatePreset as DashboardDatePreset] || DashboardDatePreset.last7Days;
				currentDatePreset = DashboardDatePreset[currentDatePreset as DashboardDatePreset] || DashboardDatePreset.last7Days;
				cloned.dashboard = {
					items: cloned.dashboard.items,
					locations: cloned.dashboard.locations,
					defaultDatePreset,
					currentDatePreset,
				};
			}

			return cloned;
		},
		[addParamsRules, parseFiltersToParams, normalizeContent]
	);

	return {
		normalizeData,
		normalizeParams,
		normalizeConfig,
		addParamsRules,
		removeParamsRules,
		parseConfig,
		setContextUser,
		parseParamsToFilters,
		parseFiltersToParams,
	};
};
