// modules
import { path } from 'consts';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// utils
import { useLoginMutation } from 'service';
import { useAnalyticsProxy } from 'service/analytics/useAnalyticsProxy';
import { actions } from 'store/actions';
import { tabs as defaultTabs } from 'store/defaultState';
import { useAuth } from 'store/slices/auth';
import { useConfig } from 'store/slices/config';
import { useAuthContext } from 'utils/security';
import { translateAnalyticsConfig } from 'utils/translateAnalyticsConfig';
import { useErrorToast } from './useErrorToast';
import { useIntervalLoading } from './useIntervalLoading';
import { useMergedPermissions } from './useMergedPermissions';
import { useSharedConfig } from './useSharedConfig';
import { useUserConfig } from './useUserConfig';
// types
import { IBaaSError } from 'service/types';
import { Config } from 'store/types';
import { ILoginResponse } from 'types/login';

export const useAuthBoundary = () => {
	const { isAuthorized, user, loading: loginLoading } = useAuth();
	const config = useConfig();
	const { save: saveConfig } = useUserConfig();
	const { token, setToken, setCertificate, setPublicKey, reset } = useAuthContext();
	const { mutateAsync: request, ...response } = useLoginMutation();
	const { getMergedPermissionsAsync, loading: permissionsLoading } = useMergedPermissions();
	const { setContextUser } = useAnalyticsProxy();
	const [isContextSet, setContextStatus] = useState(false);
	const debouncedLoading = useIntervalLoading(response.isLoading || permissionsLoading, { wait: 1000, initial: true });
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const hasError = Boolean(response.error);
	const hasRemoteConfig = Boolean(Object.keys(user?.data?.config || {})?.length);
	const searchToken = searchParams.get('token') || '';

	/** set shared config if exists */
	const sharedLoading = useSharedConfig();

	/** @description returns url without `?token=...` param */
	const getCurrentFullLocationWithoutToken = useCallback(() => {
		if (!searchToken) return `${location.pathname}${location.search}${location.hash}`;
		const searchWithoutToken = location.search.replace(`?token=${searchToken}`, '').replace(`&token=${searchToken}`, '');
		if (!searchWithoutToken || searchWithoutToken === '?') return `${location.pathname}${location.hash}`;
		const parsedSearch = searchWithoutToken[0] === '&' ? `?${searchWithoutToken.slice(1)}` : searchWithoutToken;
		return `${location.pathname}${parsedSearch}${location.hash}`;
	}, [location, searchToken]);

	useErrorToast(response.error as IBaaSError);

	/** save local config to the server if no server config */
	useEffect(() => {
		if (!isAuthorized) return;
		if (!hasRemoteConfig && token) {
			saveConfig(config);
		}
	}, [config, token, hasRemoteConfig, isAuthorized, saveConfig]);

	/** request login if has token and not authorized */
	useEffect(() => {
		if ((searchToken || token) && !isAuthorized && !hasError && !loginLoading) {
			dispatch(actions.auth.setLoading(true));
			request({ token: searchToken || token });
		}
	}, [token, searchToken, isAuthorized, hasError, loginLoading]);

	/** fails handling */
	useEffect(() => {
		if (response.error) {
			navigate(path.login);
			dispatch(actions.auth.setLoading(false));
			reset();
			response.reset();
			return;
		}
		if (response.isIdle || response.isLoading || searchToken || token || response.data?.token || sharedLoading) return;
		response.reset();
		switch (true) {
			case Boolean(response.isIdle || response.isLoading || searchToken || token || response.data?.token || sharedLoading):
				break;
			case location.pathname === path.root:
				dispatch(actions.auth.setLoading(false));
				navigate(path.login);
				break;
			case location.pathname === path.login:
				break;
			default:
				dispatch(actions.auth.setLoading(false));
				navigate(path.unauthorized);
		}
	}, [reset, token, searchToken, location.pathname, response.isIdle, response.isLoading, sharedLoading, response.data?.token, response.error]);

	/** set inMemory data */
	useEffect(() => {
		if (response.isIdle || response.isLoading || sharedLoading) return;
		const { token, publicKey, certificate } = response.data || {};
		if (!token || !publicKey || !certificate) return;
		setToken(token || '', Boolean(searchToken));
		setPublicKey(publicKey || '');
		setCertificate(certificate || '');
	}, [response.data, response.isIdle, response.isLoading, sharedLoading]);

	/** set analytics proxy context */
	useEffect(() => {
		const { token } = response.data || {};
		if (response.isIdle || response.isLoading || !token || sharedLoading) return;
		const user = omit(response.data, ['certificateId', 'certificate', 'publicKey', 'token']) as ILoginResponse;
		setContextUser(user);
		setContextStatus(true);
	}, [response.data, response.isIdle, response.isLoading, sharedLoading]);

	/** set user data globally on authorization success  */
	useEffect(() => {
		if (!isContextSet || isAuthorized) return;
		const certificate = response.data?.certificate || '';
		const user = cloneDeep(omit(response.data, ['certificate', 'publicKey', 'token']));
		const hasUser = !isEmpty(user);
		if (!hasUser) return;
		if (user.data?.config) {
			const translated = translateAnalyticsConfig(user.data.config) as Partial<Config>;
			/** fixing no-content case in existing user config */
			if (!translated?.analytics?.tabs?.comparison?.content?.length) {
				set(translated, 'analytics.tabs.comparison.content', defaultTabs.comparison.content);
			}
			user.data.config = translated;
		}
		getMergedPermissionsAsync(certificate, user.data?.permissions)
			.then((mergedPermissions) => set(user, 'data.mergedPermissions', mergedPermissions))
			.finally(() => {
				dispatch(actions.auth.login({ isAuthorized: true, user, loading: false }));
				response.reset();
				if (searchToken) {
					/** remove token url param */
					navigate(getCurrentFullLocationWithoutToken(), { replace: true });
				}
			});
	}, [isAuthorized, response.data, isContextSet, getMergedPermissionsAsync]);

	/** navigate to main page when authorization completed */
	useEffect(() => {
		if (isAuthorized && (searchToken || token) && location.pathname === path.login) {
			navigate(path.dashboard);
		}
	}, [token, searchToken, isAuthorized, location.pathname]);

	return debouncedLoading || response.isLoading || sharedLoading || loginLoading;
};
