// modules
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Box, Divider, List, ListSubheader, SxProps } from '@mui/material';
import uniq from 'lodash/uniq';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// utils
import { DEPARTMENTS, path } from 'consts';
import { useSecurity } from 'hooks/useSecurity';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAuth } from 'store/slices/auth';
import { useConfig } from 'store/slices/config';
// types
import { ISidebarItem, ISidebarSection } from 'types/general';
import { Access, RuleType } from 'types/security';
// components
import { SidebarItem } from './SidebarItem';

const getOfficeItem = (isManager: boolean) => (office: string) => {
	const children = [
		{
			title: 'Attendance',
			path: `${path.office}#${office}`,
			Icon: BeenhereRoundedIcon,
			pageId: 'office',
		},
	];
	if (isManager)
		children.push({
			title: 'Manage',
			path: `${path.manageOffice}#${office}`,
			Icon: SettingsRoundedIcon,
			pageId: 'office',
		});
	return {
		title: office,
		path: '',
		pageId: 'office',
		Icon: ApartmentRoundedIcon,
		children,
	};
};

const renderNavItems = ({ depth, items, path }: { depth: number; items: ISidebarItem[]; path: string }) => (
	<List disablePadding>{items.reduce((acc: ReactNode[], item) => reduceChildRoutes({ acc, depth, item, path }), [])}</List>
);

const reduceChildRoutes = ({ acc, depth, item, path }: { acc: ReactNode[]; depth: number; item: ISidebarItem; path: string }) => {
	const key = `${item.title}-${depth}`;
	const partialPathMatch = Boolean(item.path && path.includes(item.path));
	const exactPathMatch = path.split('?')[0] === item.path;
	const hasChildActive = Boolean(item.children?.find((i) => i.path && path.includes(i.path)));

	if (item.children) {
		acc.push(
			<SidebarItem
				active={partialPathMatch}
				chip={item.chip}
				depth={depth}
				Icon={item.Icon}
				info={item.info}
				key={key}
				open={hasChildActive || partialPathMatch}
				path={item.path}
				isExternal={item.isExternal}
				title={item.title}
				pressChildren={item.pressChildren}
			>
				{renderNavItems({
					depth: depth + 1,
					items: item.children,
					path,
				})}
			</SidebarItem>
		);
	} else {
		acc.push(
			<SidebarItem
				open={false}
				active={exactPathMatch}
				chip={item.chip}
				depth={depth}
				Icon={item.Icon}
				info={item.info}
				key={key}
				path={item.path}
				isExternal={item.isExternal}
				title={item.title}
				pressChildren={item.pressChildren}
			/>
		);
	}

	return acc;
};

interface ISidebarSectionProps extends ISidebarSection {
	sx?: SxProps;
	divide?: boolean;
}

export const SidebarSection: FC<ISidebarSectionProps> = (props) => {
	const { items, title, divide, ...other } = props;
	const { sidebarOpen } = useConfig();
	const { user } = useAuth();
	const { isItemAccessible } = useSecurity();
	const { showSidebarExternalLinks } = useUserConfig();
	const { pathname: currentPath } = useLocation();

	/** @description with added department options */
	const modifiedItems = useMemo(
		() =>
			items.reduce<ISidebarItem[]>((acc, i) => {
				if (i.title !== DEPARTMENTS) {
					acc.push(i);
					return acc;
				}
				const offices = uniq([...(user?.data?.managingOffice || []), ...(user?.data?.office || []), ...(user?.data?.viewedOffice || [])]);
				if (!offices.length) return acc;
				acc.push({
					...i,
					children: offices.map((office) => {
						const isManager = Boolean(user?.data?.managingOffice?.includes(office));
						return getOfficeItem(isManager)(office);
					}),
				});
				return acc;
			}, []),
		[items, user?.data?.managingOffice, user?.data?.office, user?.data?.viewedOffice]
	);

	const getAllowedItems = useCallback(
		(sidebarItems: ISidebarItem[]) => {
			return sidebarItems.reduce<ISidebarItem[]>((acc, item) => {
				if (item.isAvailable && (!user || !item.isAvailable(user))) return acc;
				if (item.isExternal && !showSidebarExternalLinks) return acc;
				if (item.children?.length) {
					const allowedChildren = getAllowedItems(item.children);
					if (!allowedChildren.length) return acc;
					acc.push({ ...item, children: allowedChildren });
				} else {
					const isAllowed = !item.pageId || isItemAccessible(item.pageId, RuleType.page, Access.read) || item.isExternal;
					if (isAllowed) acc.push(item);
				}

				return acc;
			}, []);
		},
		[user, isItemAccessible]
	);

	const allowedItems = useMemo(() => getAllowedItems(modifiedItems), [getAllowedItems, modifiedItems]);

	if (!allowedItems.length) return null;

	const renderTitleContent = () => {
		if (sidebarOpen)
			return (
				<Box
					sx={{
						ml: 4,
						whiteSpace: 'nowrap',
					}}
				>
					{title}
				</Box>
			);
		if (!divide) return null;
		return (
			<Divider
				sx={{
					borderColor: '#2D3748',
					m: 0,
				}}
			/>
		);
	};

	return (
		<List
			subheader={
				<ListSubheader
					disableGutters
					disableSticky
					sx={{
						color: 'neutral.500',
						fontSize: '0.75rem',
						fontWeight: 700,
						lineHeight: 2.5,
						textTransform: 'uppercase',
					}}
				>
					{renderTitleContent()}
				</ListSubheader>
			}
			{...other}
		>
			{renderNavItems({ items: allowedItems, path: currentPath, depth: 0 })}
		</List>
	);
};
