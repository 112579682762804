// modules
import { useDebounceEffect } from 'ahooks';
import { toast } from 'react-toastify';
// utils
import { getErrorMessage } from 'utils/getErrorMessage';

export const useErrorToast = (error?: unknown) => {
	useDebounceEffect(
		() => {
			if (!error) return;
			const message = getErrorMessage(error);
			if (message) toast.error(message);
		},
		[error],
		{ wait: 500 }
	);
};
