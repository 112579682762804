// modules
import kebabCase from 'lodash/kebabCase';
// types
import { AnalyticsMetrics, AnalyticsSyntheticMetrics } from 'types/analytics';
import { CustomVariableGroup, CustomVariableSubgroup, ICustomVariable, VariableFormat } from '../types';
// utils
import { CPM_DIVIDER, IAP_MULTIPLIER, REVENUE_DIVIDER } from 'consts';

const _DEFAULT_ANALYTICS_VARIABLES: ICustomVariable[] = [
	{
		id: 'adprovider',
		name: 'adprovider',
		value: ['adprovider'],
		pureValue: ['adprovider'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.ads,
	},
	{
		id: 'adtagname',
		name: 'adtagname',
		value: ['adtagname'],
		pureValue: ['adtagname'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.ads,
	},
	{
		id: 'adtype',
		name: 'adtype',
		value: ['adtype'],
		pureValue: ['adtype'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.ads,
	},
	{
		id: 'advendor',
		name: 'advendor',
		value: ['advendor'],
		pureValue: ['advendor'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.ads,
	},
	{
		id: 'activetime',
		name: 'activetime',
		value: ['activetime'],
		pureValue: ['activetime'],
		format: VariableFormat.time,
		group: CustomVariableGroup.valueMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'appname',
		name: 'appname',
		value: ['appname'],
		pureValue: ['appname'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'apptype',
		name: 'apptype',
		value: ['apptype'],
		pureValue: ['apptype'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'country',
		name: 'country',
		value: ['country'],
		pureValue: ['country'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'creator',
		name: 'creator',
		value: ['d4'],
		pureValue: ['d4'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'date',
		name: 'date',
		value: ['date'],
		pureValue: ['date'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'devicemodel',
		name: 'devicemodel',
		value: ['devicemodel'],
		pureValue: ['devicemodel'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'genre',
		name: 'genre',
		value: ['genre'],
		pureValue: ['genre'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'inactivetime',
		name: 'inactivetime',
		value: ['inactivetime'],
		pureValue: ['inactivetime'],
		format: VariableFormat.time,
		group: CustomVariableGroup.valueMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'mode',
		name: 'mode',
		value: ['mode'],
		pureValue: ['mode'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'newusers',
		name: 'newusers',
		value: ['newusers'],
		pureValue: ['newusers'],
		format: VariableFormat.number,
		group: CustomVariableGroup.valueMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'platform',
		name: 'platform',
		value: ['platform'],
		pureValue: ['platform'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'productid',
		name: 'productid',
		value: ['productid'],
		pureValue: ['productid'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'sessions',
		name: 'sessions',
		value: ['sessions'],
		pureValue: ['sessions'],
		format: VariableFormat.number,
		group: CustomVariableGroup.valueMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'source',
		name: 'source',
		value: ['source'],
		pureValue: ['source'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'useragent',
		name: 'useragent',
		value: ['useragent'],
		pureValue: ['useragent'],
		format: VariableFormat.string,
		group: CustomVariableGroup.metric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'users',
		name: 'users',
		value: ['users'],
		pureValue: ['users'],
		format: VariableFormat.number,
		group: CustomVariableGroup.valueMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'eventcategory',
		name: 'Event Category',
		value: ['eventcategory'],
		pureValue: ['eventcategory'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'eventaction',
		name: 'Event Action',
		value: ['eventaction'],
		pureValue: ['eventaction'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'eventlabel',
		name: 'Event Label',
		value: ['eventlabel'],
		pureValue: ['eventlabel'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'avgeventvalue',
		name: 'Avg Event Value',
		value: ['avgeventvalue'],
		pureValue: ['avgeventvalue'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'eventvalue',
		name: 'Event Value',
		value: ['eventvalue'],
		pureValue: ['eventvalue'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'eventscount',
		name: 'Events Count',
		value: ['eventscount'],
		pureValue: ['eventscount'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'd1',
		name: 'D1',
		value: ['d1'],
		pureValue: ['d1'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'd2',
		name: 'D2',
		value: ['d2'],
		pureValue: ['d2'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'd3',
		name: 'D3',
		value: ['d3'],
		pureValue: ['d3'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'd4',
		name: 'D4',
		value: ['d4'],
		pureValue: ['d4'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'd5',
		name: 'D5',
		value: ['d5'],
		pureValue: ['d5'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'd6',
		name: 'D6',
		value: ['d6'],
		pureValue: ['d6'],
		format: VariableFormat.string,
		group: CustomVariableGroup.eventMetric,
		subgroup: CustomVariableSubgroup.general,
	},
	{
		id: 'returned-users',
		name: 'Returned Users',
		value: ['users', '-', 'newusers'],
		pureValue: ['users', '-', 'newusers'],
		format: VariableFormat.number,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'returned-users-percent',
		name: 'Returned Users, Percent',
		value: ['returned-users', '/', 'users'],
		pureValue: ['(', 'users', '-', 'newusers', ')', '/', 'users'],
		format: VariableFormat.percent,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'sessions-per-user',
		name: 'Sessions Per User',
		value: ['sessions', '/', 'users'],
		pureValue: ['sessions', '/', 'users'],
		format: VariableFormat.number,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'avg-session-time',
		name: 'Avg Session Time',
		value: ['activetime', '+', 'inactivetime'],
		pureValue: ['activetime', '+', 'inactivetime'],
		format: VariableFormat.time,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'total-time',
		name: 'Total Time',
		value: ['avg-session-time', '*', 'sessions', '/', 'users'],
		pureValue: ['(', 'activetime', '+', 'inactivetime', ')', '*', 'sessions'],
		format: VariableFormat.time,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'total-time-per-user',
		name: 'Total Time Per User',
		value: ['total-time', '/', 'users'],
		pureValue: ['(', 'activetime', '+', 'inactivetime', ')', '*', 'sessions', '/', 'users'],
		format: VariableFormat.time,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'request-to-play',
		name: 'Opportunity',
		value: ['request-to-play'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.ads,
		valueMetric: AnalyticsMetrics.eventscount,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.requestToPlay] }],
	},
	{
		id: 'impression',
		name: 'Impression',
		value: ['impression'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.ads,
		valueMetric: AnalyticsMetrics.eventscount,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.impression] }],
	},
	{
		id: 'rewarded-per-total-impression',
		name: 'Rewarded Per Total Impression',
		value: ['rewarded-impression', '/', 'impression'],
		pureValue: ['rewarded-impression', '/', 'impression'],
		format: VariableFormat.percent,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'request',
		name: 'Request',
		value: ['request'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.ads,
		valueMetric: AnalyticsMetrics.eventscount,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.request] }],
	},
	{
		id: 'response',
		name: 'Response',
		value: ['response'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.ads,
		valueMetric: AnalyticsMetrics.eventscount,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.response] }],
	},
	{
		id: 'responses-per-requests',
		name: 'Responses Per Request',
		value: ['response', '/', 'request'],
		pureValue: ['response', '/', 'request'],
		format: VariableFormat.percent,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'fill-rate',
		name: 'Fill Rate',
		value: ['impression', '/', 'request-to-play'],
		pureValue: ['impression', '/', 'request-to-play'],
		format: VariableFormat.percent,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'opportunity-per-user',
		name: 'Opportunity Per User',
		value: ['request-to-play', '/', 'users'],
		pureValue: ['request-to-play', '/', 'users'],
		format: VariableFormat.number,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'opportunity-per-session',
		name: 'Opportunity Per Session',
		value: ['request-to-play', '/', 'sessions'],
		pureValue: ['request-to-play', '/', 'sessions'],
		format: VariableFormat.number,
		group: CustomVariableGroup.predefinedVariable,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'viewed-time',
		name: 'Total Watch Time',
		value: ['viewed-time'],
		pureValue: ['eventvalue'],
		format: VariableFormat.time,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.video,
		valueMetric: AnalyticsMetrics.eventvalue,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.viewedTime] }],
	},
	{
		id: 'started',
		name: 'Views',
		value: ['started'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.video,
		valueMetric: AnalyticsMetrics.eventscount,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.started] }],
	},
	{
		id: 'rewarded-impression',
		name: 'Rewarded Impression',
		value: ['rewarded-impression'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.ads,
		valueMetric: AnalyticsMetrics.eventscount,
		filters: [
			{ metric: AnalyticsMetrics.adtype, included: [AnalyticsSyntheticMetrics.rewarded] },
			{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.impression] },
		],
	},
	{
		id: 'paying-users',
		name: 'Paying Users',
		value: ['paying-users'],
		pureValue: ['paying-users'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.payment,
		valueMetric: AnalyticsMetrics.d3,
		filters: [
			{
				metric: AnalyticsMetrics.eventaction,
				included: [
					AnalyticsSyntheticMetrics.iapPurchase,
					AnalyticsSyntheticMetrics.iapSubscription,
					AnalyticsSyntheticMetrics.iapRecurringSubscription,
				],
			},
		],
	},
	{
		id: 'ad-avg-cpm',
		name: 'Ad Avg CPM',
		value: ['eventvalue', '/', 'eventscount', '/', CPM_DIVIDER],
		pureValue: ['eventvalue', '/', 'eventscount', '/', CPM_DIVIDER],
		format: VariableFormat.price,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.predefinedVariables,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.impression] }],
	},
	{
		id: 'ad-revenue',
		name: 'Ad Revenue',
		value: ['eventvalue', '/', REVENUE_DIVIDER],
		pureValue: ['eventvalue', '/', REVENUE_DIVIDER],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.predefinedVariables,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.impression] }],
	},
	{
		id: 'iap-sales',
		name: 'IAP Sales',
		value: ['eventvalue', '*', IAP_MULTIPLIER],
		pureValue: ['eventvalue', '*', IAP_MULTIPLIER],
		format: VariableFormat.price,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.predefinedVariables,
		filters: [
			{
				metric: AnalyticsMetrics.eventaction,
				included: [
					AnalyticsSyntheticMetrics.iapPurchase,
					AnalyticsSyntheticMetrics.iapSubscription,
					AnalyticsSyntheticMetrics.iapRecurringSubscription,
				],
			},
		],
	},
	{
		id: 'iap-refunds',
		name: 'IAP Refunds',
		value: ['eventvalue', '*', IAP_MULTIPLIER],
		pureValue: ['eventvalue', '*', IAP_MULTIPLIER],
		format: VariableFormat.price,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.predefinedVariables,
		filters: [{ metric: AnalyticsMetrics.eventaction, included: [AnalyticsSyntheticMetrics.iapRefund] }],
	},
	{
		id: 'iap-transactions',
		name: 'IAP Transactions',
		value: ['eventscount'],
		pureValue: ['eventscount'],
		format: VariableFormat.number,
		group: CustomVariableGroup.separate,
		subgroup: CustomVariableSubgroup.predefinedVariables,
		filters: [
			{
				metric: AnalyticsMetrics.eventaction,
				included: [
					AnalyticsSyntheticMetrics.iapPurchase,
					AnalyticsSyntheticMetrics.iapSubscription,
					AnalyticsSyntheticMetrics.iapRecurringSubscription,
				],
			},
		],
	},
	{
		id: 'avg-watch-time',
		name: 'Avg Watch Time',
		value: ['viewed-time', '/', 'started'],
		pureValue: ['viewed-time', '/', 'started'],
		format: VariableFormat.time,
		group: CustomVariableGroup.dependent,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'iap-revenue',
		name: 'IAP Revenue',
		value: ['iap-sales', '-', 'iap-refunds'],
		pureValue: ['iap-sales', '-', 'iap-refunds'],
		format: VariableFormat.price,
		group: CustomVariableGroup.dependent,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'total-revenue',
		name: 'Total Revenue',
		value: ['iap-revenue', '+', 'ad-revenue'],
		pureValue: ['iap-revenue', '+', 'ad-revenue'],
		format: VariableFormat.price,
		group: CustomVariableGroup.dependent,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'ad-revenue-per-user',
		name: 'Ad Revenue Per User',
		value: ['ad-revenue', '/', 'users'],
		pureValue: ['ad-revenue', '/', 'users'],
		format: VariableFormat.price,
		group: CustomVariableGroup.dependent,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'avg-iap-cost',
		name: 'Avg IAP Cost',
		value: ['iap-sales', '/', 'iap-transactions'],
		pureValue: ['iap-sales', '/', 'iap-transactions'],
		format: VariableFormat.price,
		group: CustomVariableGroup.dependent,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
	{
		id: 'iap-conversion',
		name: 'IAP Conversion',
		value: ['paying-users', '/', 'users'],
		pureValue: ['paying-users', '/', 'users'],
		format: VariableFormat.percent,
		group: CustomVariableGroup.dependent,
		subgroup: CustomVariableSubgroup.predefinedVariables,
	},
];

export const DEFAULT_ANALYTICS_VARIABLES = new Map(_DEFAULT_ANALYTICS_VARIABLES.map((i) => [kebabCase(i.id), i]));
