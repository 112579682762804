// modules
import { get } from 'lodash';
// types
import { IAnalyticsSessionData, IAnalyticsUserData } from 'types/analytics';

const SEPARATOR = '/@/';

const KEY_VALUE_SEPARATOR = '//@@//';

export const getAnalyticsObjectFromId = (i: string) => {
	const pairs = i.split(SEPARATOR);
	const result: Record<string, string | number> = {};
	pairs.forEach((pair) => {
		const [key, value] = pair.split(KEY_VALUE_SEPARATOR);
		if (key) result[key] = value;
	});
	return result;
};

/**
 * @description Combines necessary element names into unique id
 */
export const getAnalyticsObjectId = (i: IAnalyticsSessionData | IAnalyticsUserData | Record<string, string | number>): string => {
	const idByKey: Record<string, string | number | undefined> = {
		date: get(i, 'date'),
		platform: i.platform,
		appname: i.appname,
		apptype: i.apptype,
		country: i.country,
		eventcategory: i.eventcategory,
		eventaction: i.eventaction,
		eventlabel: i.eventlabel,
		advendor: i.advendor,
		d1: i.d1,
		adtype: i.adtype,
		d2: i.d2,
		adprovider: i.adprovider,
		d3: i.d3,
		creator: i.creator,
		d4: i.d4,
		useragent: i.useragent,
		d5: i.d5,
		adtagname: i.adtagname,
		d6: i.d6,
		devicemodel: i.devicemodel,
		genre: i.genre,
		mode: i.mode,
		source: i.source,
	};

	const id = Object.keys(idByKey)
		.reduce<string[]>((acc, key) => {
			const value = idByKey[key];
			if (value) acc.push(`${key}${KEY_VALUE_SEPARATOR}${value}`);
			return acc;
		}, [])
		.join(SEPARATOR);

	return id;
};
