// modules
import { Avatar, Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { FC, ReactNode } from 'react';
// components
import { Overlay } from './Overlay';
// utils
import { anim } from 'theme/animations';

export interface ISuccessModalProps {
	body?: ReactNode;
	closeButtonLabel?: string;
	onClose: () => void;
	title: ReactNode;
}

const ICON_CONTAINER_WIDTH = 64;

export const SuccessModal: FC<ISuccessModalProps> = (props) => {
	const { title, closeButtonLabel = 'Close', onClose, body } = props;
	const rotate = anim('rotate', 3, undefined, 0);

	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 5000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Overlay />
			<Container sx={{ width: 700 }}>
				<Paper
					elevation={12}
					sx={{
						p: 3,
						position: 'relative',
					}}
				>
					<Grid container>
						<Grid item width={ICON_CONTAINER_WIDTH}>
							<Avatar
								sx={{
									backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
									color: `success.main`,
									mr: 2,
									mt: '-7px',
									width: 48,
									height: 48,
									...rotate,
								}}
							>
								<CheckIcon fontSize="medium" />
							</Avatar>
						</Grid>
						<Grid item width={`calc(100% - ${ICON_CONTAINER_WIDTH}px)`}>
							<Typography variant="h5">{title}</Typography>
							<Typography component="div" color="textSecondary" sx={{ py: 2, maxHeight: 400, overflowY: 'scroll' }} variant="body2">
								{body}
							</Typography>
							<Grid container alignItems="end" justifyContent="end" mt={2}>
								<Button variant="contained" onClick={onClose} color="primary">
									{closeButtonLabel || 'Close'}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Box>
	);
};
