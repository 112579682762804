// modules
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import { Box, Button, Collapse, ListItem, Tooltip, Typography } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
// types
import { ISidebarItem } from 'types/general';
import { Access, RuleType } from 'types/security';
// utils
import { useSecurity } from 'hooks/useSecurity';
import { useUserConfig } from 'hooks/useUserConfig';
import { actions } from 'store/actions';
import { useConfig } from 'store/slices/config';

interface ISidebarItemProps extends Omit<ISidebarItem, 'children'>, PropsWithChildren {
	active: boolean;
	open: boolean;
	depth: number;
}

let timeout: NodeJS.Timeout;

const PRESS_CHILD_CLASS_NAME = 'element-sub-link';

export const SidebarItem: FC<ISidebarItemProps> = (props) => {
	const { active, children, pressChildren, chip, depth, Icon, info, open: openProp, path, isExternal, title, ...other } = props;
	const [open, setOpen] = useState(!!openProp);
	const [pressChildrenOpen, setPressChildren] = useState(false);
	const { sidebarOpen } = useConfig();
	const dispatch = useDispatch();
	const { isItemAccessible, permissions } = useSecurity();
	const { config } = useUserConfig();

	const pressItems = useMemo(() => {
		return (pressChildren || []).filter((child) => {
			const pageId = child.pageId || props.pageId;
			if (!pageId) return true;
			const isPageAccessible = isItemAccessible(pageId, RuleType.page, Access.read);
			if (!isPageAccessible) return false;
			const isAnalytics = ['analytics-reports', 'analytics-sandbox-reports'].includes(pageId);
			if (!isAnalytics) return true;
			const tab = child.path.split('#')[1];
			const isTabAvailable = permissions?.analytics?.tabs[tab]?.visible && config.analytics?.tabs[tab]?.visible;
			return isTabAvailable;
		});
	}, [pressChildren, permissions?.analytics?.tabs, config.analytics?.tabs, isItemAccessible]);

	const handlePress = useCallback(() => {
		clearTimeout(timeout);
		timeout = setTimeout(() => setPressChildren(true), 400);
	}, []);

	const handleUnpress = useCallback((event: unknown) => {
		const target = (event as MouseEvent).target as HTMLLinkElement;
		const isSubLink = typeof target?.className === 'string' && target?.className?.includes(PRESS_CHILD_CLASS_NAME);
		clearTimeout(timeout);
		setPressChildren(false);
		if (isSubLink) target.click();
	}, []);

	useEffect(() => {
		if (!pressChildrenOpen) return;
		document.addEventListener('mouseup', handleUnpress);
		return () => {
			document.removeEventListener('mouseup', handleUnpress);
		};
	}, [handleUnpress, pressChildrenOpen]);

	useEffect(() => {
		if (!sidebarOpen && open) setOpen(false);
	}, [sidebarOpen, open]);

	const handleToggle = useCallback(() => {
		if (!sidebarOpen) {
			dispatch(actions.config.openSidebar());
		}
		setOpen((prevOpen) => !prevOpen);
	}, [setOpen, sidebarOpen]);

	const renderPressChild = (child: Omit<ISidebarItem, 'children' | 'Icon' | 'chip' | 'info'>) => {
		return (
			<Typography
				sx={{ fontSize: 14, color: 'neutral.400', cursor: 'pointer', textDecoration: 'none', '&:hover': { color: 'neutral.100' } }}
				key={child.title}
				component={Link}
				className={PRESS_CHILD_CLASS_NAME}
				to={child.path}
				onMouseDown={handlePress}
				onTouchEnd={handleUnpress}
			>
				{child.title}
			</Typography>
		);
	};

	const renderPressChildren = () => {
		if (!pressChildrenOpen || !pressItems?.length) return null;
		return (
			<Box
				sx={{
					top: 48,
					left: 16,
					right: 16,
					px: 2,
					py: 1,
					backgroundColor: 'neutral.800',
					borderRadius: 1,
					zIndex: 1,
					display: 'flex',
					gap: 0.5,
					flexDirection: 'column',
					position: 'absolute',
					width: 'calc(100% - 32px)',
				}}
			>
				{pressItems?.map(renderPressChild)}
			</Box>
		);
	};

	let paddingLeft = 24;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const linkLabel = (
		<Box
			sx={{
				opacity: sidebarOpen ? 1 : 0,
				transition: 'opacity .3s',
			}}
		>
			<Box sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>{title}</Box>
			{info}
		</Box>
	);

	// Branch
	if (children) {
		return (
			<ListItem
				disableGutters
				sx={{
					display: 'block',
					my: sidebarOpen ? 1 : 0,
					py: 0,
					px: sidebarOpen ? 2 : 0,
				}}
				{...other}
			>
				<Tooltip title={title} placement="right" arrow disableFocusListener disableHoverListener={sidebarOpen}>
					<Button
						endIcon={!open ? <KeyboardArrowRightIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
						disableRipple
						onClick={handleToggle}
						onMouseDown={handlePress}
						onMouseUp={handleUnpress}
						onTouchStart={handlePress}
						onTouchEnd={handleUnpress}
						startIcon={Icon && <Icon />}
						sx={{
							color: active ? 'primary.light' : 'neutral.300',
							justifyContent: 'flex-start',
							borderRadius: sidebarOpen ? 1 : 0,
							pl: `${paddingLeft}px`,
							transition: 'padding .3s',
							pr: 3,
							textAlign: 'left',
							textTransform: 'none',
							width: '100%',
							'&:hover': {
								backgroundColor: 'rgba(255,255,255, 0.08)',
							},
							'& .MuiButton-startIcon': {
								color: active ? 'primary.light' : 'neutral.400',
							},
							'& .MuiButton-endIcon': {
								color: 'neutral.400',
							},
						}}
					>
						{linkLabel}
					</Button>
				</Tooltip>
				<Collapse in={open}>{children}</Collapse>
				{renderPressChildren()}
			</ListItem>
		);
	}

	const btnBaseProps = {
		startIcon: Icon && <Icon />,
		endIcon: sidebarOpen && chip,
		disableRipple: true,
		sx: {
			color: 'neutral.300',
			justifyContent: 'flex-start',
			pl: `${paddingLeft}px`,
			transition: 'padding .3s',
			pr: 3,
			borderRadius: sidebarOpen ? 1 : 0,
			textAlign: 'left',
			textTransform: 'none',
			whiteSpace: 'nowrap',
			width: '100%',
			...(active && {
				backgroundColor: 'rgba(255,255,255, 0.08)',
				color: 'primary.light',
				fontWeight: 'fontWeightBold',
			}),
			'& .MuiButton-startIcon': {
				color: active ? 'primary.light' : 'neutral.400',
			},
			'&:hover': {
				backgroundColor: 'rgba(255,255,255, 0.08)',
			},
		},
	};

	const renderLink = () => {
		if (isExternal) {
			return (
				<Button {...btnBaseProps} component="a" href={path} target="_blank">
					{linkLabel}
					<OpenInNewRoundedIcon
						sx={{
							width: 14,
							height: 14,
							ml: 1,
							color: 'neutral.500',
							opacity: Number(sidebarOpen),
						}}
					/>
				</Button>
			);
		}
		return (
			<Button
				{...btnBaseProps}
				sx={{ ...btnBaseProps.sx, userDrag: 'none', userSelect: 'none' }}
				draggable="false"
				component={Link}
				to={path}
				onMouseDown={handlePress}
				onMouseUp={handleUnpress}
				onTouchStart={handlePress}
				onTouchEnd={handleUnpress}
			>
				{linkLabel}
			</Button>
		);
	};

	// Leaf
	return (
		<ListItem
			disableGutters
			sx={{
				display: 'flex',
				my: sidebarOpen ? 1 : 0,
				py: 0,
				px: sidebarOpen ? 2 : 0,
				position: 'relative',
			}}
		>
			<Tooltip title={title} placement="right" arrow disableFocusListener disableHoverListener={sidebarOpen}>
				{renderLink()}
			</Tooltip>
			{renderPressChildren()}
		</ListItem>
	);
};
