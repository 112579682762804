// modules
import cloneDeep from 'lodash/cloneDeep';
// types
import { ICustomVariable } from 'components/Analytics/types';
import { AnalyticsContentItem, AnalyticsTabConfig, ChartConfig, TableConfig } from 'store/types';

/** @description replaces/deletes provided variable to existing one in provided set (by id) */
export const manageCustomVariable = (variable: ICustomVariable, set: ICustomVariable[], mode?: 'edit' | 'delete') => {
	const isDelete = mode === 'delete';

	return set.reduce<ICustomVariable[]>((acc, curr) => {
		const isSameVariable = curr.id === variable.id;
		if (isSameVariable) {
			if (isDelete) return acc;
			else acc.push(variable);
		} else acc.push(curr);
		return acc;
	}, []);
};

/** @description find usage of the variable in all charts and replaces/deletes with new one */
export const manageCustomVariableInCharts = (variable: ICustomVariable, chartsConfig: Record<string, ChartConfig>, mode?: 'edit' | 'delete') => {
	return Object.entries(chartsConfig).reduce<Record<string, ChartConfig>>((acc, [id, config]) => {
		const result: ChartConfig = {};
		if (config.variablesAxisX) result.variablesAxisX = manageCustomVariable(variable, config.variablesAxisX, mode);
		if (config.variablesAxisY) result.variablesAxisY = manageCustomVariable(variable, config.variablesAxisY, mode);
		if (config.variablesAxisZ) result.variablesAxisZ = manageCustomVariable(variable, config.variablesAxisZ, mode);
		acc[id] = { ...config, ...result };
		return acc;
	}, {});
};

/** @description find usage of the variable in all tables and replaces/deletes with new one */
export const manageCustomVariableInTables = (variable: ICustomVariable, chartsConfig: Record<string, TableConfig>, mode?: 'edit' | 'delete') => {
	return Object.entries(chartsConfig).reduce<Record<string, TableConfig>>((acc, [id, config]) => {
		let variables: ICustomVariable[] | undefined;
		if (config.variables) variables = manageCustomVariable(variable, config.variables, mode);
		acc[id] = { ...config, variables };
		return acc;
	}, {});
};

/** @description find usage of the variable in content array and replaces/deletes with new one */
export const manageCustomVariableInContent = (variable: ICustomVariable, content: AnalyticsContentItem[], mode?: 'edit' | 'delete') => {
	const isDelete = mode === 'delete';
	return content.map((item) => {
		const isSameVariable = item.variable && item.variable.id === variable.id;
		const newItem: AnalyticsContentItem = cloneDeep(item);
		if (isSameVariable) {
			newItem.variable = isDelete ? undefined : variable;
		}
		newItem.content = manageCustomVariableInContent(variable, newItem.content || []);
		return newItem;
	}, {});
};

/** @description find usage of the variable in content array and replaces/deletes with new one */
export const manageCustomVariableInTabs = (variable: ICustomVariable, tabs: Record<string, AnalyticsTabConfig>, mode?: 'edit' | 'delete') => {
	return Object.entries(tabs).reduce<Record<string, AnalyticsTabConfig>>((acc, [tab, config]) => {
		acc[tab] = { ...config, content: manageCustomVariableInContent(variable, config.content || [], mode) };
		return acc;
	}, {});
};
