// modules
import { Avatar, Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { FC, ReactNode } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// components
import { Overlay } from './Overlay';
// utils
import { anim } from 'theme/animations';

export interface IErrorModalProps {
	closeButtonLabel?: string;
	body?: ReactNode;
	onClose: () => void;
	onRepeat?: () => void;
	repeatButtonLabel?: string;
	title: ReactNode;
}

const ICON_CONTAINER_WIDTH = 64;

export const ErrorModal: FC<IErrorModalProps> = (props) => {
	const { closeButtonLabel, body, onClose, onRepeat, repeatButtonLabel, title } = props;
	const hasRepeat = Boolean(onRepeat);
	const slingshot = anim('slingshot', 1.5, undefined, 0);

	const renderRepeatButton = () => {
		if (!onRepeat) return null;
		return (
			<Button
				onClick={onRepeat}
				sx={{
					backgroundColor: `primary.main`,
					color: 'white',
					'&:hover': {
						backgroundColor: `primary.dark`,
					},
				}}
				variant="contained"
			>
				{repeatButtonLabel || 'Try again'}
			</Button>
		);
	};

	return (
		<Box
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 5000,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Overlay />
			<Container sx={{ width: 700 }}>
				<Paper
					elevation={12}
					sx={{
						p: 3,
						position: 'relative',
					}}
				>
					<Grid container>
						<Grid item width={ICON_CONTAINER_WIDTH}>
							<Avatar
								sx={{
									backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
									color: `error.main`,
									mr: 2,
									mt: '-7px',
									width: 48,
									height: 48,
									...slingshot,
								}}
							>
								<ErrorOutlineIcon fontSize="medium" />
							</Avatar>
						</Grid>
						<Grid item width={`calc(100% - ${ICON_CONTAINER_WIDTH}px)`}>
							<Typography variant="h5">{title}</Typography>
							<Typography component="div" color="textSecondary" sx={{ py: 2, maxHeight: 400, overflowY: 'scroll' }} variant="body2">
								{body}
							</Typography>
							<Grid container alignItems="end" justifyContent="end" mt={2} gap={1}>
								<Button variant={hasRepeat ? 'text' : 'contained'} color="primary" onClick={onClose}>
									{closeButtonLabel || 'Close'}
								</Button>
								{renderRepeatButton()}
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Box>
	);
};
