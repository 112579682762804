// modules
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import {
	AppBar,
	Avatar,
	Divider,
	IconButton,
	ListItemIcon,
	MenuItem,
	MenuList,
	Paper,
	Link as MuiLink,
	Switch,
	useMediaQuery,
	Theme,
	Box,
	Typography,
	SvgIcon,
	SxProps,
	Tooltip,
	Chip,
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useDispatch } from 'react-redux';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Link, useNavigate } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import ViewSidebarRoundedIcon from '@mui/icons-material/ViewSidebarRounded';
// import SettingsIcon from '@mui/icons-material/Settings';
// components
import Breadcrumbs from 'components/Breadcrumbs';
import Search from 'components/Search';
import { Logo } from 'components/Logo';
import { ReactComponent as Incognito } from 'assets/svg/incognito.svg';
// utils
import { actions } from 'store/actions';
import { useClickOutside } from 'hooks/useClickOutside';
import { path } from 'consts';
import { useAuth } from 'store/slices/auth';
import { useAuthContext } from 'utils/security';
import { useUserConfig } from 'hooks/useUserConfig';
// types
import { IBreadcrumb } from 'components/Breadcrumbs/types';

interface IHeaderProps {
	useBreadcrumbs?: boolean;
	breadcrumbItems?: IBreadcrumb[];
}

export const Header: FC<IHeaderProps> = (props) => {
	const { useBreadcrumbs, breadcrumbItems } = props;
	const dispatch = useDispatch();
	const { isPretend, reset } = useAuthContext();
	const navigate = useNavigate();
	const { config, changeTheme, isSharedConfig } = useUserConfig();
	const auth = useAuth();
	const [dropdownOpen, setOpen] = useState(false);
	const dropdownContainerRef = useRef(null);
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
	const isRTL = config?.theme?.direction === 'ltr';
	const isDark = config?.theme?.mode === 'dark';

	const handleCloseDropdown = useCallback(() => {
		setOpen(false);
	}, []);

	useClickOutside(dropdownContainerRef, handleCloseDropdown, !dropdownOpen);

	const handleTriggerDropdown = useCallback(() => {
		setOpen((prev) => !prev);
	}, [setOpen]);

	const handleTriggerSidebar = useCallback(() => {
		dispatch(actions.config.triggerSidebar());
	}, []);

	const handleThemeChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const mode = event.target.checked ? 'dark' : 'light';
			changeTheme({ mode });
		},
		[changeTheme]
	);

	const handleDirectionChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const direction = event.target.checked ? 'rtl' : 'ltr';
			changeTheme({ direction });
		},
		[changeTheme]
	);

	const renderSidebarButton = () => {
		if (lgUp || !auth.isAuthorized) return null;
		return (
			<IconButton onClick={handleTriggerSidebar} sx={{ width: 60, p: 0 }}>
				<MenuRoundedIcon sx={{ color: 'white', fontSize: 30 }} />
			</IconButton>
		);
	};

	const renderLogo = () => {
		switch (true) {
			case isSharedConfig:
				return (
					<>
						<Logo sx={{ width: 40, height: 40, ml: 2 }} />
						<Chip
							color="info"
							sx={{ height: 15, borderRadius: 0.5 }}
							label={
								<Typography
									sx={{
										fontSize: 12,
										fontWeight: '700',
										color: 'neutral.100',
									}}
								>
									Sharing
								</Typography>
							}
							size="small"
						/>
					</>
				);
			case !auth.isAuthorized:
				return <Logo sx={{ width: 40, height: 40, ml: 2 }} />;
			default:
				return null;
		}
	};

	const handleLogout = useCallback(() => {
		if (isPretend) {
			navigate(path.login);
			window.location.reload();
			return;
		}
		reset();
		dispatch(actions.auth.logout());
		navigate(path.login);
	}, [reset, isPretend]);

	const renderDefaultDropdownList = () => {
		if (!dropdownOpen) return null;

		return (
			<MenuList>
				<MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
					<LightModeIcon sx={{ color: isDark ? 'neutral.500' : 'neutral.600' }} />
					<Switch checked={isDark} onChange={handleThemeChange} />
					<DarkModeIcon sx={{ color: isDark ? 'neutral.100' : 'neutral.300' }} />
				</MenuItem>
				<MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
					<ViewSidebarRoundedIcon
						sx={{
							transform: 'scaleX(-1)',
							color: () => {
								if (isDark) return isRTL ? 'neutral.100' : 'neutral.500';
								return isRTL ? 'neutral.500' : 'neutral.100';
							},
						}}
					/>
					<Switch checked={config?.theme?.direction === 'rtl'} onChange={handleDirectionChange} />
					<ViewSidebarRoundedIcon
						sx={{
							color: () => {
								if (isDark) return isRTL ? 'neutral.500' : 'neutral.100';
								return isRTL ? 'neutral.100' : 'neutral.500';
							},
						}}
					/>
				</MenuItem>
			</MenuList>
		);
	};

	const renderPretendIcon = (sx?: SxProps) => {
		if (!isPretend) return null;
		return (
			<Tooltip arrow title="Pretending" placement="bottom" disableHoverListener={dropdownOpen}>
				<Box
					sx={{
						width: 'min-content',
						display: 'flex',
						gap: 1,
						alignItems: 'center',
						justifyContent: 'center',
						color: 'text.primary',
						backgroundColor: 'neutral.400',
						borderRadius: 100,
						p: '4px',
						...sx,
					}}
				>
					<SvgIcon sx={{ width: 20, height: 20 }} component={Incognito} inheritViewBox />
				</Box>
			</Tooltip>
		);
	};

	const renderDropdownList = () => {
		return (
			<MenuList sx={{ p: 0 }}>
				<Typography
					sx={{
						px: 2,
						maxWidth: 160,
						minHeight: 40,
						textAlign: 'center',
						color: 'neutral.500',
						whiteSpare: 'wrap',
						position: 'relative',
						'& .username': {
							opacity: 0,
							transition: '.6s opacity',
						},
						'&:hover': auth?.user?.fullName
							? {
									'& .username': {
										opacity: 1,
									},
							  }
							: {},
					}}
				>
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'background.paper',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{auth?.user?.fullName || auth?.user?.username || ''}
					</Box>
					<Box
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'background.paper',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						className="username"
					>
						{auth?.user?.username || ''}
					</Box>
				</Typography>
				<Divider sx={{ m: '0px !important' }} />
				<MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
					<LightModeIcon sx={{ color: isDark ? 'neutral.500' : 'neutral.600' }} />
					<Switch checked={isDark} onChange={handleThemeChange} />
					<DarkModeIcon sx={{ color: isDark ? 'neutral.100' : 'neutral.300' }} />
				</MenuItem>
				{/* <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
					<ViewSidebarRoundedIcon
						sx={{
							transform: 'scaleX(-1)',
							color: () => {
								if (isDark) return isRTL ? 'neutral.100' : 'neutral.500';
								return isRTL ? 'neutral.500' : 'neutral.100';
							},
						}}
					/>
					<Switch checked={config?.theme?.direction === 'rtl'} onChange={handleDirectionChange} />
					<ViewSidebarRoundedIcon
						sx={{
							color: () => {
								if (isDark) return isRTL ? 'neutral.500' : 'neutral.100';
								return isRTL ? 'neutral.100' : 'neutral.500';
							},
						}}
					/>
				</MenuItem> */}
				<Divider sx={{ m: '0px !important' }} />
				{/* <MenuItem>
						<MuiLink
							component={Link}
							to=""
							sx={{
								color: isDark ? 'neutral.100' : 'neutral.700',
								display: 'flex',
								alignItems: 'center',
								textDecoration: 'none !important',
							}}
						>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							Settings
						</MuiLink>
					</MenuItem> */}
				<MenuItem sx={{ height: 40 }} onClick={handleLogout}>
					<MuiLink
						component={Link}
						to=""
						sx={{
							color: isDark ? 'neutral.100' : 'neutral.700',
							display: 'flex',
							alignItems: 'center',
							textDecoration: 'none !important',
						}}
					>
						<ListItemIcon>
							<LogoutIcon />
						</ListItemIcon>
						Logout
					</MuiLink>
				</MenuItem>
			</MenuList>
		);
	};

	const renderDropdown = () => {
		if (!dropdownOpen) return null;

		const renderContent = auth.isAuthorized ? renderDropdownList : renderDefaultDropdownList;

		return (
			<Paper elevation={24} sx={{ overflow: 'hidden', position: 'absolute', right: 0, top: 60, minWidth: 160 }}>
				{renderContent()}
			</Paper>
		);
	};

	const renderBreadcrumbs = () => {
		if (!useBreadcrumbs) return null;
		return <Breadcrumbs items={breadcrumbItems} />;
	};

	return (
		<>
			<AppBar
				component="header"
				sx={{
					height: 56,
					zIndex: 1999,
					backgroundColor: isDark ? 'main.800' : 'main.500',
					position: 'absolute',
					width: 'auto',
					left: 0,
					right: 0,
				}}
			>
				<Box
					sx={{
						pr: 2,
						height: '100%',
						boxShadow: '0px -4px 15px 0px rgb(50 50 50)',
						zIndex: 1,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{renderSidebarButton()}
					{renderLogo()}
					<Box sx={{ flex: 1, height: '100%' }}>{renderBreadcrumbs()}</Box>
					{auth.isAuthorized && !isSharedConfig && <Search />}
					<Box sx={{ pl: 0, display: 'flex', justifyContent: 'end', position: 'relative' }} ref={dropdownContainerRef}>
						<Avatar
							sx={{
								backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.900' : 'neutral.100'),
								cursor: 'pointer',
								border: '1px solid',
								borderColor: (theme) => (theme.palette.mode === 'dark' ? 'main.600' : 'main.400'),
								transition: 'border-color .3s, transform .3s',
								'&:hover': {
									borderColor: (theme) => (theme.palette.mode === 'dark' ? 'main.400' : 'neutral.100'),
									transform: 'scale(1.05)',
								},
							}}
							src={auth.user?.avatar}
							alt={auth.user?.fullName || auth.user?.username}
							onClick={handleTriggerDropdown}
						>
							<AccountCircleRoundedIcon sx={{ color: 'neutral.500' }} />
						</Avatar>
						{renderPretendIcon({ position: 'absolute', bottom: 0, right: 0, transform: 'translate(25%, 25%)' })}
						{renderDropdown()}
					</Box>
				</Box>
			</AppBar>
		</>
	);
};
