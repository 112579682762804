// types
import { IEntityConfig } from 'types/entity';

export const baasConfig: IEntityConfig = {
	key: 'baas-config',
	form: {
		submitOptions: {
			endpoint: {
				create: '',
				edit: '/system/config/set',
			},
		},
		fetchOptions: {
			endpoint: '/system/config/get',
		},
		fields: [
			{
				type: 'json',
				label: '',
				key: 'data',
			},
		],
	},
};
