// modules
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import momentTz from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// components
import App from './App';
// utils
import { persistor, store } from 'store/store';
import { AuthProvider } from 'utils/security';
// styles
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import 'swiper/swiper.min.css';
import './index.css';

momentTz.tz.setDefault('UTC');
momentTz.defineLocale('weekStart_monday', { week: { dow: 1 } });
momentTz.defineLocale('weekStart_sunday', { week: { dow: 0 } });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/** in-memory storage */
export const inMemory = {
	token: '',
	publicKey: '',
	certificate: '',
};

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 0,
			staleTime: 0,
		},
	},
});

const setToken = (value: string) => (inMemory.token = value);
const setPublicKey = (value: string) => (inMemory.publicKey = value);
const setCertificate = (value: string) => (inMemory.certificate = value);

root.render(
	<AuthProvider
		publicKey={inMemory.publicKey}
		certificate={inMemory.certificate}
		setToken={setToken}
		setCertificate={setCertificate}
		setPublicKey={setPublicKey}
	>
		<ReduxProvider store={store}>
			<PersistGate persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<App />
				</QueryClientProvider>
			</PersistGate>
		</ReduxProvider>
	</AuthProvider>
);
