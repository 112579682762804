// modules
import { Box, Card, IconButton, Typography } from '@mui/material';
import { round, startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, LineElement, RadialLinearScale, PointElement } from 'chart.js';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch } from 'react-redux';
// utils
import { numberWithCommas } from 'utils/numberWithCommas';
import { useConfig } from 'store/slices/config';
import { actions } from 'store/actions';

ChartJS.register(RadialLinearScale, PointElement, CategoryScale, LinearScale, LineElement, Title, Tooltip);

const BYTES_IN_MB = 1000000;

interface IMemory {
	jsHeapSizeLimit: number;
	totalJSHeapSize: number;
	usedJSHeapSize: number;
}

interface IPerformanceWindowProps {
	intervalSeconds?: number;
	limit?: number;
}

const initialMemory: IMemory = { jsHeapSizeLimit: 0, totalJSHeapSize: 0, usedJSHeapSize: 0 };

const getOperator = (prev: number, curr: number) => {
	if (prev < curr) return <span style={{ position: 'absolute', right: 0, fontSize: 25, color: 'red' }}>&#9652;</span>;
	if (prev > curr) return <span style={{ position: 'absolute', right: 0, fontSize: 25, transform: 'rotate(180deg)', color: 'green' }}>&#9652;</span>;
	return <span style={{ position: 'absolute', right: 0, fontSize: 25, color: 'lightgrey' }}>-</span>;
};

const bytesToMb = (value: number) => {
	const megabytes = round(value / BYTES_IN_MB, 1);
	return megabytes;
};

const memoryKeys: Array<keyof IMemory> = ['jsHeapSizeLimit', 'totalJSHeapSize', 'usedJSHeapSize'];

export const PerformanceWindow: FC<IPerformanceWindowProps> = (props) => {
	const { intervalSeconds = 1, limit = 10 } = props;
	const config = useConfig();
	const [memoryHistory, setMemoryHistory] = useState<IMemory[]>([]);
	const [memory, setMemory] = useState<IMemory>(initialMemory);
	const [open, setOpen] = useState(true);
	const dispatch = useDispatch();
	const isAllowed = Boolean(config.debug);

	const handleClose = useCallback(() => {
		dispatch(actions.config.setDebugMode(false));
	}, []);

	useEffect(() => {
		if (!isAllowed) return;
		const timeout = setTimeout(() => {
			const newMemory = (window.performance as unknown as { memory: IMemory }).memory;
			setMemory(newMemory);
			const newHistory = memoryHistory.slice(Math.max(memoryHistory.length - limit + 1, 0));
			setMemoryHistory([...newHistory, newMemory]);
		}, intervalSeconds * 1000);

		return () => clearTimeout(timeout);
	}, [memory, limit, isAllowed]);

	const renderMemoryItem = (memoryKey: keyof IMemory) => {
		const prevMemory = bytesToMb((memoryHistory[memoryHistory.length - 2] || initialMemory)[memoryKey]);
		const currMemory = bytesToMb(memory[memoryKey]);

		return (
			<Typography key={memoryKey} sx={{ position: 'relative', display: 'flex', alignItems: 'center', gap: 1, paddingRight: 4 }} variant="subtitle2">
				<Typography sx={{ minWidth: 150 }}>{startCase(memoryKey)}:</Typography>
				<Typography fontWeight="bold">{numberWithCommas(currMemory)} MBs</Typography>
				{getOperator(prevMemory, currMemory)}
			</Typography>
		);
	};

	const renderMemoryChart = () => {
		const totalHistory = memoryHistory.map((i) => bytesToMb(i.totalJSHeapSize));
		const totalAvg = totalHistory.reduce((acc, c) => acc + c, 0) / totalHistory.length;
		const totalColor = totalAvg < 100 ? 'green' : totalAvg > 200 ? 'red' : 'orange';
		const usedHistory = memoryHistory.map((i) => bytesToMb(i.usedJSHeapSize));
		const usedAvg = usedHistory.reduce((acc, c) => acc + c, 0) / usedHistory.length;
		const usedColor = usedAvg < 100 ? 'green' : usedAvg > 200 ? 'red' : 'orange';
		const chartData = {
			labels: totalHistory.map((_, idx, arr) => `${(arr.length - idx) * intervalSeconds}s`),
			datasets: [
				{
					data: totalHistory,
					label: 'Total Heap',
					borderColor: totalColor,
					backgroundColor: totalColor,
					backgroundOpacity: 0,
					pointRadius: 1.5,
					tension: 0.1,
				},
				{
					data: usedHistory,
					label: 'Used heap',
					borderColor: usedColor,
					backgroundColor: usedColor,
					backgroundOpacity: 0,
					pointRadius: 1.5,
					tension: 0.1,
				},
			],
		};
		const maxValue = Math.round((Math.max(100, ...totalHistory) + 100) / 100) * 100;
		return (
			<Box>
				<Line
					data={chartData}
					options={{
						interaction: {
							mode: 'index' as const,
							intersect: false,
						},
						plugins: {
							legend: {
								display: false,
							},
						},
						scales: {
							y: {
								min: 0,
								max: maxValue,
							},
						},
						animation: {
							duration: 0,
						},
					}}
				/>
			</Box>
		);
	};

	if (!isAllowed) return null;

	return (
		<Card
			sx={{
				border: '1px solid gainsboro',
				overflow: 'visible',
				position: 'fixed',
				py: 1,
				px: 2,
				pt: 4,
				bottom: 16,
				right: 16,
				zIndex: 9999,
				transition: 'transform .3s',
				transform: open ? '' : 'translate(calc(100% + 17px))',
			}}
		>
			{renderMemoryChart()}
			{memoryKeys.map(renderMemoryItem)}
			<IconButton onClick={handleClose} size="small" sx={{ position: 'absolute', top: 4, right: 4 }}>
				<CloseRoundedIcon fontSize="small" />
			</IconButton>
			<Card
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: 30,
					height: 40,
					position: 'absolute',
					top: 30,
					left: 0,
					boxShadow: 'none',
					border: '1px solid gainsboro',
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					borderRight: 0,
					zIndex: 9999,
					transform: 'translate(-100%)',
				}}
			>
				<IconButton onClick={() => setOpen(!open)} size="small">
					<ArrowBackIosNewRoundedIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} fontSize="small" />
				</IconButton>
			</Card>
		</Card>
	);
};
