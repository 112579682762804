// modules
import get from 'lodash/get';
import { Column } from 'ag-grid-community';
// types
import { AGTableColumn } from './types';

export const getColumnId = (column?: AGTableColumn | Column): string => {
	if (!column) return '';
	let getter = undefined;
	if (Object.hasOwnProperty.call(column, 'getColId')) {
		getter = get(column, 'getColId') as undefined | (() => string);
	}
	return getter?.() || get(column, 'colId') || get(column, 'field') || get(column, 'groupId') || get(column, 'headerName') || '';
};
