// modules
import snakeCase from 'lodash/snakeCase';
// types
import { CustomVariableGroup, ICustomVariable } from './../types';
// utils
import { GLOBAL_ANALYTICS_VARIABLE_PREFIX } from 'consts';

export const getAnalyticsVariableLabel = (item: ICustomVariable | undefined) => {
	if (!item) return '';
	switch (item.group) {
		case CustomVariableGroup.ownVariable:
			return item.name;
		default:
			return `${GLOBAL_ANALYTICS_VARIABLE_PREFIX}${snakeCase(item.name).toUpperCase()}`;
	}
};
