// types
import { IEntityConfig } from 'types/entity';

export const cmsConfig: IEntityConfig = {
	key: 'cms-config',
	form: {
		submitOptions: {
			endpoint: {
				create: '',
				edit: '',
			},
		},
		fetchOptions: {
			endpoint: '',
		},
		fields: [
			{
				type: 'json',
				label: '',
				key: 'config',
				isRestOfContent: true,
			},
		],
	},
};
