/* eslint-disable */
// modules
import moment from 'moment';

const logger = console as any;

if (logger.everything === undefined) {
	logger.everything = [];

	logger.defaultLog = console.log.bind(console);
	console.log = function () {
		logger.everything.push({ type: 'log', datetime: moment.utc().format(), value: Array.from(arguments) });
		logger.defaultLog.apply(console, arguments);
	};
	logger.defaultError = console.error.bind(console);
	console.error = function () {
		logger.everything.push({ type: 'error', datetime: moment.utc().format(), value: Array.from(arguments) });
		logger.defaultError.apply(console, arguments);
	};
	logger.defaultWarn = console.warn.bind(console);
	console.warn = function () {
		logger.everything.push({ type: 'warn', datetime: moment.utc().format(), value: Array.from(arguments) });
		logger.defaultWarn.apply(console, arguments);
	};
	logger.defaultDebug = console.debug.bind(console);
	console.debug = function () {
		logger.everything.push({ type: 'debug', datetime: moment.utc().format(), value: Array.from(arguments) });
		logger.defaultDebug.apply(console, arguments);
	};
}

export { logger };
